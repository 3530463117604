import React from 'react';
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup
} from "reactstrap";
import Select from 'react-select';
import { FieldTypes, SpecialFieldTypes } from './form-utility';

const delimiter = '<#::#>checked';
const FormField = (props) => {

  const onSingleValFieldChange = (newValue, field, tabId) => {
    let finalVal = `['${newValue}']`;
    props.updateFieldActualValue(finalVal, field.id, tabId);
  }

  const onMultiValueSingleSelectFieldChange = (newValue, field, tabId) => {
    let valuesArr = eval(field.actualValue);
    let newValues = valuesArr.map(val => {
      val = val.replace(delimiter, '');
      if (val === newValue) {
        val = val + delimiter;
      }
      return val;
    })
    let finalVal = JSON.stringify(newValues);
    props.updateFieldActualValue(finalVal, field.id, tabId);
  }

  const onMultiValueMultiSelectFieldChange = (ev, field, tabId) => {
    let newValue = ev.target.value;
    let isChecked = ev.target.checked;
    let valuesArr = eval(field.actualValue);
    let newValues = valuesArr.map(val => {
      if (val.replace(delimiter, '') === newValue) {
        if (!isChecked) {
          val = val.replace(delimiter, '');
        } else if (isChecked && !val.endsWith(delimiter)) {
          val = val + delimiter;
        }
      }
      return val;
    });
    let finalVal = JSON.stringify(newValues);
    props.updateFieldActualValue(finalVal, field.id, tabId);
  }


  const getFieldByType = (field, tabId) => {

    let fieldVal = field.actualValue !== null || field.actualValue !== undefined ?
      eval(field.actualValue) : undefined;

    if (field.type === FieldTypes.textbox) {
      return (
        <FormGroup>
          <Label>{field.name}</Label>
          {field.actualValue !== undefined ?
            <Input value={fieldVal} placeholder={field.name} type="text"
              onChange={(ev) => onSingleValFieldChange(ev.target.value, field, tabId)} />
            :
            <Input placeholder='Field value' type="text" />
          }
        </FormGroup>
      )
    }

    else if (field.type === FieldTypes.textarea) {
      return (
        <FormGroup>
          <Label>{field.name}</Label>
          {field.actualValue !== undefined ?
            <Input value={fieldVal} type="textarea" placeholder={field.name}
              onChange={(ev) => onSingleValFieldChange(ev.target.value, field, tabId)} />
            :
            <Input placeholder='Descriptive text' type="textarea" />
          }
        </FormGroup>
      )
    }

    else if (field.type === SpecialFieldTypes.statement) {
      return (
        <FormGroup>
          <legend>{field.name}</legend>
        </FormGroup>
      )
    }

    else if (field.type === SpecialFieldTypes.header) {
      return (
        <FormGroup>
          <legend>{field.name}</legend>
        </FormGroup>
      )
    }

    else if (field.type === FieldTypes.date) {
      return (
        <FormGroup>
          <Label for="exampleDate">{field.name}</Label>
          {field.actualValue !== undefined ?
            <Input type="date" value={fieldVal} name={field.name} placeholder="Enter date"
              onChange={(ev) => onSingleValFieldChange(ev.target.value, field, tabId)} />
            :
            <Input type="date" name={field.name} id={field.id} placeholder="Enter date" />
          }
        </FormGroup>
      )
    }

    else if (field.type == FieldTypes.dropdown) {

      const fieldValues = field.actualValue !== undefined ? eval(field.actualValue) : eval(field.value);
      let options = [{ label: '-', value: '-' }];
      fieldValues.forEach(fv => {
        let option = { value: fv, label: fv }
        if (fv.endsWith("<#::#>checked")) {
          fv = fv.replace(delimiter, '');
          option.label = fv;
          option.value = fv;
          option.selected = true;
        }
        options.push(option)
      });
      if (!options.some(o => o.selected)) {
        options[0].selected = true;
      }

      return (
        <FormGroup>
          <Label>{field.name}</Label>
          {field.actualValue !== undefined ?
            <Input type="select" value={options.filter(o => o.selected)[0].value} name={field.name}
              id={field.id} onChange={(ev) => onMultiValueSingleSelectFieldChange(ev.target.value, field, tabId)}>
              {options && options.map(o => <option>{o.value}</option>)}
            </Input>
            :
            <Input type="select" name={field.name} id={field.id}>
              {options && options.map(o => <option>{o.value}</option>)}
            </Input>
          }
        </FormGroup>
      )
      // return (
      //   <Row className="mt-3">
      //     <Col md="12">
      //       <Label>{field.name}</Label>
      //       <Select
      //         options={options}
      //       />
      //     </Col>
      //   </Row>
      // )
    }

    else if (field.type === FieldTypes.radio) {
      const fieldValues = field.actualValue !== undefined ? eval(field.actualValue) : eval(field.value);
      const random = Math.random();
      return (
        <FormGroup>
          <Label>{field.name}</Label>
          {fieldValues.map(fv =>
            <FormGroup check>
              <Label check>
                {field.actualValue !== undefined ?
                  <Input type="radio" value={fv.replace(delimiter, '')} name={field.name + random}
                    checked={fv.endsWith(delimiter) ? true : false}
                    onChange={(ev) => onMultiValueSingleSelectFieldChange(ev.target.value, field, tabId)} />
                  :
                  <Input type="radio" value={fv} name={field.name + random} />
                }
                {' '}
                {fv.replace(delimiter, '')}
              </Label>
            </FormGroup>
          )}
        </FormGroup>
      )
    }

    else if (field.type === FieldTypes.checkbox) {
      const fieldValues = field.actualValue !== undefined ? eval(field.actualValue) : eval(field.value);
      return (
        <FormGroup>
          <Label>{field.name}</Label>
          {fieldValues && fieldValues.map(fv =>
            <FormGroup key={fv} check>
              <Label check>
                {field.actualValue !== undefined ?
                  <Input type="checkbox" value={fv.replace(delimiter, '')} id={fv}
                    checked={fv.endsWith(delimiter)}
                    onChange={(ev) => onMultiValueMultiSelectFieldChange(ev, field, tabId)} />
                  :
                  <Input type="checkbox" />
                }
                {' '}
                {fv.replace(delimiter, '')}
              </Label>
            </FormGroup>
          )}
        </FormGroup>
      )
    }
  }

  return (
    <>
      {getFieldByType(props.field, props.tabId)}
    </>

  )
}


export default FormField              