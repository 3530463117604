import React from 'react';
import { Row, Col, FormGroup, Label, Input, FormFeedback,Button } from 'reactstrap';

class CareLevelForm extends React.Component{
    constructor(){
        super()
        this.state = {
            Name: '',
            isActive: false,
            NumberOfBeds: 0,            
        }
    }
    componentDidMount = () => {
        console.log('commmunityId' + this.props.commmunityId)
        let id = parseInt(this.props.id),
         communityId = parseInt(this.props.commmunityId),
         carelevelVal = this.props.careVal;
         if(carelevelVal !== undefined){
             this.setState({
                 Id: id,
                 communityId: communityId,
                 Name: carelevelVal.name,
                 isActive: carelevelVal.isActive,
                 NumberOfBeds: carelevelVal.numberOfBeds,
             })
         }
         else{
            this.setState({
                Id : id,
            })
         }  
     

    }
    handleChange = (e) => {
        if(e.target.name === 'NumberOfBeds'){
            let val = parseInt(e.target.value)
            this.setState({ [e.target.name]: val })
        }
        else this.setState({ [e.target.name]: e.target.value })
    }
    changeStatus = () => {
        let statusState = this.state.isActive    
        this.setState({
            isActive: !statusState
        },  () => {
            if (this.props.getCareLevelsVal) {
              this.props.getCareLevelsVal(this.state);
            }
        })
    }
    render(){
        const {Name, NumberOfBeds, isActive} = this.state
    return(
    
        <React.Fragment>
            <Row>
                <Col md="5" lg="5" sm="12">
                    <FormGroup>
                        <Label for="careType">
                            Name
                        </Label>
                        <Input value={Name} name="Name" onChange={(e)=> this.handleChange(e)} onBlur={(e)=> this.handleChange(e)} disabled={this.props.careVal === undefined ? false : true}/>
                        <FormFeedback>Enter the care type</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md="3" lg="3" sm="12">
                    <FormGroup>
                        <Label>No of Beds</Label>
                        <Input value={NumberOfBeds} type="number" name="NumberOfBeds" onChange={(e)=> this.handleChange(e)} onBlur={(e)=> this.handleChange(e)} disabled={this.props.careVal === undefined ? false : true} />
                        <FormFeedback>Enter no of beds</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md="3" lg="3" sm="12">
                <div className="d-flex flex-column align-items-center justify-content-between">
                            <div className="mb-3 mr-3">Active</div>
                            <Label className="custom-toggle">
                              <input defaultChecked={this.props.careVal !== undefined ? this.props.careVal : isActive} type="checkbox" onClick={this.changeStatus}  />
                              <span className="custom-toggle-slider rounded-circle" />
                            </Label>
                        </div>
                </Col>
                {this.props.careVal === undefined ? 
                <Col md="1" lg="1" sm="12">
                <div className="d-flex align-items-center justify-content-between h-100">
                    <Button color="link" size="lg" type="button" onClick={()=>{this.props.removeCareLevel()}}>
                    <i className="fa fa-trash text-danger" aria-hidden="true"></i></Button> 
                </div>
                </Col> : ''}
            </Row>
        </React.Fragment>
    )
}
}

export default CareLevelForm