const userValidators = {
    email: {
        rules: [
            {
                test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Email is invalid',
            },
            {
                test: (value) => {
                    return value.length != 0;
                },
                message: 'Email cannot be empty',
            },
        ],
        errors: [],
        valid: false,
        state: '',
    },
    password: {
        rules: [
            {
                test: (value) => {
                    return value.length != 0;
                },
                message: 'Password cannot be empty',
            },
            {
                test: (value) => {
                    return value.length >= 6;
                },
                message: 'Password must not be shorter than 6 characters',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    retypePassword: {
        rules: [
            {
                test: (value) => {
                    return value.length != 0;
                },
                message: 'Retype Password cannot be empty',
            },
            {
                test: (value) => {
                    return value.length >= 6;
                },
                message: 'Retype Password must not be shorter than 6 characters',
            }, {
                test: (value, valueToCompare) => {
                    return value === valueToCompare;
                },
                fieldToCompare: "password",
                message: 'Password and Retype Password should be same',
            }
        ],

        errors: [],
        valid: false,
        state: ''
    },
    firstName: {
        rules: [
            {
                test: (value) => {
                    return value.length != 0;
                },
                message: 'First name cannot be empty',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    lastName: {
        rules: [
            {
                test: (value) => {
                    return value.length != 0;
                },
                message: 'Last name cannot be empty',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    phoneNumber: {
        rules: [
            {
                test: (value) => {
                    return value.length != 0;
                },
                message: 'Phone number cannot be empty',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    selectedTimeZone: {
        rules: [
            {
                test: (value) => {
                    return value != null;
                },
                message: 'Time zone is not selected',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    selectedRole: {
        rules: [
            {
                test: (value) => {
                    return value != null;
                },
                message: 'Role is not selected',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    selectedCommunity: {
        rules: [
            {
                test: (value) => {
                    return value != null;
                },
                message: 'Community is not selected',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    SelectedCareLevels: {
        rules: [
            {
                test: (value) => {
                    var items = value.filter(c => c.isChecked);
                    return items.length != 0;
                },
                message: 'Carelevels are not selected',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
};

export default userValidators;