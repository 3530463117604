import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import service from '../../services';
import axios from "axios";
import refreshToken from '../../refresh';
import StoryDetail from './story-detail';


let accessToken, userId
class Stories extends React.Component {
    constructor() {
        super()
        this.state = {
            stories: [],
            showList: true,
            expanded: []
        }
    }

    customDelete = (row) => {
        let confirmDelete = window.confirm('Are you sure?');
        if (!confirmDelete) return;
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'Configuration/RemoveStory?storyId=' + row.id,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            },
        }).then(response => {
            let stories = this.state.stories;
            let selectedStory = stories.filter(c => c.id === row.id);
            if (selectedStory.length != 0) {
                stories.splice(stories.indexOf(selectedStory[0].id), 1);
            }
            let newState = Object.assign({}, this.state);
            newState.stories = stories;
            this.setState(newState);
        })
            .catch(error => {
                console.log(error);
            });
    }

    componentDidMount = () => {
        userId = localStorage.getItem('userId')
        accessToken= localStorage.getItem('accessToken')
        let id = parseInt(userId)
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'Configuration/GetStories',
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(response => {
            let storiesData = response.data.result.stories;
            let newState = Object.assign({}, this.state);
            newState.stories = storiesData;
            this.setState(newState);
        })
            .catch(error => {
               
                if (error.request.status == 401) {
                    refreshToken()
                }
                console.log(error);
            });
    }

    cancelDetail = (storyData, command) => {
        let newState = Object.assign({}, this.state);
        newState.expanded = [];
        if (storyData) {
            if (command === "update" || command === "cancel") {
                let updatedStory = newState.stories.filter(c => c.id === storyData.id);
                if (updatedStory.length != 0) {
                    updatedStory[0].id = storyData.id;
                    updatedStory[0].name = storyData.name;
                    updatedStory[0].description = storyData.description;
                }
            }
            else {
                //newState.stories.splice(0, 0, storyData);
                newState.stories.push(storyData);
            }
        }
        let stories = newState.stories.filter(c => c.id === 0);
        if (stories.length != 0) {
            newState.stories.splice(stories[0], 1);
        }
        this.setState(newState);
    }

    addStory = () => {
        let newState = Object.assign({}, this.state);
        let stories = newState.stories.filter(c => c.id === 0);
        if (stories.length != 0) {
            newState.stories.splice(stories[0], 1);
        }
        newState.stories.splice(0, 0, { id: 0, name: '', description: '' });
        newState.expanded = [0];
        this.setState(newState);
    }
    render() {
        const { stories, showList, customDelete } = this.state
        
        const expandRow = {
            showExpandColumn: true,
            expandByColumnOnly: true,
            onlyOneExpanding: true,
            expanded: this.state.expanded,
            renderer: (row) => {
                return (
                    <StoryDetail story={row} cancelDetail={this.cancelDetail} />
                )
            }
        };

        const  columns = [
            {
                dataField: 'id',
                text: 'UserId',
                hidden: true
            },{
                dataField: 'name',
                text: 'Story Name',
                sort: true,
            }, {
                dataField: 'description',
                text: 'Story Description',
                sort: true
            }, {
                text: 'Manager',
                sort: false,
                isDummyField: true,
                editable: false,
                formatter: (cellContent, row) => {
                    return (<Button color="danger" size="sm" onClick={()=>this.customDelete(row)}><i className="fa fa-trash" aria-hidden="true"></i></Button>);
                }
            }

        ]
        
        return(
            <React.Fragment>
                 <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search Stories"
                          type="text"
                          onChange={this.filterCommunities}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6" lg="6">
                <div className="d-flex justify-content-end align-items-center">
                                    <div className="mr-5">
                                        <Button className="btn btn-outline-default btn-sm" onClick={this.addStory}><i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Add New </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row> 
                            <Col lg="12" m="12">
                {showList === true ? 
                                    <BootstrapTable classes="custom-table-css" striped hover condensed
                                        keyField='id'
                                        data={stories}
                                        columns={columns}
                                        expandRow={expandRow}
                /> : showList === false ? 'No Stories' : ''}
                </Col>
            </Row>

          </CardBody>
        </Card>
            </React.Fragment>
           
        )
    }
}

export default Stories;