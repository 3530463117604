// On load we have to get all forms
/* Then on click of form get details of form and redirect to form-details 
where based on data create tab and fields and bind values*/
/*create one more page add-form,
There we have to create a view of tabs, and then fields (keep it simple at first)
Then we will give add tab feature.
*/

import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
  Modal,
  CardFooter,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from 'react-router-dom';
import service from '../../services';
import axios from "axios";
import Select from 'react-select';
import Label from '../../../node_modules/reactstrap/lib/Label';
import _ from 'lodash'

let accessToken;

const FormList = (props) => {

  const [formList, setFormList] = useState([]);
  const [filteredFormList, setFilteredFormList] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [previewCloneFormModal, setPreviewCloneFormModal] = useState(false);
  const [cloneFormData, setCloneFormData] = useState({ formName: '', okDisabled: false, communities: [], warningMessage: '' });
  const [responseAlert, setResponseAlert] = useState({ showAlert: false, alertType: 'success', alertMessage: '' });

  useEffect(() => {
    accessToken = localStorage.accessToken;
    getCommunities();
  }, [])

  const viewForm = (id) => {
    let formId = id;
    let communityId = communities.filter(c => c.selected)[0].value;
    props.history.push(`./updateform/${formId}`)
  }

  const addForm = () => {
    let communityId = communities.filter(c => c.selected)[0].value;
    props.history.push(`./addform/community/${communityId}`)
  }

  const getCommunities = () => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + 'Communities/GetCommunities?status=true',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let comm = response.data.result.communities;
      let commOptions = comm.map(c => {
        return { label: c.name, value: c.id, selected: false }
      });
      commOptions[0].selected = true;
      setCommunities(commOptions);

      getForms(commOptions.filter(c => c.selected)[0].value);
    })
      .catch(error => {
        console.log(error);
      });
  }

  const filterForms = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      setFilteredFormList([...formList])
    } else {
      searchText = searchText.toLowerCase();
      const filteredForms = formList
        .filter(form => form.name.toLowerCase().indexOf(searchText) >= 0);
      setFilteredFormList([...filteredForms])
    }
  }

  const getForms = (communityId) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + `forms/GetFormsByCommunity?communityId=${communityId}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let dataList = response.data.result.forms;
      console.log(dataList)
      setFormList(dataList);
      setFilteredFormList(dataList);
    })
      .catch(error => {
        console.log(error);
      });
  }

  const handleCommunityChange = (selected) => {
    let comm = _.cloneDeep(communities);
    comm.forEach(c => {
      c.selected = c.value === selected.value ? true : false;
    });
    setCommunities(comm);
    getForms(selected.value);
  }

  const handleCloneModalCommunityChange = (selected) => {
    let cloneData = _.cloneDeep(cloneFormData);
    cloneData.warningMessage = '';
    cloneData.communities.forEach(c => {
      c.selected = c.value === selected.value ? true : false;
    });
    setCloneFormData(cloneData);
  }

  const openCloneFormModal = (formId) => {
    let cloneData = _.cloneDeep(cloneFormData);
    let comm = _.cloneDeep(communities);
    cloneData.formId = formId;
    cloneData.formName = '';
    cloneData.okDisabled = false;
    cloneData.communities = comm.filter(c => !c.selected);
    setCloneFormData(cloneData);
    toggleCloneFormModal();
  }

  const toggleCloneFormModal = () => {
    setPreviewCloneFormModal(!previewCloneFormModal);
  }

  const cloneForm = () => {
    if (cloneFormData.formName.trim().length === 0) {
      setCloneFormData({ ...cloneFormData, warningMessage: 'Form name is mandatory !!!' });
      return;
    } else if (cloneFormData.communities.filter(c => c.selected).length === 0) {
      setCloneFormData({ ...cloneFormData, warningMessage: 'Please select community to clone !!!' });
      return;
    }

    setCloneFormData({ ...cloneFormData, okDisabled: true });
    let formId = cloneFormData.formId;
    let communityId = cloneFormData.communities.filter(c => c.selected)[0].value;
    let formName = cloneFormData.formName;
    axios({
      method: 'POST',
      url: service.baseAPIURL + `Forms/DuplicateForm?communityId=${communityId}&&formId=${formId}&&formName=${formName}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      if (response.data.result.errorMessage) {
        setResponseAlert({ showAlert: true, alertType: 'failure', alertMessage: response.data.result.errorMessage });
        setCloneFormData({ ...cloneFormData, okDisabled: false });
      } else {
        setResponseAlert({ showAlert: true, alertType: 'success', alertMessage: "Form Cloned successfully !!!" });
        toggleCloneFormModal();
      }
    })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <>
      <Alert color={responseAlert.alertType} isOpen={responseAlert.showAlert} toggle={() => setResponseAlert({ ...responseAlert, showAlert: false })}>
        <strong>{responseAlert.alertType.toUpperCase()}! </strong>  {responseAlert.alertMessage}
      </Alert>
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent py-4 border-0">
          <Row className="mb-4">
            <Col lg="6" md="6" sm="12">
              <Row>
                <Col lg="8" md="8">
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-control-alternative"
                        placeholder="Search Forms"
                        type="text"
                        onChange={filterForms}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="8" md="8">
                  <Label>Select Community</Label>
                  <Select
                    value={communities.filter(c => c.selected)}
                    options={communities}
                    placeholder="Select Community"
                    onChange={(e) => handleCommunityChange(e)}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="6" lg="6">
              <div className="d-flex justify-content-end align-items-center">
                <div className="mr-5">
                  <Button className="btn btn-outline-default btn-sm" onClick={addForm}><i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Add New </Button>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          <Row>
            {filteredFormList.map((data, i) => (
              <Col lg="4" md="4" sm="12" className="mb-3" key={data.id}>
                <Card>
                  <CardBody className="p-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5>{data.name}</h5>
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter className="p-0">
                    <div className="d-flex justify-content-between">
                      <Button color="link" size="lg" type="button" onClick={() => viewForm(data.id)}>
                        <span className="btn-inner--icon" id={"tooltipedit" + i}>
                          <i className="fa fa-edit" aria-hidden="true"></i>
                        </span>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target={"tooltipedit" + i}
                      > Edit
                          </UncontrolledTooltip>
                      <Button color="link" size="lg" type="button" onClick={() => openCloneFormModal(data.id)}>
                        <span className="btn-inner--icon" data-placement="top" id={"tooltipview" + i}>
                          <i class="far fa-clone"></i>
                        </span>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target={"tooltipview" + i}
                      >
                        Clone
                          </UncontrolledTooltip>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      <Modal className="modal-dialog-centered" isOpen={previewCloneFormModal}>
        <div className="modal-header">
          <h4>Clone Form</h4>
          <button
            aria-label="Close"
            className="close shadow-none"
            data-dismiss="modal"
            type="button"
            onClick={toggleCloneFormModal}>
            <span className="btn-inner--icon">
              <i className="fas fa-times" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg="12" md="12" sm="12">
              <FormGroup className="mt-3">
                <Input type="text" value={cloneFormData.formName} placeholder="Enter form name"
                  onChange={(ev) => setCloneFormData({ ...cloneFormData, formName: ev.target.value })} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='12' lg="12" md="12">
              <Label>Select Community</Label>
              <Select
                value={cloneFormData.communities.filter(c => c.selected)}
                options={cloneFormData.communities}
                placeholder="Select Community"
                onChange={(e) => handleCloneModalCommunityChange(e)}
              />
            </Col>
          </Row>
        </div>
        <Label className='text-warning text-center'>{cloneFormData.warningMessage}</Label>
        <div className="modal-footer">
          <Button color="primary" disabled={cloneFormData.okDisabled} onClick={cloneForm}>Clone</Button>
        </div>
      </Modal>
    </>
  )
}

export default FormList