import React, { Component } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    CardFooter
} from "reactstrap";
import service from '../../services';
import axios from "axios";
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';

const accessToken =  localStorage.accessToken;

function readChanged(checked, row, colIndex) {
    switch (colIndex) {
        case 1:
            row.isRead = checked;
            break;
        case 2:
            row.isAdd = checked;
            break;
        case 3:
            row.isUpdate = checked;
            break;
        case 4:
            row.isDelete = checked;
            break;
    }
   
}


class Permission extends Component {
    
    constructor() {
        super();
        this.state = {
            roles: [],
            selectedRole: null,
            rolePermissions: []
        };
        this.changeRole = this.changeRole.bind(this);
        this.updateRolePermissions = this.updateRolePermissions.bind(this);
        //this.readChanged = this.readChanged.bind(this);
    }

    componentDidMount() {
            axios({
                method: 'GET',
                url: service.baseAPIURL + 'permission/getroles',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {
                let roles = response.data.result.roles, roleOptions = [];
                roles.forEach((item, i) => {
                    roleOptions.push({
                        value: item.id,
                        label: item.name,
                        selected: false
                    })
                });
                this.setState({
                    roles: roleOptions
                })
            })
            .catch(error => {
                console.log(error);
            });    
    }

    updateRolePermissions(row) {
        console.log(row);
        let formData = {
            "ModuleId": row.id,
            "RoleId": this.state.selectedRole.value,
            "IsRead": row.isRead,
            "IsAdd": row.isAdd,
            "IsUpdate": row.isUpdate,
            "IsDelete": row.isDelete
        }
        const url = service.baseAPIURL + 'permission/UpdateModulePermission'
        axios.post(url, formData, {headers: { 'content-type': 'application/json' }})
            .then(response => {})
            .catch(error => {
                console.log(error);
            });    
    }



    loadPermissions = roleId => {
        const url = service.baseAPIURL + 'permission/getmodulepermissions?roleId=' + roleId;
        this.setState({
            rolePermissions: []
        })

            axios({
                method: 'GET',
                url: service.baseAPIURL + 'permission/getmodulepermissions?roleId=' + roleId,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {
                let modulePermissions = response.data.result.modulePermissions, Permissions = [];
                modulePermissions.forEach((item, i) => {
                    Permissions.push({
                        id: item.moduleId,
                        moduleName: item.moduleName,
                        isRead: item.isRead,
                        isAdd: item.isAdd,
                        isUpdate: item.isUpdate,
                        isDelete: item.isDelete
                    })
                });
                console.log(Permissions);
                this.setState({
                    rolePermissions: Permissions
                })
            })
            .catch(error => {
                console.log(error);
            });    
    }

    changeRole = selectedRole => {
        this.setState({ selectedRole: selectedRole });
        this.loadPermissions(selectedRole.value);
    };

    render() {
        function rowUpdateFormatter(cell, row) {
            return (
                <Button size="sm" color="primary" onClick={() => this.updateRolePermissions}>Update</Button>
            );
        };

        const columns = [{
            dataField: 'id',
            text: 'Permission Id',
            hidden: true
        }, {
            dataField: 'moduleName',
            text: 'Module'
        }, {
            dataField: 'isRead',
            text: 'Read',
            formatter: function (cell, row) {
                return (
                    <label className="custom-toggle">
                        <input type="checkbox" defaultChecked={row.isRead}  onClick={(e) => readChanged(e.currentTarget.checked, row, 1)} />
                        <span className="custom-toggle-slider rounded-circle" />
                    </label>
                );
            }
        }, {
            dataField: 'isAdd',
            text: 'Create',
                formatter: function (cell, row) {
                return (
                    <label className="custom-toggle">
                        <input type="checkbox" defaultChecked={row.isAdd}  onClick={(e) => readChanged(e.currentTarget.checked, row, 2)} />
                        <span className="custom-toggle-slider rounded-circle" />
                    </label>
                );
            },
        }, {
            dataField: 'isUpdate',
            text: 'Update',
                formatter: function (cell, row) {
                return (
                    <label className="custom-toggle">
                        <input type="checkbox" defaultChecked={row.isUpdate} onClick={(e) => readChanged(e.currentTarget.checked, row, 3)} />
                        <span className="custom-toggle-slider rounded-circle" />
                    </label>
                );
            },
        }, {
            dataField: 'isDelte',
            text: 'Delete',
                formatter: function (cell, row) {
                return (
                    <label className="custom-toggle">
                        <input type="checkbox" defaultChecked={row.isDelete} onClick={(e) => readChanged(e.currentTarget.checked, row, 4)} />
                        <span className="custom-toggle-slider rounded-circle" />
                    </label>
                );
            },
        }, {
            dataField: 'id',
            text: 'Manage',
            formatter: rowUpdateFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.updateRolePermissions(row);
                }
            }
        }];

        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-4">
                        <Row className="text-muted">
                            <Col md="6" lg="6">
                                <h3>Role Permissions</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-2">
                        <Row>
                            <Col md="4" lg="4" sm="4" className="mb-3">
                                <Select
                                    value={this.state.selectedRole}
                                    onChange={this.changeRole}
                                    options={this.state.roles} />
                            </Col>
                        </Row>

                        <BootstrapTable classes="custom-table-css" striped hover condensed
                            keyField='id'
                            data={this.state.rolePermissions}
                            columns={columns} />
                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
            </React.Fragment>
        );
    }
}

export default Permission;