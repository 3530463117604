import Login from '../views/login';
import Communities from '../views/communities/communities';
import AddCommunities from '../views/communities/add-communities';
import AddUser from '../views/users/add-user';
import UpdateUser from '../views/users/update-user';
import UsersList from '../views/users/users-list';
import Permission from '../views/permissions/permission';
import UpdateCommunities from '../views/communities/update-communities';
import Resident from '../views/resident/resident-list';
import AddResident from '../views/resident/add-resisdent';
import UpdateResident from '../views/resident/update-resident';
import Event from '../views/events/events';
import AddEvent from '../views/events/add-events';
import UpdateEvent from '../views/events/update-event';
import Calendar from '../views/calendar/calendar';
import Stories from '../views/configuration/story-list';
import ForgotPassword from '../views/password/forgot-password';
import ResetPassword from '../views/password/reset-password';
import FormList from '../views/forms/forms';
import AddUpdateForm from '../views/forms/add-update-form';
import ResidentForm from '../views/forms/resident-form';
import FormFilter from '../views/forms/form-filter';
import Editor from '../views/editor/editor'



var routes = [{
    path: "/communities",
    name: "Communities",
    icon: "fa fa-users text-primary",
    component: Communities,
    layout: "/admin",
    routeType: 'main',
},
{
    path: "/addcommunity",
    name: "Communities",
    icon: "fa fa-users text-primary",
    component: AddCommunities,
    layout: "/admin",
    routeType: 'sub'
},
{
    path: "/updatecommunity",
    name: "Communities",
    icon: "fa fa-users text-primary",
    component: UpdateCommunities,
    layout: "/admin",
    routeType: 'sub'
},
{
    path: "/users",
    name: "Users",
    icon: "fa fa-user text-primary",
    component: UsersList,
    layout: "/admin",
    routeType: 'main'
},
{
    path: "/permissions",
    name: "Permissions",
    icon: "fa fa-lock text-primary",
    component: Permission,
    layout: "/admin",
    routeType: 'main'
},
{
    path: "/adduser",
    name: "Users",
    icon: "fa fa-users text-primary",
    component: AddUser,
    layout: "/admin",
    routeType: 'sub'
},
{
    path: "/updateuser",
    name: "Users",
    icon: "fa fa-users text-primary",
    component: UpdateUser,
    layout: "/admin",
    routeType: 'sub'
},
{
    path: "/login",
    name: "Login",
    icon: "ni ni-tv-2 text-primary",
    component: Login,
    layout: "/auth"
},
{
    path: "/forgotpassword",
    name: "Forgot Password",
    icon: "ni ni-tv-2 text-primary",
    component: ForgotPassword,
    layout: "/auth"
},
{
    path: "/resetpassword",
    name: "Reset Password",
    icon: "ni ni-tv-2 text-primary",
    component: ResetPassword,
    layout: "/auth"
},
{
    path: "/resident",
    name: "Resident",
    icon: "fa fa-building text-primary",
    component: Resident,
    layout: "/admin",
    routeType: 'main',
},
{
    path: "/addresident",
    name: "Resident",
    icon: "fa fa-building text-primary",
    component: AddResident,
    layout: "/admin",
    routeType: 'sub',
},
{
    path: "/updateresident",
    name: "Resident",
    icon: "fa fa-building text-primary",
    component: UpdateResident,
    layout: "/admin",
    routeType: 'sub',
},
{
    path: "/events",
    name: "Event Manager",
    icon: "fa fa-calendar text-primary",
    component: Event,
    layout: "/admin",
    routeType: 'main',
},
{
    path: "/addevents",
    name: "Event Manager",
    icon: "fa fa-calendar text-primary",
    component: AddEvent,
    layout: "/admin",
    routeType: 'sub',
},
{
    path: "/updateevents",
    name: "Event Manager",
    icon: "fa fa-calendar text-primary",
    component: UpdateEvent,
    layout: "/admin",
    routeType: 'sub',
},
{
    path: "/calendar",
    name: "Calendar",
    icon: "fa fa-calendar-check text-primary",
    component: Calendar,
    layout: "/admin",
    routeType: 'main',
},
{
    path: "/stories",
    name: "Stories",
    icon: "fa fa-bookmark text-primary",
    component: Stories,
    layout: "/admin",
    routeType: 'main',
},
{
    path: "/forms",
    name: "Forms",
    icon: "fa fa-bookmark text-primary",
    component: FormList,
    layout: "/admin",
    routeType: 'main',
},
{
    path: "/addform/community/:cid",
    name: "Forms",
    icon: "fa fa-bookmark text-primary",
    component: AddUpdateForm,
    layout: "/admin",
    routeType: 'sub',
},
{
    path: "/updateform/:fid",
    name: "Forms",
    icon: "fa fa-bookmark text-primary",
    component: AddUpdateForm,
    layout: "/admin",
    routeType: 'sub',
},
{
    path: "/resident/:rid/form/:fid",
    name: "ResidentForms",
    icon: "fa fa-bookmark text-primary",
    component: ResidentForm,
    layout: "/admin",
    routeType: 'sub',
},
{
    path: "/formfilter",
    name: "FormFilter",
    icon: "fa fa-bookmark text-primary",
    component: FormFilter,
    layout: "/admin",
    routeType: 'sub',
},
{
    path: "/editor",
    name: "Editor",
    icon: "fa fa-sticky-note text-primary",
    component: Editor,
    layout: "/admin",
    routeType: 'main',
}

];
export default routes;