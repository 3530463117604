import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal, CardFooter,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Label
} from "reactstrap";
import { Link } from 'react-router-dom';
import service from '../../services';
import axios from "axios";

let accessToken;

class CommunitiesList extends React.Component {
  constructor() {
    super();
    this.state = {
      communityList: [],
      filteredCommunityList: [],
      communities: null,
      previewModal: false,
      communityName: null,
      abbreviation: null,
      streetName: null,
      city: null,
      countryName: null,
      stateName: null,
      phoneNumber: null,
      faxNumber: null,
      avatar: null,
      carelevels: []
    }
  }
  componentDidMount = () => {
    accessToken = localStorage.accessToken
    this.getCommunities()
  }

  viewCommunity = (id) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + 'communities/getcommunitydetails?id=' + id,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let data = response.data.result
      if (data !== null) {
        let selectedCountryVal, selectedStateVal
        data.community.countries.map(item => {
          if (data.community.countryId === item.id) {
            selectedCountryVal = item.name
          }
        })
        data.community.states.map(item => {
          if (data.community.stateId === item.id) {
            selectedStateVal = item.name
          }
        })

        this.setState({
          communityName: data.community.name,
          streetName: data.community.street,
          stateName: selectedStateVal,
          countryName: selectedCountryVal,
          phoneNumber: data.community.phoneNumber,
          faxNumber: data.community.faxNumber,
          city: data.community.city,
          carelevels: data.community.careLevels
        })
        this.toggleModal("previewModal")
      }
    })
      .catch(error => {
        console.log(error);
      });
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  toggleStatus = (id) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + 'communities/togglecommunitystatus?id=' + id + '&status=true',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      if (response.data.result.isSuccess) {
        this.getCommunities()
      }
    })
      .catch(error => {
        console.log(error);
      });
  }

  filterCommunities = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredCommunityList: [...this.state.communityList] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredCommunities = this.state.communityList
        .filter(com => com.name.toLowerCase().indexOf(searchText) >= 0
          || com.stateName.toLowerCase().indexOf(searchText) >= 0);
      this.setState({ filteredCommunityList: [...filteredCommunities] });
    }
  }

  getCommunities = () => {

    axios({
      method: 'GET',
      url: service.baseAPIURL + 'communities/getcommunities?status=true',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let dataList = response.data.result.communities
      console.log(dataList)
      this.setState({
        communityList: dataList,
        filteredCommunityList: dataList
      })
    })
      .catch(error => {
        console.log(error);
      });
  }
  render() {

    const { filteredCommunityList } = this.state;

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search Communities"
                          type="text"
                          onChange={this.filterCommunities}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6" lg="6">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Link className="btn btn-outline-default btn-sm" to="./addcommunity"><i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Add New </Link>
                  </div>


                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              {filteredCommunityList.map((data, i) => (
                <Col lg="4" md="4" sm="12" className="mb-3" key={data.id}>
                  <Card>
                    <CardBody className="p-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5>{data.name}</h5>
                          <p className="small">{data.countryName}<br />
                            {data.stateName}</p>
                        </div>
                        <div>
                          <img width="60" src={service.baseImgURL + data.avatar} className="img-fluid rounded shadow" />
                        </div>
                      </div>
                    </CardBody>
                    <CardFooter className="p-0">
                      <div className="d-flex justify-content-between">
                        <Link class="btn btn-lg" to={'./updatecommunity?' + data.id} data-placement="top" id={"tooltipedit" + i}>
                          <span className="btn-inner--icon">
                            <i className="fa fa-edit" aria-hidden="true"></i>
                          </span>
                        </Link>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"tooltipedit" + i}
                        >
                          Edit
                          </UncontrolledTooltip>
                        <Button color="link" size="lg" type="button" onClick={() => this.viewCommunity(data.id)}><span className="btn-inner--icon" data-placement="top" id={"tooltipview" + i}><i className="fa fa-eye"></i></span></Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"tooltipview" + i}
                        >
                          View
                          </UncontrolledTooltip>
                        <Button color="link" size="lg" type="button" onClick={() => this.toggleStatus(data.id)} data-placement="top" id={"tooltipstatus" + i}><span className="btn-inner--icon">{data.isActive ? <i className="fas fa-power-on text-success"></i> : <i className="fas fa-power-off text-danger"></i>}</span></Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"tooltipstatus" + i}
                        >
                          Status
                          </UncontrolledTooltip>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>

          </CardBody>
        </Card>
        <Modal className="modal-dialog-centered" isOpen={this.state.previewModal}>
          <div className="modal-header">
            <h4>Community Details</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("previewModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row className="justify-content-md-center">
              <Col lg="11" md="11">
                <Row>
                  <Col lg="3" md="2" sm="5" >
                    <img src={this.state.communityPic} class="rounded img-thumbnail" alt="..." />
                  </Col>
                  <Col>
                    <p>{this.state.communityName} </p>
                    <div className="d-flex align-items-start justify-content-between mb-3">
                      <div><i class="fa fa-phone"></i> : {this.state.phoneNumber}</div>
                      <div><i class="fa fa-fax"></i> :  {this.state.faxNumber}</div>
                    </div>

                    <div className="d-flex align-items-start mb-3"><i class="fa fa-map-marker"></i> : <div> {this.state.streetName} , {this.state.city} , {this.state.stateName} , {this.state.countryName}</div></div>

                  </Col>

                </Row>
                <Row>
                  <Col md="12"> {this.state.carelevels.length > 0 ? <p className="border-top mb-3 pt-3">CareLevels</p> : ""}
                  </Col>
                  <Col md="12">
                    <ListGroup flush>
                      <ListGroupItem><div class="d-flex align-items-center justify-content-between">
                        <h6>Care Name</h6> <h6>No of beds</h6></div></ListGroupItem>
                      {this.state.carelevels.map(item => {
                        return (
                          <ListGroupItem><div class="d-flex align-items-center justify-content-between"><Label>{item.name}</Label> <Label>{item.numberOfBeds}</Label></div></ListGroupItem>)
                      })}
                    </ListGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

          </div>
          <div className="modal-footer"></div>

        </Modal>
      </React.Fragment>
    )
  }

}


export default CommunitiesList