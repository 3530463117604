import React from 'react';
import { Row, Col, Label, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { greatestDurationDenominator } from '@fullcalendar/react';

class Scheduler extends React.Component {

    parseRrule = (rrule) => {
        let rruleNotation, rruleExpressions;
        rruleNotation = rrule.split(':')[0];
        rruleExpressions = rrule.split(':')[1];
        var rruleParts = rruleExpressions.split(';');
        let freqRule = rruleParts.filter(s => s.indexOf('FREQ') != -1);
        let countRrule = rruleParts.filter(s => s.indexOf('COUNT') != -1);
        let interValRrule = rruleParts.filter(s => s.indexOf('INTERVAL') != -1);
        let untilRrule = rruleParts.filter(s => s.indexOf('UNTIL') != -1);
        // set Frequency
        let freq = freqRule.length == 0 ? "" : freqRule[0].split('=')[1];
        let count = countRrule.length == 0 ? 0 :countRrule[0].split('=')[1];
        let interval = interValRrule.length == 0 ? 0 :interValRrule[0].split('=')[1];
        let untilDate = untilRrule.length == 0 ? "" :untilRrule[0].split('=')[1];
        let newState = Object.assign({}, this.state);
        newState.schedule.recurrence.repeatInterval = parseInt(interval);
       
        //recurrence end
        if (untilRrule.length == 0 && countRrule.length == 0) {
            newState.schedule.recurrenceRange.selectedOption = "0";
        }
        else if (untilRrule.length == 0 && countRrule.length != 0) {
            newState.schedule.recurrenceRange.selectedOption = "1";
            newState.schedule.recurrenceRange.occurances = parseInt(count);
        }
        else if (untilRrule.length != 0 && countRrule.length == 0) {
            newState.schedule.recurrenceRange.selectedOption = "2";
            newState.schedule.recurrenceRange.endDate = new Date(untilDate.substring(0, 4) + '-' + untilDate.substring(4, 6) + '-' + untilDate.substring(6, 8));
        }
        // End
        if (freq === 'NEVER') {
            // Set Never
            newState.schedule.recurrence.frequency = 1;
            newState.schedule.recurrence.recurrenceLabel = 'Never';
        }
        else if (freq === 'DAILY' && (count==0||count > 1)) {
            //Set Daily
            newState.schedule.recurrence.frequency = 2;
            newState.schedule.recurrence.recurrenceLabel = 'Day(s)';
        }
        else if (freq === 'WEEKLY') {
            //Set WEEKLY
            newState.schedule.recurrence.frequency = 3;
            newState.schedule.recurrence.recurrenceLabel = 'Week(s)';
            let weekRule = rruleParts.filter(s => s.indexOf('BYDAY') != -1);
            let weekString = weekRule[0].split('=')[1];
            let weeks = weekString.split(',');
            weeks.map((obj, index) => {
                let item = newState.schedule.weekly.selectedWeekIndexes.filter(w => w.value === obj);
                if (item.length != 0) {
                    item[0].selected = true;
                }
            })
        }
        else if (freq === 'MONTHLY') {
            //Set MONTHLY
            newState.schedule.recurrence.frequency = 4;
            newState.schedule.recurrence.recurrenceLabel = 'Month(s)';
            let weekRule = rruleParts.filter(s => s.indexOf('BYDAY') != -1);
            let monthRule = rruleParts.filter(s => s.indexOf('BYMONTHDAY') != -1);
            if (monthRule.length != 0) {
                
                newState.schedule.monthly.selectedOption = "0";
                newState.schedule.monthly.selectedDate = parseInt(monthRule[0].split('=')[1]);
            }
            else if (weekRule.length != 0) {
                newState.schedule.monthly.selectedOption = "1";
                let weekString = weekRule[0].split('=')[1];
                let weekDayIndexCombination = weekString.split(',');
                weekDayIndexCombination.map((item, index) => {
                    var _weekIndex = newState.schedule.recurrence.weekIndexes.filter(m => m.value === item.substring(0, 1));
                    var _weekDay = newState.schedule.recurrence.weekDays.filter(m => m.value === item.substring(1, item.length));
                    if (_weekIndex.length != 0 && _weekDay.length!=0) {
                        newState.schedule.monthly.selectedWeekIndex = _weekIndex[0];
                        newState.schedule.monthly.selectedWeekDay = _weekDay[0];
                    }
                })
            }
        }
        else if (freq === 'YEARLY') {
            //Set YEARLY
            debugger;
            newState.schedule.recurrence.frequency = 5;
            newState.schedule.recurrence.recurrenceLabel = 'Year(s)';
            let monthRule = rruleParts.filter(s => s.indexOf('BYMONTH') != -1);
            let monthDayRule = rruleParts.filter(s => s.indexOf('BYMONTHDAY') != -1);
            let weekRule = rruleParts.filter(s => s.indexOf('BYDAY') != -1);

            if (monthRule.length != 0 && monthDayRule.length != 0) {
                newState.schedule.yearly.selectedOption = "0";
                let day = monthDayRule[0].split('=')[1];
                newState.schedule.yearly.selectedDate = parseInt(day);
                let monthIndex = monthRule[0].split('=')[1];
                let selectedMonth = newState.schedule.recurrence.months.filter(m => m.value === monthIndex);
                if (selectedMonth.length != 0) {
                    newState.schedule.yearly.selectedMonth = selectedMonth[0];
                }
            }
            else if (monthRule.length != 0 && weekRule.length != 0) {
                newState.schedule.yearly.selectedOption = "1";
                let monthIndex = monthRule[0].split('=')[1];
                let selectedMonth = newState.schedule.recurrence.months.filter(m => m.value === monthIndex);
                if (selectedMonth.length != 0) {
                    newState.schedule.yearly.selectedMonthWithWeek = selectedMonth[0];
                }
                let weekString = weekRule[0].split('=')[1];
                let weekDayIndexCombination = weekString.split(',');
                weekDayIndexCombination.map((item, index) => {
                    var _weekIndex = newState.schedule.recurrence.weekIndexes.filter(m => m.value === item.substring(0, 1));
                    var _weekDay = newState.schedule.recurrence.weekDays.filter(m => m.value === item.substring(1, item.length));
                    if (_weekIndex.length != 0 && _weekDay.length != 0) {
                        newState.schedule.yearly.selectedWeekIndex = _weekIndex[0];
                        newState.schedule.yearly.selectedWeekDay = _weekDay[0];
                    }
                })
            }
        }
        this.setState(newState);
    };

    constructor(props) {
        super(props);
        this.state = {
            schedule: {
                recurrence: {
                    frequency: 1,
                    repeatInterval:1,
                    recurrenceLabel:'Never',
                    weeks: ["Sun", "Mon", "Tue", "Web", "Thu", "Fri", "Sat"],
                    weekIndexes: [{ label: "first", value: "1" }, { label: "second", value: "2" }, { label: "third", value: "3" }, { label: "fourth", value: "4" }, { label: "last", value: "5" }],
                    weekDays: [
                        { label: "Sunday", value: "SU" },
                        { label: "Monday", value: "MO" },
                        { label: "Tuesday", value: "TU" },
                        { label: "Wednesday", value: "WE" },
                        { label: "Thursday", value: "TH" },
                        { label: "Friday", value: "FR" },
                        { label: "Saturday", value: "SA" }
                    ],
                    months: [
                        { label: "January", value: "1" },
                        { label: "February", value: "2" },
                        { label: "March", value: "3" },
                        { label: "April", value: "4" },
                        { label: "May", value: "5" },
                        { label: "June", value: "6" },
                        { label: "July", value: "7" },
                        { label: "August", value: "8" },
                        { label: "September", value: "9" },
                        { label: "October", value: "10" },
                        { label: "November", value: "11" },
                        { label: "December", value: "12" }
                    ],
                    defaultValue:1
                },
                weekly: {
                    selectedWeekIndexes: [
                        { label: 'Sun', value: 'SU', selected: false },
                        { label: 'Mon', value: 'MO', selected: false },
                        { label: 'Tue', value: 'TU', selected: false },
                        { label: 'Wed', value: 'WE', selected: false },
                        { label: 'Thu', value: 'TH', selected: false },
                        { label: 'Fri', value: 'FR', selected: false },
                        { label: 'Sat', value: 'SA', selected: false }
                    ]
                },
                monthly: {
                    selectedOption: "0",
                    selectedWeekIndex: { label: "first", value: "1" },
                    selectedWeekDay: { label: "Sunday", value: "SU" },
                    selectedDate:1
                },
                yearly: {
                    selectedOption: "0",
                    selectedMonth: { label: "January", value: "1" },
                    selectedMonthWithWeek: { label: "January", value: "1" },
                    selectedDate: 1,
                    selectedWeekIndex: { label: "first", value: "1" },
                    selectedWeekDay: { label: "Sunday", value: "SU" },
                },
                recurrenceRange: {
                    repeatEvery: 1,
                    occurances: 1,
                    selectedOption: "0",
                    endDate:new Date()
                }
            }
        };
    }

    componentDidMount() {
        if (this.props.rrule !== "") {
            this.parseRrule(this.props.rrule);
        }
    }

    changeFrequency = (index, label) => {
        var newState = Object.assign({}, this.state);
        newState.schedule.recurrence.frequency = index;
        newState.schedule.recurrence.recurrenceLabel = label;
        newState.schedule.recurrence.repeatInterval = 1;
        newState.schedule.recurrenceRange.selectedOption = "0";
        newState.schedule.recurrenceRange.occurances = 1;
        newState.schedule.recurrenceRange.endDate = new Date();
        newState.schedule.weekly.selectedWeekIndexes.map((week) => {
            week.selected = false;
        });
        newState.schedule.monthly.selectedOption = "0";
        newState.schedule.yearly.selectedOption = "0";
        this.setState(newState);
    }

    changeRepeatInterval = (e) => {
        var target = e.target;
        this.setState(prevState => ({
            schedule: {
                ...prevState.schedule,
                recurrence: {
                    ...prevState.schedule.recurrence,
                    repeatInterval: target.value
                }
            }
        }))
    }

    repeatEndChanged = (e) => {
        var target = e.target;
        this.setState(prevState => ({
            schedule: {
                ...prevState.schedule,
                recurrenceRange: {
                    ...prevState.schedule.recurrenceRange,
                    selectedOption: target.value
                }
            }
        }))
    }

    repeatEndOccurancesChanged = (e) => {
        var target = e.target;
        this.setState(prevState => ({
            schedule: {
                ...prevState.schedule,
                recurrenceRange: {
                    ...prevState.schedule.recurrenceRange,
                    occurances: target.value
                }
            }
        }))
    }

    repeatEndDateChanged = (date) => {
        this.setState(prevState => ({
            schedule: {
                ...prevState.schedule,
                recurrenceRange: {
                    ...prevState.schedule.recurrenceRange,
                    endDate: date
                }
            }
        }))
    }

    selectWeekDay = (weekName) => {
        var newState = Object.assign({}, this.state);
        var selectedItem = newState.schedule.weekly.selectedWeekIndexes.filter(c => c.value == weekName);
        if (selectedItem.length > 0) {
            selectedItem[0].selected = true;
        }
        this.setState(newState);
    }


   
    repeatOptionChanged = (value, freq, paramType) => {
        var newState = Object.assign({}, this.state);
        if (freq === "monthly" && paramType === "option") {
            newState.schedule.monthly.selectedOption = value;
        }
        if (freq === "yearly" && paramType === "option") {
            newState.schedule.yearly.selectedOption = value;
        }

        if (freq === "monthly" && paramType === "day") {
            newState.schedule.monthly.selectedDate = value;
        }
        if (freq === "yearly" && paramType === "day") {
            newState.schedule.yearly.selectedDate = value;
        }

        if (freq === "yearly" && paramType === "weekIndex") {
            newState.schedule.yearly.selectedWeekIndex = value;
        }
        if (freq === "monthly" && paramType === "weekIndex") {
            newState.schedule.monthly.selectedWeekIndex = value;
        }

        if (freq === "monthly" && paramType === "weekDay") {
            newState.schedule.monthly.selectedWeekDay = value;
        }
        if (freq === "yearly" && paramType === "weekDay") {
            newState.schedule.yearly.selectedWeekDay = value;
        }

        if (freq === "yearly" && paramType === "month") {
            newState.schedule.yearly.selectedMonth = value;
        }
        if (freq === "yearly" && paramType === "monthwithweek") {
            newState.schedule.yearly.selectedMonthWithWeek = value;
        }
        this.setState(newState);
    }
  
    render() {
        const { schedule } = this.state;
        const { changeRepeatInterval,repeatOptionChanged,
                selectWeekDay, repeatEndChanged, repeatEndOccurancesChanged, repeatEndDateChanged } = this;
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
               
            }),
            control: (provided) => ({
                ...provided,
                height: "35px",
            })
        }
        const repeatComponent = function (schedule) {
            if (schedule.recurrence.frequency === 3) {
                return (
                    <ButtonGroup className="mb-2" size="sm">
                        {
                            schedule.weekly.selectedWeekIndexes.map((week) =>
                                <Button color={week.selected ? 'primary' : ''}
                                    active={week.selected ? true : false}
                                    onClick={() => selectWeekDay(week.value)}>{week.value}</Button>
                            )
                        }
                    </ButtonGroup>
                )
            }
            else if (schedule.recurrence.frequency === 4){
                return (
                    <React.Fragment>
                        <input type="hidden" value={schedule.monthly.selectedOption} />
                        <Row className="mb-1">
                            <Col>
                                <Label check>
                                    <input type="radio" name="monthlyRepeat" value={schedule.monthly.selectedOption}
                                        defaultChecked={schedule.monthly.selectedOption === "0"}
                                        onChange={(e) => repeatOptionChanged("0","monthly","option")}/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;Date &nbsp;&nbsp;
                                </Label>
                                <input type="number" min="1" max="31" style={{ width: '50px' }}
                                    disabled={!(schedule.monthly.selectedOption === "0")}
                                    value={schedule.monthly.selectedDate}
                                    onChange={(e) => repeatOptionChanged(e.target.value,"monthly","day")} className="form-control" />
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Label check style={{ paddingLeft:'15px' }}>
                                <input type="radio" name="monthlyRepeat" value={schedule.monthly.selectedOption}
                                    defaultChecked={schedule.monthly.selectedOption === "1"}
                                    onChange={(e) => repeatOptionChanged("1", "monthly", "option")} />
                                                &nbsp;
                                </Label>
                            <Col md="2">
                                <Select styles={customStyles}
                                    value={schedule.monthly.selectedWeekIndex}
                                    onChange={(option) => repeatOptionChanged(option,"monthly","weekIndex")}
                                    isDisabled={!(schedule.monthly.selectedOption === "1")}
                                    options={schedule.recurrence.weekIndexes} />
                            </Col>
                            <Col md="2">
                                <Select styles={customStyles}
                                    value={schedule.monthly.selectedWeekDay}
                                    onChange={(option) => repeatOptionChanged(option, "monthly", "weekDay")}
                                    isDisabled={!(schedule.monthly.selectedOption === "1")}
                                    options={schedule.recurrence.weekDays} />
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            }
            else if (schedule.recurrence.frequency === 5) {
                return (
                    <React.Fragment>
                        <input type="hidden" value={schedule.yearly.selectedOption} />
                        <Row className="mb-1">
                            <Label check style={{ paddingLeft: '15px' }}>
                                <input type="radio" name="yearlyRepeat" value="0"
                                    defaultChecked={schedule.yearly.selectedOption==="0"}
                                    onChange={(e) => repeatOptionChanged("0", "yearly", "option")}/>
                                                &nbsp;
                                </Label>
                            <Col md="3">
                                <Select styles={customStyles}
                                    value={schedule.yearly.selectedMonth}
                                    isDisabled={!(schedule.yearly.selectedOption === "0")}
                                    onChange={(option) => repeatOptionChanged(option, "yearly", "month")}
                                    options={schedule.recurrence.months}
                                />
                            </Col>
                            <Col md="2">
                                <input type="number" min="1" max="31" style={{ width: '50px' }}
                                    disabled={!(schedule.yearly.selectedOption === "0")}
                                    onChange={(e) => repeatOptionChanged(e.target.value, "yearly", "day")}
                                    value={schedule.yearly.selectedDate}
                                    className="form-control" />
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Label check style={{ paddingLeft: '15px' }}>
                                <input type="radio" name="yearlyRepeat" value="1"
                                    defaultChecked={schedule.yearly.selectedOption==="1"}
                                    onChange={(e) => repeatOptionChanged("1", "yearly", "option")}/>
                                                &nbsp;
                                </Label>
                            <Col md="2">
                                <Select styles={customStyles}
                                    value={schedule.yearly.selectedWeekIndex}
                                    isDisabled={!(schedule.yearly.selectedOption === "1")}
                                    onChange={(option) => repeatOptionChanged(option, "yearly", "weekIndex")}
                                    options={schedule.recurrence.weekIndexes}
                                />
                            </Col>
                            <Col md="2">
                                <Select styles={customStyles}
                                    value={schedule.yearly.selectedWeekDay}
                                    isDisabled={!(schedule.yearly.selectedOption === "1")}
                                    onChange={(option) => repeatOptionChanged(option, "Yearly", "weekDay")}
                                    options={schedule.recurrence.weekDays}
                                />
                            </Col>
                            <label>&nbsp;of&nbsp;</label>
                            <Col md="3">
                                <Select styles={customStyles}
                                    value={schedule.yearly.selectedMonthWithWeek}
                                    isDisabled={!(schedule.yearly.selectedOption === "1")}
                                    onChange={(option) => repeatOptionChanged(option, "yearly", "monthwithweek")}
                                    options={schedule.recurrence.months}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            }
        }

        const schedulerComponent = function (schedule) {
            let frequency = schedule.recurrence.frequency;
            if (frequency === 3 || frequency === 4 || frequency === 5) {
                return (
                    <React.Fragment>
                        <Row className="mb-2">
                            <Col md="2">
                                <Label>Repeat every:</Label>
                            </Col>
                            <Col md="3">
                            <div class="d-flex align-items-center">
                                <input type="number" min="1" style={{ width: '50px' }}
                                    value={schedule.recurrence.repeatInterval}
                                    onChange={(e) => changeRepeatInterval(e)}
                                    className="form-control" />
                                <Label>&nbsp;{schedule.recurrence.recurrenceLabel}</Label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md="2">
                                <Label>Repeat On:</Label>
                            </Col>
                            <Col md="10">
                                {repeatComponent(schedule)}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md="2">
                                <Label>End:</Label>
                            </Col>
                            <Col md="4">
                                <Row className="mb-2">
                                    <Col>
                                        <Label check>
                                            <input type="radio" name="recurrenceRange" value="0"
                                                defaultChecked={schedule.recurrenceRange.selectedOption === "0"}
                                                onChange={(e) => repeatEndChanged(e)} />
                                            &nbsp;&nbsp;Never
                                        </Label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <Label check>
                                            <input type="radio" name="recurrenceRange" value="1"
                                                defaultChecked={schedule.recurrenceRange.selectedOption === "1"}
                                                onChange={(e) => repeatEndChanged(e)} />
                                            &nbsp;&nbsp;After&nbsp;&nbsp;
                                        </Label>
                                        <div class="d-flex align-items-center">
                                        <input type="number" min="1" style={{ width: '50px' }}
                                            disabled={!(schedule.recurrenceRange.selectedOption === "1")}
                                            value={schedule.recurrenceRange.occurances}
                                            onChange={(e) => repeatEndOccurancesChanged(e)}
                                            className="form-control" />
                                        <Label>&nbsp;Occurance(s)</Label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <Label check>
                                            <input type="radio" name="recurrenceRange" value="2"
                                                defaultChecked={schedule.recurrenceRange.selectedOption === "2"}
                                                onChange={(e) => repeatEndChanged(e)} />
                                            &nbsp;&nbsp;On&nbsp;&nbsp;
                                        </Label>
                                        <DatePicker style={{ maxHeight: '35px' }}
                                            onChange={(date) => repeatEndDateChanged(date)}
                                            dateFormat={'MM/dd/yyyy'} selected={schedule.recurrenceRange.endDate}
                                            disabled={!(schedule.recurrenceRange.selectedOption === "2")}
                                            className="form-control" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            }
            else if (frequency==2) {
                return (
                    <React.Fragment>
                        <Row className="mb-2">
                            <Col md="2">
                                <Label>Repeat every:</Label>
                            </Col>
                            <Col md="3">
                            <div class="d-flex align-items-center">
                                <input type="number" min="1" style={{ width: '50px' }}
                                    value={schedule.recurrence.repeatInterval}
                                    onChange={(e) => changeRepeatInterval(e)}
                                    className="form-control" />
                                <Label>&nbsp;{schedule.recurrence.recurrenceLabel}</Label>
                            </div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md="2">
                                <Label>End:</Label>
                            </Col>
                            <Col md="4">
                                <Row className="mb-2">
                                    <Col>
                                        <Label check>
                                            <input type="radio" name="recurrenceRange" value="0"
                                                defaultChecked={schedule.recurrenceRange.selectedOption === "0"}
                                                onChange={(e) => repeatEndChanged(e)} />
                                            &nbsp;&nbsp;Never
                                        </Label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <Label check>
                                            <input type="radio" name="recurrenceRange" value="1"
                                                defaultChecked={schedule.recurrenceRange.selectedOption === "1"}
                                                onChange={(e) => repeatEndChanged(e)}/>
                                            &nbsp;&nbsp;After&nbsp;&nbsp;
                                        </Label>
                                        <div class="d-flex align-items-center">
                                        <input type="number" min="1" style={{ width: '50px' }}
                                            disabled={!(schedule.recurrenceRange.selectedOption === "1")}
                                            value={schedule.recurrenceRange.occurances}
                                            onChange={(e) => repeatEndOccurancesChanged(e)}
                                            className="form-control" />
                                        <Label>&nbsp;Occurance(s)</Label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <Label check>
                                            <input type="radio" name="recurrenceRange" value="2"
                                                defaultChecked={schedule.recurrenceRange.selectedOption === "2"}
                                                onChange={(e) => repeatEndChanged(e)} />
                                            &nbsp;&nbsp;On&nbsp;&nbsp;
                                        </Label>
                                        <DatePicker style={{ maxHeight: '35px' }}
                                            onChange={(date) => repeatEndDateChanged(date)}
                                            dateFormat={'MM/dd/yyyy'} selected={schedule.recurrenceRange.endDate}
                                            disabled={!(schedule.recurrenceRange.selectedOption === "2")}
                                            className="form-control"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            }
        }
        return (
            <React.Fragment>
                <ButtonToolbar>
                    <ButtonGroup className="mb-2">
                        <Button color={schedule.recurrence.frequency === 1 ? 'primary' : ''}
                            active={schedule.recurrence.frequency === 1 ? true : false}
                            onClick={() => this.changeFrequency(1, 'never')}>Never</Button>
                        <Button color={schedule.recurrence.frequency === 2 ? 'primary' : ''}
                            active={schedule.recurrence.frequency === 2 ? true : false}
                            onClick={() => this.changeFrequency(2, 'Day(s)')}>Daily</Button>
                        <Button color={schedule.recurrence.frequency === 3 ? 'primary' : ''}
                            active={schedule.recurrence.frequency === 3 ? true : false}
                            onClick={() => this.changeFrequency(3,'Week(s)')}>Weekly</Button>
                        <Button color={schedule.recurrence.frequency === 4 ? 'primary' : ''}
                            active={schedule.recurrence.frequency === 4 ? true : false}
                            onClick={() => this.changeFrequency(4,'Month(s)')}>Monthly</Button>
                        <Button color={schedule.recurrence.frequency === 5 ? 'primary' : ''}
                            active={schedule.recurrence.frequency === 5 ? true : false}
                            onClick={() => this.changeFrequency(5,'Year(s)')}>Yearly</Button>
                    </ButtonGroup>
                </ButtonToolbar>
                {schedulerComponent(schedule)}
            </React.Fragment>
        )   
    }
}

function dailySchedule() {
    return (
        <Row>
            <Col>
                <Label>Repeat every:</Label>
                <input type="number" min="1" />
                <Label>Day(s)</Label>
            </Col>
        </Row>
    )
}

export default Scheduler;