import React, { Component } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Progress,
    Input,
    Row,
    Col, Toast, ToastBody, ToastHeader,
    CardFooter
} from "reactstrap";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import axios from "axios";
import defaultPic from '../../assets/img/theme/defaultProfile.jpg';
import { strengthIndicator, strengthColor } from '../../components/password-strength/strength-checker';
import userValidators from './user-validation';
import service from '../../services';

let accessToken; 
class AddUser extends Component {
    constructor() {
        super();

        this.state = {
            timeZones: [],
            roles: [],
            communities: [],
            careLevels:[],
            showResponse: false,
            responseMessage:'ok',
            userInfo: {
                email: '',
                password: '',
                retypePassword: '',
                firstName: '',
                lastName: '',
                userPic: defaultPic,
                selectedTimeZone: null,
                selectedRole: null,
                selectedCommunity: null,
                selectedCareLevels: [],
                aboutMe:'',
                userImage: {
                    fileData: null,
                    fileType: '',
                    fileName: ''
                },
                phoneNumber: '',
                showOnSubmit:false
            },
            passwordStrength: {
                value: 0,
                color:''
            }
        }

        this.userValidators = userValidators;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.resetValidators = this.resetValidators.bind(this);
        this.updateValidators = this.updateValidators.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.displayValidationErrors = this.displayValidationErrors.bind(this);
        this.displayErrorBorder = this.displayErrorBorder.bind(this);
    }

    componentDidMount() {
        accessToken = localStorage.accessToken;
        this.getUserDetails();
    }

    getCareLevels = (id) => {
            axios({
                method: 'GET',
                url: service.baseAPIURL+ 'Users/GetCareLevels?userId=0&communityId='+id,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {
                console.log(response);
                let careLevelOptions = [];
                response.data.result.careLevels.forEach((item, index) => {
                    careLevelOptions.push({ label: item.name, value: item.id, isChecked: true });
                });
                this.setState((prevState)=>({
                    careLevels: careLevelOptions,
                    userInfo: {
                        ...prevState.userInfo,
                        selectedCareLevels: careLevelOptions
                    }
                }))
                this.updateValidators("SelectedCareLevels", this.state.userInfo.selectedCareLevels);
            })
            .catch(error => {
                console.log(error);
            });
    }

    getUserDetails = () => {
            axios({
                method: 'GET',
                url: service.baseAPIURL+ 'Users/GetUserDetails?userId=0',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {
                if (response.data.result.user) {
                    let user = response.data.result.user;
                    console.log(user);
                    let timeZoneOptions = [], roleOptions = [], communitiesOptions = [];
                    user.timeZones.forEach((item, index) => {
                        timeZoneOptions.push({ label: item.name, value: item.id });
                    });
                    user.communities.forEach((item, index)=> {
                        communitiesOptions.push({ label: item.name, value: item.id });
                    });
                    user.roles.forEach((item, index)=> {
                        roleOptions.push({ label: item.name, value: item.id });
                    });
                    this.setState({
                        communities: communitiesOptions,
                        roles: roleOptions,
                        timeZones: timeZoneOptions
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    isFormValid() {
        let status = true;
        Object.keys(this.userValidators).forEach((field) => {
            if (!this.userValidators[field].valid) {
                status = false;
            }
        });
        return status;
    }

    updateValidators(fieldName, value) {
        const { userInfo } = this.state;
        if (this.userValidators[fieldName] == undefined) return;
        this.userValidators[fieldName].errors = [];
        this.userValidators[fieldName].state = value;
        this.userValidators[fieldName].valid = true;
        this.userValidators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.userValidators[fieldName].errors.push(rule.message);
                    this.userValidators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function' && rule.fieldToCompare) {
                if (!rule.test(value, userInfo[rule.fieldToCompare])) {
                    this.userValidators[fieldName].errors.push(rule.message);
                    this.userValidators[fieldName].valid = false;
                }
            }
            else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.userValidators[fieldName].errors.push(rule.message);
                    this.userValidators[fieldName].valid = false;
                }
            }
        });
        console.log(this.userValidators);
    }

    handleInputChange(event, inputPropName) {
        const newState = Object.assign({}, this.state);
        if (event.target) {
            newState.userInfo[inputPropName] = event.target.value;
            this.updateValidators(inputPropName, event.target.value);
        }
        else {
            newState.userInfo[inputPropName] = event;
            this.updateValidators(inputPropName, event);
        }
        this.setState(newState);
        if (inputPropName == 'password') {
            let passwordIndicatorValue = strengthIndicator(event.target.value);
            let passwordStrengthColor = strengthColor(passwordIndicatorValue);
            this.setState({
                passwordStrength: {
                    color: passwordStrengthColor,
                    value: passwordIndicatorValue
                }
            })
        }
        if (inputPropName == "selectedCommunity") {
            this.getCareLevels(event.value);
            
        }
    }

    resetValidators() {
        Object.keys(this.userValidators).forEach((fieldName) => {
            this.userValidators[fieldName].errors = [];
            this.userValidators[fieldName].state = '';
            this.userValidators[fieldName].valid = false;
        });
    }

    saveUser = () => {
        var isUserFormValid = this.isFormValid();
        const { userInfo } = this.state;
        if (!isUserFormValid) {
            this.setState(prevState => ({ userInfo: { ...prevState.userInfo,showOnSubmit: true } }));
            this.updateValidators("email", userInfo.email);
            this.updateValidators("password", userInfo.password);
            this.updateValidators("retypePassword", userInfo.retypePassword);
            this.updateValidators("firstName", userInfo.firstName);
            this.updateValidators("lastName", userInfo.lastName);
            this.updateValidators("phoneNumber", userInfo.phoneNumber);
            this.updateValidators("selectedTimeZone", userInfo.selectedTimeZone);
            this.updateValidators("selectedRole", userInfo.selectedRole);
            this.updateValidators("selectedCommunity", userInfo.selectedCommunity);
            this.updateValidators("SelectedCareLevels", userInfo.selectedCareLevels);
            
        }
        else {
            var careLevelOptions = [];
            userInfo.selectedCareLevels.forEach((item, index) => {
                if (item.isChecked) {
                    careLevelOptions.push({ "Id": item.value });
                }
            })
           
            let formData = {
                "Email": userInfo.email,
                'Password': userInfo.password,
                'RetypePassword': userInfo.retypePassword,
                'FirstName': userInfo.firstName,
                'LastName': userInfo.lastName,
                'PhoneNumber': userInfo.phoneNumber,
                'Avatar': 'no-image.gif',
                'TimeZoneId': userInfo.selectedTimeZone.value,
                'AboutMe': userInfo.aboutMe,
                "SelectedRoles": [{ "Id": userInfo.selectedRole.value }],
                "SelectedCommunities": [{ "Id": userInfo.selectedCommunity.value}],
                "SelectedCareLevels": careLevelOptions,
                "FileUtility": {
                    "FileData": userInfo.userImage.fileData,
                    "FileExtension": userInfo.userImage.fileType
                }
            }
                axios({
                    method: 'POST',
                    url: service.baseAPIURL + 'Users/AddUser',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    },
                    data: formData
                })
                .then(response => {
                    console.log(response);
                    if (response.data.result.message != "") {
                        this.setState({
                            showResponse: true,
                            responseMessage: response.data.result.message
                        })
                    }
                    else {
                        this.setState({
                            showResponse: true,
                            responseMessage: response.data.result.message
                        })
                        this.resetUser();
                    }
                    window.scrollTo(0, 0)
                })
                .catch(error => {
                    console.log(error);
                }); 
        }
    };

    displayErrorBorder(fieldName) {
        return !this.userValidators[fieldName].valid && this.userValidators[fieldName].errors.length>0 ? "has-danger" : '';
    }

    displayValidationErrors(fieldName) {
        const validator = this.userValidators[fieldName];
        const result = '';
        if (validator && !validator.valid) {
            const errors = validator.errors.map((info, index) => {
                return <span className="error" key={index}>* {info}</span>;
            });

            return (
                <div className="col s12 row">
                    {errors}
                </div>
            );
        }
        return result;
    }

    changeProfilePic = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            let fileName = file.name.split('.'),
                fileData = reader.result.split('base64,');
            this.setState(prevState => ({
                userInfo: {
                    ...prevState.userInfo,
                    userPic: reader.result,
                    userImage: {
                        fileType: '.' + fileName[fileName.length - 1],
                        fileData: fileData[1],
                        fileName: file.name
                    }
                }
            }));
        }
    }

    resetUser = () => {
        this.resetValidators();
        this.setState({
            userInfo: {
                email: '',
                password: '',
                retypePassword: '',
                firstName: '',
                lastName: '',
                userPic: defaultPic,
                selectedTimeZone: null,
                userImage: {
                    fileData: null,
                    fileType: '',
                    fileName: ''
                },
                phoneNumber: '',
            },
            passwordStrength: {
                value: 0,
                color: ''
            }
        });
    }

    changeCareLevel = (e, careLevel, index) => {
        var isChecked = e.target.checked;
        this.state.userInfo.selectedCareLevels[index].isChecked = isChecked;
       
    }

    render() {
        const profilePicStyle = {
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-Repeat',
            backgroundPosition: 'center',
            backgroundImage: 'url(' + this.state.userInfo.userPic + ')',
            borderRadius: '5px',
            backgroundColor: '#ebebeb'
        }
        const { timeZones, roles, careLevels, communities, userInfo, showResponse, responseMessage, passwordStrength } = this.state;
        return (
            <React.Fragment>
                <Toast style={{ backgroundColor: '#ce0012', margin: '5px', color: 'white' }} isOpen={showResponse}>
                    <ToastHeader icon={responseMessage==""?"success":"warning"}>
                        Add user
                    </ToastHeader>
                    <ToastBody>
                        {responseMessage == "" ? "User added successfully" : responseMessage}
                    </ToastBody>
                </Toast>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-4">
                        <Row className="text-muted">
                            <Col md="6" lg="6">
                                <h3>Add User</h3>
                            </Col>
                            <Col md="6" lg="6" className="text-right">
                                <Link to = './users'>Back</Link>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-2">
                        <Form role="form">
                            <Row>
                                <Col md="3" sm="1" lg="3" className="mb-3 position-relative">
                                    <Input size="sm" onChange={(e) => this.changeProfilePic(e)} id="communityAvatar" type="file" inputprops={{ accept: 'image/*' }} style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0 }} />
                                    <div style={profilePicStyle} ></div>
                                </Col>
                                <Col md="9">
                                    <Row className="mb-3">
                                        <Col md="6" className={this.displayErrorBorder("email")}>
                                            <Input type="email" autoComplete="new-email" size="sm" name="email" placeholder="Enter email" onChange={(e) => this.handleInputChange(e, "email")} />
                                            {this.displayValidationErrors('email')}
                                        </Col>
                                        <Col md="6" className={this.displayErrorBorder("phoneNumber")}>
                                            <Input type="tel" size="sm" placeholder="Enter Phone number" onChange={(e) => this.handleInputChange(e, "phoneNumber")} />
                                            {this.displayValidationErrors('phoneNumber')}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="6" className={this.displayErrorBorder("firstName")}>
                                            <Input type="text" size="sm" placeholder="Enter first name" onChange={(e) => this.handleInputChange(e, "firstName")} />
                                            {this.displayValidationErrors('firstName')}
                                        </Col>
                                        <Col md="6" className={this.displayErrorBorder("lastName")}>
                                            <Input type="text" size="sm" placeholder="Enter last name" onChange={(e) => this.handleInputChange(e, "lastName")} />
                                            {this.displayValidationErrors('lastName')}
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col md="6" className={this.displayErrorBorder("password")}>
                                            <Input type="password" size="sm" placeholder="Enter password" onChange={(e) => this.handleInputChange(e, "password")} />
                                            {this.displayValidationErrors('password')}
                                            <Progress title="Password strength indicator" style={{ marginBottom:0 }} className="mt-1" value={passwordStrength.value} color={passwordStrength.color} />
                                        </Col>
                                        <Col md="6" className={this.displayErrorBorder("retypePassword")}>
                                            <Input type="password" size="sm" placeholder="Retype password" onChange={(e) => this.handleInputChange(e, "retypePassword")} />
                                            {this.displayValidationErrors('retypePassword')}
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col md="12">
                                            <Input type="textarea" size="sm" rows="3" placeholder="Tell us about yourself" onChange={(e) => this.handleInputChange(e, "aboutMe")} />
                                        </Col>
                                        
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md="4" className={this.displayErrorBorder("selectedTimeZone")}>
                                    <Select
                                        value={userInfo.selectedTimeZone}
                                        options={timeZones}
                                        placeholder="Select Timezone"
                                        onChange={(e) => this.handleInputChange(e, "selectedTimeZone")}
                                    />
                                    {this.displayValidationErrors('selectedTimeZone')}
                                </Col>
                                <Col md="4" className={this.displayErrorBorder("selectedRole")}>
                                    <Select
                                        value={userInfo.selectedRole}
                                        options={roles}
                                        placeholder="Select Role"
                                        onChange={(e) => this.handleInputChange(e, "selectedRole")}
                                    />
                                    {this.displayValidationErrors('selectedRole')}
                                </Col>
                                <Col md="4" className={this.displayErrorBorder("selectedCommunity")}>
                                    <Select
                                        value={userInfo.selectedCommunity}
                                        options={communities}
                                        placeholder="Select Community"
                                        onChange={(e) => this.handleInputChange(e, "selectedCommunity")}
                                    />
                                    {this.displayValidationErrors('selectedCommunity')}
                                </Col>
                            </Row>
                            <Row className={this.displayErrorBorder("SelectedCareLevels")}>
                                {
                                    careLevels.map((careLevel,index) => {
                                       return (<Col md="3" sm="12" lg="4">
                                            <div className="d-flex align-items-center h-100">
                                               <label className="custom-toggle">
                                                   <input defaultChecked={careLevel.isChecked}
                                                       onChange={(e) => this.changeCareLevel(e,careLevel, index)} type="checkbox" />
                                                    <span className="custom-toggle-slider rounded-circle" />
                                               </label>
                                               <div className="mb-1 mr-3">{careLevel.label}</div>
                                            </div>
                                        </Col>)
                                    })
                                }
                                {this.displayValidationErrors('SelectedCareLevels')}
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col md="8" lg="8" ></Col>
                            <Col md="4">
                                <Row>
                                    <Col md="12" sm="12" lg="12" >
                                        <Button color="secondary" size="sm" type="button" onClick={this.resetUser}>Cancel</Button>
                                        <Button color="primary" size="sm" type="button" onClick={this.saveUser}>Save</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </React.Fragment>
            )
    }
}

export default AddUser;