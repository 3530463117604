import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col, 
    FormFeedback,
    CardFooter,
    Alert, Label
  } from "reactstrap";
import {Link} from 'react-router-dom';
import Select from 'react-select';
import defaultPic from '../../assets/img/theme/defaultProfile.jpg';
import service from '../../services';
import axios from "axios";
import validator from 'validator';
import DatePicker from "react-datepicker";
import Story from '../../components/story/story';
import "react-datepicker/dist/react-datepicker.css";


let accessToken;
let userId;
class UpdateResident extends React.Component{
  
  constructor(){
    super()
      this.state = {
        residentId:0,
        careLevels : [],
        residentStatus: true,
        firstName: '',
        lastName: '',
        dob: '',
        doj: '',
        location: '',
        selectedCareLevels: [],
        firstNameError: false,
        lastNameError: false,
        dobError: false,
        dojError: false,
        gender: [{
          value: true,
          label: 'Male',
        },
        {
          value: false,
          label: 'Female'
        }],
        selectedGender: '',
        selectedCareLevels: '',
        selectedCareLevelsError: false,
        selectedGenderError: false,
        locationError: false,
        profilePic: defaultPic,
        profilePicError: false,
        selectedProfilePic: false,
        completeForm: false,
        showAlert: false,
        showAlertType: null,
        selectedCareLevel:null,
        residentStories: [],
        residentStoriesVal: []
    };
  }
    componentDidMount = () => {
        accessToken = localStorage.accessToken
        userId = parseInt(localStorage.userId)
        this.getCareLevels()
  }

    getResidentDetails = () => {
        let pathURL = window.location.href
        let getId = pathURL.split('?');
        let id = getId[1];
        var newState = Object.assign({}, this.state);
        newState.residentId = parseInt(id);
        this.setState(newState);
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'residents/GetResident?id=' + id,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(response => {
            console.log(response);
            let data = response.data.result.resident,
                dob = data.dateOfBirth !== null ? data.dateOfBirth.split('T')[0] : '',
                selectedGender = '';

            if (data.gender !== null) {
                selectedGender = {
                    value: data.gender,
                    label: data.gender ? 'Male' : 'Female'
                }
            }
            let careLevelOption = this.state.careLevels.filter(c => c.value === data.careLevelId);
            console.log(careLevelOption[0]);
            this.setState({
                profilePic: data.avatar === null ? '' : service.baseImgURL + data.avatar,
                avatar: data.avatar === null ? 'default.jpg': data.avatar,
                firstName: data.firstName === null ? '' : data.firstName,
                lastName: data.lastName === null ? '' : data.lastName,
                selectedGender: selectedGender,
                selectedCareLevel: careLevelOption[0],
                dob: data.dateOfBirth === null ? '' : new Date(data.dateOfBirth.split('T')[0]),
                doj: data.dateOfJoining === null ? '' : new Date(data.dateOfJoining.split('T')[0]),
                residentStatus: data.isActive,
                location: data.location,
                residentStories: data.residentStories,
                completeForm: true,
                
            })
            console.log(this.state);
        })
            .catch(error => {
                console.log(error);
            });

    }

    getCareLevels = () => {
        userId = parseInt(localStorage.userId)
    const {selectedCareLevelId} = this.state
    axios({
      method: 'GET',
      url: service.baseAPIURL + 'communities/getcarelevelsbyfilter?userId='+userId ,
      headers: {
          'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      console.log(response)
        let careLevelsData = response.data.result.careLevels,
            carelevelOptions = [],
            selectedCareLevel = null

            careLevelsData.forEach((item, i) => {
              if(!item.isActive){  
                if(item.id === selectedCareLevelId)  {
                  selectedCareLevel = {
                    value: item.id,
                    label: item.name
                  }
                }   
                carelevelOptions.push({
                  value: item.id,
                  label: item.name
                })
              }
          });
         
          this.setState({
            careLevels: carelevelOptions,
            selectedCareLevel: selectedCareLevel
          })
        this.getResidentDetails();
      })
      .catch(error => {
        console.log(error);
      });
  }
  changeProfilePic = (e) => {
    e.preventDefault();
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let fileName = file.name.split('.'),
            fileData = reader.result.split('base64,');
        this.setState({
          profilePicFileType: '.'+fileName[1],
          profilePic: reader.result,
          profilePicName : fileData[1],
          profilePicError: false,
          completeForm: true,
          selectedProfilePic: true
        });
   }
  }

  ChangeInput = (text, propertyName, propertyNameError) => {
    this.setState({
      [propertyName]: text,
      completeForm: true
    })
    if(text !== ''){
      this.setState({
        [propertyNameError] : false
      })
    }
  }


  submitDetails = () => {
    const {firstName, lastName, dob, doj, location, selectedCareLevels, selectedGender, selectedProfilePic, completeForm, profilePicName, profilePicFileType, residentId, residentStoriesVal, residentStories, avatar } = this.state
    if(validator.isEmpty(firstName)){
      this.setState({
        firstNameError: true,
        completeForm : false
      })
    }
    if(validator.isEmpty(lastName)){
      this.setState({
        lastNameError: true,
        completeForm : false
      })
    }
    if(dob === ""){
      this.setState({
        dobError: true,
        completeForm : false
      })
    }
    if(doj === ""){
      this.setState({
        dojError: true,
        completeForm : false
      })
    }
    if(dob === doj){
      this.setState({
        dojError: true,
        completeForm : false
      })
    }
    if(selectedGender === ""){
      this.setState({
        selectedGenderError: true,
        completeForm : false
      })
    }
    
    if(validator.isEmpty(location)){
      this.setState({
        locationError: true,
        completeForm : false
      })
    }
    if(completeForm){
      let storiesArry = []
      if(residentStoriesVal.length > 0){
        storiesArry = residentStoriesVal
      }
      else{
        residentStories.map((item) => {
          let val = {
            "id": 0,
            "residentId": residentId,
            "storyId": item.storyId,
            "storyValue": item.storyValue,
            "name": item.name,
            "description": item.description
          }
          storiesArry.push(val)
        })
      }
      debugger
      let formData = {
        "Id": residentId,
        "FirstName": firstName,
        "LastName": lastName,
        "DateOfBirth": dob,
        "DateOfJoining" : doj,
        "Gender": selectedGender.value,
        "Location": location,
        "Avatar": avatar,
        "FileUtility":{
          "FileData": profilePicName,
          "FileExtension": profilePicFileType
        },
        "residentStories": storiesArry
      }
      axios({
        method: 'POST',
        url: service.baseAPIURL + 'residents/updateresident',
        headers: {
            'Authorization': 'Bearer ' + accessToken
        },
        data: formData
      }).then(response => {
        if(response.data.result != null && response.data.result.isSuccess){
          this.setState({
            showAlert: true,
            showAlertType : 'success'
          })
          }
        else if(response.data.errors != null){
          this.setState({
            showAlert: true,
            showAlertType : 'danger'
          })
        }
        window.scrollTo(0, 0)
        })
        .catch(error => {
          console.log(error);
        });

    }
  }

  // toggle the status

  toggleStatus = (e) => {
    this.setState({
      residentStatus: e.target.checked
    })
  }

  handleGender = selectedGender => {
    this.setState({ selectedGender: selectedGender.value, completeForm:true, selectedGenderError: false });
  };

  handleCarelevels = selectedCarelevel => {
    this.setState({ selectedCareLevels: selectedCarelevel.value, completeForm:true, selectedCareLevelsError: false });
  };

  handleDOBChange = startDate => {
    this.setState({
      dob: startDate,
      dobError: false,
      completeForm: true
    });
  };
  handleDOJChange = joinDate => {
    this.setState({
      doj: joinDate,
      dojError: false,
      completeForm: true
    });
  };

  addValues = (data) => {
    debugger;
    let residentStoriesValNew = [... this.state.residentStories, data] 
    const key = 'storyId';
    const arrayUniqueByKey = [...new Map(residentStoriesValNew.map(item =>
      [item[key], item])).values()];
    this.setState({
      residentStoriesVal: arrayUniqueByKey
    })
  }

  
    render() {
        const { careLevels, residentStatus, firstNameError, location, gender, lastNameError, dobError, dojError, selectedGenderError, locationError, selectedCareLevelsError, profilePicError, showAlert, showAlertType, dob, doj, firstName, lastName, selectedGender, selectedCareLevel, residentStories } = this.state
     
      const profilePicStyle = {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat:'no-Repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url('+ this.state.profilePic +')',
        borderRadius: '5px',
        backgroundColor: '#ebebeb'
      }
      const profilePicStyleError = {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat:'no-Repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url('+ this.state.profilePic +')',
        borderRadius: '5px',
        backgroundColor: '#ebebeb',
        borderWidth: '1px',
        borderColor: '#fb6340',
        borderStyle:'solid'
    }
    let alertMessage, alertStatus
    if(this.state.showAlertType === 'success'){
      alertMessage =  'resident added'
      alertStatus ="Success"
    }
    else{
      alertMessage = 'resident couldn\'t be added'
      alertStatus ="Error"
    }
   
      return(
            <>
            <Alert color={showAlertType} isOpen={showAlert} toggle={(e) => this.setState({showAlert: false})}>
            <strong>{alertStatus}! </strong>  {alertMessage}
          </Alert>
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent py-4">
                
                  <Row className="text-muted">
                    <Col md="6" lg="6">
                    <h3>Update Resident Details</h3>
                    </Col>
                                      
                  </Row>   
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                
                  <Form role="form">
                    <Row>
                    <Col md="3" sm="12" lg="3" className="mb-3">
                      <div className="position-relative profilePic">
                        <Input onChange={(e) => this.changeProfilePic(e)}  type="file" inputprops={{ accept: 'image/*' }} style={{position: 'absolute', width:'100%', height: '100%', opacity:0}}/>
                        <div style={profilePicError ? profilePicStyleError : profilePicStyle} ></div>
                        {profilePicError ? <div className="error-msg">Upload a profile pic</div>: ''}
                      </div>
                      </Col>
                      <Col md="9" sm="12" lg="9">
                        <Row>
                          <Col>
                            <FormGroup className={firstNameError ? 'has-danger mb-3' : 'mb-3'}>
                          <Label>Firstname</Label>
                            <Input value={firstName}  type="text" onChange={(e) => this.ChangeInput(e.target.value, 'firstName', 'firstNameError')}  invalid={firstNameError}/>
                            <FormFeedback>Enter a first name</FormFeedback>
                          </FormGroup>
                          </Col>
                          <Col>
                      <FormGroup className={lastNameError ? 'has-danger mb-3' : 'mb-3'}>
                      <Label>Lastname</Label>
                           <Input value={lastName}  type="text" onChange={(e) => this.ChangeInput(e.target.value, 'lastName', 'lastNameError')}  invalid={lastNameError}/>
                           <FormFeedback>Enter a lastname</FormFeedback>
                        </FormGroup>
                      </Col>
                      </Row>
                      <Row className="mb-3">
                      <Col>
                      <div className={dobError ? 'has-danger d-flex align-items flex-column' : 'd-flex align-items flex-column'}>
                          <Label>Date of Birth</Label>
                         <DatePicker selected={dob} dateFormat="yyy-MM-dd"  placeholderText="YYYY-MM-DD" allowSameDay={false} onChange={this.handleDOBChange} className={dobError ? 'is-invalid form-control' : 'form-control'}/>
                         {dobError ? <div className="error-msg">Enter a valid DOB</div>: ''}
                        </div>
                      </Col>
                      <Col >
                      <div className={dojError ? 'has-danger d-flex align-items flex-column' : 'd-flex align-items flex-column'}>
                          <Label>Date of Joining</Label>
                         <DatePicker selected={doj} dateFormat="yyy-MM-dd" placeholderText="YYYY-MM-DD" allowSameDay={false} onChange={this.handleDOJChange} className={dojError ? 'is-invalid form-control' : 'form-control'}/>
                         {dojError ? <div className="error-msg">Enter a valid DOJ</div>: ''}
                        </div>
                      </Col>
                      <Col>
                      <FormGroup className={selectedGenderError ? 'has-danger mb-3' : 'mb-3'}>
                        <Label>Gender </Label>
                        <Select
                          value={selectedGender}
                          options={gender} 
                          onChange={this.handleGender}
                          className={selectedGenderError ? 'is-invalid': ''}/>
                         {selectedGenderError ? <div className="error-msg">Enter a valid gender</div>: ''}
                        </FormGroup>
                        </Col>
                        </Row>
                        </Col>
                      
                    
                      <Col md="4" sm="12" lg="4">
                        <FormGroup className={locationError ? 'has-danger mb-3' : 'mb-3'}>
                            <Label>Location</Label>
                            <Input type="text" value={location} invalid={locationError} onChange={(e) => this.ChangeInput(e.target.value, 'location', 'location  Error')} />
                          <FormFeedback>Enter your location</FormFeedback>
                        </FormGroup>
                        </Col>
                     
                      <Col md="4" sm="12" lg="4">
                      <FormGroup className={selectedCareLevelsError ? 'has-danger mb-3' : 'mb-3'}>
                        <Label>CareLevels </Label>
                        <Select
                        value={selectedCareLevel}
                          options={careLevels} 
                          onChange={this.handleCarelevels}
                          />
                          
                          {selectedCareLevelsError ? <div className="error-msg">Select a carelevel</div>: ''}
                        </FormGroup>
                        </Col>
                    
                      <Col md="4" sm="12" lg="4">    
                        <div className="d-flex align-items-center justify-content-between h-100">
                            <div className="mb-1 mr-3">Active</div>
                            <label className="custom-toggle">
                              <input defaultChecked={residentStatus} type="checkbox" onClick = {(e) => this.toggleStatus(e)} />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                        </div>
                      </Col>
                      
                    </Row>
                   <Row>
                   {residentStories.map((item, key) => {
                      return <Story key={key} item={item} addValues={this.addValues} residentId={this.state.residentId} />
                    })} 
                   </Row>
                    
                  </Form>
                </CardBody>
                <CardFooter>
                <Row>
                  <Col md="2" lg="2" className="offset-md-10">
                        <div className="d-flex align-items-center justify-content-between">
                            <Link className="btn btn-secondary btn-sm" to = './resident'>Cancel</Link>
                            <Button color="primary" size="sm" type="button" onClick={this.submitDetails}>Update</Button>
                            
                        </div>
                       
                      </Col>               
                    </Row>
                </CardFooter>
              </Card>
            </>
        )
    }
    
}

export default UpdateResident
