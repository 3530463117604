import React from 'react';
import {
    Input,
    Label,
    Row,
    Col,
    Button
} from "reactstrap";
import service from '../../services';
import axios from "axios";
import refreshToken from '../../refresh';
import storyValidators from './story-validation';

let accessToken, userId
export default class StoryDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            story: props.story,
            showOnSubmit:false
        }
        this.storyValidators = storyValidators;
    }

    cancelDetail = () => {
        debugger;
        this.props.cancelDetail(this.props.story,"cancel");
    }

    displayErrorBorder = (fieldName) => {
        return !this.storyValidators[fieldName].valid && this.storyValidators[fieldName].errors.length > 0 ? "has-danger" : '';
    };

    displayValidationErrors = (fieldName) => {
        const validator = this.storyValidators[fieldName];
        const result = '';
        if (validator && !validator.valid) {
            const errors = validator.errors.map((info, index) => {
                return <span className="error" key={index}>* {info}</span>;
            });

            return (
                <div className="col s12 row">
                    {errors}
                </div>
            );
        }
        return result;
    }

    handleInputChange = (event, inputPropName) => {
        const newState = Object.assign({}, this.state);
        if (event.target) {
            newState.story[inputPropName] = event.target.value;
            this.updateValidators(inputPropName, event.target.value);
        }
        this.setState(newState);
    }

    updateValidators = (fieldName, value) => {
        const { story } = this.state;
        if (this.storyValidators[fieldName] == undefined) return;
        this.storyValidators[fieldName].errors = [];
        this.storyValidators[fieldName].state = value;
        this.storyValidators[fieldName].valid = true;
        this.storyValidators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.storyValidators[fieldName].errors.push(rule.message);
                    this.storyValidators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function' && rule.fieldToCompare) {
                if (!rule.test(value, story[rule.fieldToCompare])) {
                    this.storyValidators[fieldName].errors.push(rule.message);
                    this.storyValidators[fieldName].valid = false;
                }
            }
            else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.storyValidators[fieldName].errors.push(rule.message);
                    this.storyValidators[fieldName].valid = false;
                }
            }
        });
    }

    isFormValid = () => {
        let status = true;
        Object.keys(this.storyValidators).forEach((field) => {
            if (!this.storyValidators[field].valid) {
                status = false;
            }
        });
        return status;
    }

    UpdateStory = () => {
        var isStoryFormValid = this.isFormValid();
        let newState = Object.assign({}, this.state);
        const { story } = this.state;
        if (!isStoryFormValid) {
            newState.showOnSubmit = true;
            this.updateValidators("name", story.name);
            this.updateValidators("description", story.description);
            this.setState(newState);
            return;
        }
        let frmData = {
            "id": this.state.story.id,
            "name": this.state.story.name,
            "description": this.state.story.description,
            "isActive":true
        }
        accessToken = localStorage.accessToken
        let command = this.state.story.id === 0 ? "insert" : "update";
        axios({
            method: 'POST',
            url: service.baseAPIURL + (this.state.story.id === 0 ? 'configuration/addstory' : 'configuration/updatestory'),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: frmData
        }).then(response => {
            if (response.data.result.isSuccess) {
                frmData.id = parseInt(response.data.result.storyId);
                this.props.cancelDetail(frmData, command);
            }
        })
            .catch(error => {
                if (error.request.status == 401) {
                    refreshToken()
                }
            });
    }

    handleChangeInput = (value, propName) => {
        let newState = Object.assign({}, this.state);
        this.updateValidators(propName, value);
        newState.story[propName] = value;
        this.setState(newState);
    }

    render() {
        return (<>
            <Row>
                <Col md="6" sm="12" lg="6" className={this.displayErrorBorder("name")}>
                    <Label> Name</Label>
                    <Input value={this.state.story.name} type="text" onChange={(e) => this.handleChangeInput(e.target.value,'name')} autoComplete="new-name" />
                    {this.displayValidationErrors('name')}
                </Col>
                <Col md="6" sm="12" lg="6" className={this.displayErrorBorder("description")}>
                    <Label> Decription</Label>
                    <Input type="textarea" value={this.state.story.description} onChange={(e) => this.handleChangeInput(e.target.value,'description')} autoComplete="new-name"  />
                    {this.displayValidationErrors('description')}
                </Col>
            </Row>
            <Row>
                <Col md="12" sm="12" lg="12">
                    <Button color="danger" size="sm" onClick={this.UpdateStory}>Update</Button>
                    <Button color="danger" size="sm" onClick={this.cancelDetail}>Cancel</Button>
                </Col>
            </Row>
        </>)
    }
}