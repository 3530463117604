import moment from 'moment';

const Helper = {
    generateRrule: function (schedule) {
        var rrule = "";
        let startDate = moment(new Date());
        let weekDay = startDate.format('dddd');
        switch (schedule.recurrence.frequency) {
            case 1:
                // Never
                rrule = "FREQ=Never;COUNT=1;INTERVAL=1;WKST=" + weekDay.substring(0, 2).toUpperCase();
                break;
            case 2:
                // Daily
                if (schedule.recurrenceRange.selectedOption === "0") {
                    rrule = "FREQ=DAILY;INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase();
                }
                else if (schedule.recurrenceRange.selectedOption === "1") {
                    rrule = "FREQ=DAILY;COUNT=" + schedule.recurrenceRange.occurances + ";INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase();
                }
                else if (schedule.recurrenceRange.selectedOption === "2") {
                    rrule = "FREQ=DAILY;UNTIL=" + Helper.rruleDate(schedule.recurrenceRange.endDate) + ";INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase();
                }
                break;
            case 3:
                // Weekly
                var weekString=schedule.weekly.selectedWeekIndexes.filter(m => m.selected == true).map(({ value }) => `${value}`).join(',')
                if (schedule.recurrenceRange.selectedOption === "0") {
                    rrule = "FREQ=WEEKLY;INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + ';BYDAY=' + weekString;
                }
                else if (schedule.recurrenceRange.selectedOption === "1") {
                    rrule = "FREQ=WEEKLY;COUNT=" + schedule.recurrenceRange.occurances + ";INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + ';BYDAY=' + weekString;
                }
                else if (schedule.recurrenceRange.selectedOption === "2") {
                    rrule = "FREQ=WEEKLY;UNTIL=" + Helper.rruleDate(schedule.recurrenceRange.endDate) + ";INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + ';BYDAY=' + weekString;
                }
                break;
            case 4:
                // Monthly
                let monthRule = "";
                if (schedule.monthly.selectedOption === "0") {
                    monthRule = ";BYMONTHDAY=" + schedule.monthly.selectedDate;
                }
                else if (schedule.monthly.selectedOption === "1") {
                    monthRule = ";BYDAY=" + schedule.monthly.selectedWeekIndex.value + schedule.monthly.selectedWeekDay.value;
                }
                //var weekString = schedule.weekly.selectedWeekIndexes.filter(m => m.selected == true).map(({ value }) => `${value}`).join(',')
                if (schedule.recurrenceRange.selectedOption === "0") {
                    rrule = "FREQ=MONTHLY;INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + monthRule;
                }
                else if (schedule.recurrenceRange.selectedOption === "1") {
                    rrule = "FREQ=MONTHLY;COUNT=" + schedule.recurrenceRange.occurances + ";INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + monthRule;
                }
                else if (schedule.recurrenceRange.selectedOption === "2") {
                    rrule = "FREQ=MONTHLY;UNTIL=" + Helper.rruleDate(schedule.recurrenceRange.endDate) + ";INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + monthRule;
                }
                break;
            case 5:
                // Yearly
                let yearRule = "";
                if (schedule.yearly.selectedOption === "0") {
                    yearRule = ";BYMONTH=" + schedule.yearly.selectedMonth.value + ";BYMONTHDAY=" + schedule.yearly.selectedDate;
                }
                else if (schedule.yearly.selectedOption === "1") {
                    yearRule = ";BYMONTH=" + schedule.yearly.selectedMonthWithWeek.value + ";BYDAY=" + schedule.yearly.selectedWeekIndex.value + schedule.yearly.selectedWeekDay.value;
                }
                //var weekString = schedule.weekly.selectedWeekIndexes.filter(m => m.selected == true).map(({ value }) => `${value}`).join(',')
                if (schedule.recurrenceRange.selectedOption === "0") {
                    rrule = "FREQ=YEARLY;INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + yearRule;
                }
                else if (schedule.recurrenceRange.selectedOption === "1") {
                    rrule = "FREQ=YEARLY;COUNT=" + schedule.recurrenceRange.occurances + ";INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + yearRule;
                }
                else if (schedule.recurrenceRange.selectedOption === "2") {
                    rrule = "FREQ=YEARLY;UNTIL=" + Helper.rruleDate(schedule.recurrenceRange.endDate) + ";INTERVAL=" + schedule.recurrence.repeatInterval + ";WKST=" + weekDay.substring(0, 2).toUpperCase() + yearRule;
                }
                break;

        }
        return "RRULE:" + rrule;
    },
    rruleDate:function (date) {
        let newDate = moment(date);
        return newDate.format('YYYYMMDD') + 'T000000Z';
    }
}

export default Helper;