import React from 'react';
import {Card, CardBody, CardHeader, Row, Col} from 'reactstrap'



export default class Editor extends React.Component {
    render(){
        return(
            <React.Fragment>
                 <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3>Add Community</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
          <p><a href="./editor.html">Click here</a> for editor</p>
              </CardBody>
              </Card>
               
            </React.Fragment>
           
        )
    }
}