import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Spinner,
    Label,
} from "reactstrap";
import Scheduler from '../../components/events/scheduler';
import Helper from '../../helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment from 'moment';
import service from '../../services';
import axios from "axios";
import refreshToken from '../../refresh';
import { RRule, RRuleSet, rrulestr } from 'rrule'


function deleteEvent (row, deleteType) {
    let eventData = { 
        "eventId":row.eventId, 
        "deleteType": deleteType, 
        "occuranceDate": moment(row.startDate).format('YYYY-MM-DD')
    }

    axios({
        method: 'POST',
        url: service.baseAPIURL + 'events/DeleteEvent',
        headers: {
            'Authorization': 'Bearer ' + accessToken
        },
        data: eventData
      }).then(response => {
            
        })
        .catch(error => {
          console.log(error);
        });

    
} 
let accessToken, userId

class Event extends React.Component {
    constructor() {
        super(  );
        this.state = {
            RRULE:'',
            careLevels: [],
            startDate: '',
            endDate: '',
          eventsList: [],
          selectedCareLevel: '',
          showList: null,
          eventStartDate: '',
          eventEndDate: '',
          buttonDisabled: true,
          showSpinner: false,
          showDeleteAll : false
        }
        this.schedulerRef = React.createRef();
        this.Save = this.Save.bind(this);
    }

    componentDidMount = () => {
        userId = localStorage.getItem('userId')
        accessToken= localStorage.getItem('accessToken')
        let id = parseInt(userId)
        console.log(id)

        axios({
          method: 'GET',
          url: service.baseAPIURL + 'communities/getcarelevelsbyfilter?userId='+id ,
          headers: {
              'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
            let careLevelsData = response.data.result.careLevels,
                carelevelOptions = []
    
                careLevelsData.forEach((item, i) => {
                  if(!item.isActive){       
                    carelevelOptions.push({
                      value: item.id,
                      label: item.name
                    })
                  }
              });
             
              this.setState({
                careLevels: carelevelOptions
              })
          })
          .catch(error => {
            if(error.request.status == 401){
                refreshToken()
            }
            console.log(error); 
          });
    
      }

    Save = () => {
        let newState = Object.assign({}, this.state);
        newState.RRULE = Helper.generateRrule(this.schedulerRef.current.state.schedule);
        this.setState(newState)
    }
    handleCarelevels = selectedCarelevel => {
        const{ eventStartDate, eventEndDate} = this.state
        this.setState({ selectedCareLevel: selectedCarelevel });
        if(eventStartDate !== "" && eventEndDate !== ""){
            this.setState({
                buttonDisabled: false
            })
        }
    };
    ChangeDate = (value, propertyName) => {
        const{selectedCareLevel, eventStartDate, eventEndDate} = this.state
        let selectedDate =  moment(value).format("MM/DD/YYYY")
       
        this.setState({
          [propertyName]: selectedDate
        })
        if(propertyName === 'eventStartDate'){
            if(selectedCareLevel !== "" && eventEndDate !== ""){
                this.setState({
                    buttonDisabled: false
                })
            }
        }else if(propertyName === 'eventEndDate'){
            if(selectedCareLevel !== "" && eventStartDate !== ""){
                this.setState({
                    buttonDisabled: false
                })
            }
        }
    }
   
    getEventList = () => {
        this.setState({
            showSpinner: true
        })
        const {selectedCareLevel, eventStartDate, eventEndDate} = this.state
        if(selectedCareLevel !== '' && eventStartDate !== '' && eventEndDate !== ""){
            axios({
                method: 'GET',
                url: service.baseAPIURL + 'events/GetEvents?careLevelId='+selectedCareLevel.value+'&startDate='+ eventStartDate+'&endDate='+eventEndDate,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
              }).then(response => {
                  console.log(response);
                  let eventsData = response.data.result.events,
                      eventsListStatus = null,
                      eventsListArray = [];
                  eventsData.map(function (item) {
                      let eventName = item.name,
                          startTime = item.startTime,
                          endTime = item.endTime,
                          location = item.location,
                          id = item.id,
                          eventId = item.id;
                      eventsListArray.push({
                          "id": id,
                          "eventId": eventId,
                          "name": eventName,
                          "startTime": moment(startTime).format('LT'),
                          "endTime": moment(endTime).format('LT'),
                          "location": location,
                          "startDate": moment(item.startDate).format('L'),
                      })
                  })
                  this.setState({
                      eventsList: eventsListArray
                  })
                  if (eventsListArray.length > 0) {
                      eventsListStatus = true
                  }
                  else {
                      eventsListStatus = false
                  }

                  this.setState({
                      showList: eventsListStatus,
                      showSpinner: false
                  })
              })
                .catch(error => {
                    if(error.request !== undefined && error.request.status == 401){
                        refreshToken()
                    }
                    console.log(error); 
                  });
        }
    }

   
    render() {
        function actionButtons(cell, row){
            return(
                <div>
                <Link className="btn btn-sm btn-info" to = {'./updateevents?id=' + row.eventId + '&eventDate=' + row.startDate}><i className="fa fa-pen" aria-hidden="true" onClick></i></Link>
                <Button color="danger" size="sm" onClick={() => deleteEvent(row, 'Current')}><i className="fa fa-trash" aria-hidden="true"></i></Button>
            </div>
            )
        }
        const {eventsList, careLevels, showList, buttonDisabled, showSpinner} = this.state

        let inputStartDateProps = {
            className: 'form-control'
        }
        let inputEndDateProps = {
            className: 'form-control'
        }
        const paginationOptions = {
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
        }
        const columns = [{
            dataField: 'id',
            text: 'UserId',
            hidden: true
        },{
            dataField: 'eventId',
            text: 'eventId',
            hidden: true
        }, {
            dataField: 'name',
            text: 'Event Name',
            sort: true,
          
        }, {
            dataField: 'startDate',
              text: 'Event Date',
              sort: true
        }, {
          dataField: 'startTime',
          text: 'Event Start Time',
          sort: true
      },
    {
        dataField: 'endTime',
          text: 'Event End Time',
          sort: true
    },{
        dataField: 'location',
          text: 'Event Location',
          sort: true
    },{
        dataField: '',
          text: 'action',
          sort: true,
          formatter: actionButtons
    }]
        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-4">
                        <Row className="text-muted">
                            <Col md="6" lg="6">
                                <h3>Event Manager</h3>
                            </Col>
                            <Col md="6" lg="6" className="text-right">
                            <Link to="./addevents" className="btn btn-outline-default btn-sm"><i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Add New </Link>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-2">
                        <Row className="mb-3 align-items-end">
                        <Col lg="4" md="4">
                        <div className='d-flex align-items flex-column'>
                                <Label>Care Level</Label>
                                <Select
                                options={careLevels}
                                onChange={this.handleCarelevels}
                               />
                            </div>
                        </Col>
                            <Col lg="3" md="3">
                            <div className='d-flex align-items flex-column'>
                                <Label>Start Date</Label>
                                <Datetime  timeFormat={false} inputProps={inputStartDateProps} onChange={(e) => this.ChangeDate(e, 'eventStartDate')} closeOnSelect={true}  />
                            </div>
                            </Col>
                           
                            <Col lg="3" md="3">
                            <div className='d-flex align-items flex-column'>
                                <Label>End Date</Label>
                                <Datetime  timeFormat={false} inputProps={inputEndDateProps} onChange={(e) => this.ChangeDate(e, 'eventEndDate')} closeOnSelect={true}  />
                            </div>
                            </Col>
                            <Col lg="2" md="2">
                                <Button color="primary" size="sm" disabled={buttonDisabled} onClick={()=> this.getEventList()}>Get Events</Button>
                            </Col>
                        </Row>
                      <Row className="pb-5">
                          <Col lg="12" md="12" >
                              {showSpinner ? <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" /> : ''}
                            {/* <Scheduler ref={this.schedulerRef} />
                            {this.state.RRULE} */}
                            {showList === true ? 
                             <BootstrapTable classes="custom-table-css" striped hover condensed
                            keyField='id'
                            data={eventsList}
                            columns={columns}  
                            pagination={ paginationFactory(paginationOptions) } /> : showList === false ? 'No Events' : ''}
                           

                          </Col>
                      </Row>

                    </CardBody>
                    
                </Card>
            </React.Fragment>
        )
    }
}

export default Event;