import React from 'react';
import {Link} from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    CardFooter,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Alert
} from "reactstrap";
import Scheduler from '../../components/events/scheduler';
import Helper from '../../helpers';
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment from 'moment';
import service from '../../services';
import axios from "axios";
import refreshToken from '../../refresh';
import defaultPic from '../../assets/img/theme/defaultProfile.jpg';

let accessToken, userId

class AddEvent extends React.Component {
    constructor() {
        super(  );
        this.state = {
            careLevels: [],
            eventStatus: true,
            eventName: '',
            eventDesc: '',
            eventStartDate: '',
            eventStartTime: '',
            eventEndTime: '',
            eventLocation: '',
            selectedCareLevel: [],
            eventNameError: false,
            eventDescError: false,
            eventStartDateError: false,
            eventStartTimeError: false,
            eventEndTimeError: false,
            careLevelError: false,
            formCompleted: false,
            eventLocationError: false,
            showAlert: false,
            showAlertType: null,
            profilePicError: false,
            profilePic: defaultPic,
        }
        this.schedulerRef = React.createRef();
        this.Save = this.Save.bind(this);
    }

    componentDidMount = () => {
        let pathURL = window.location.href;
        let getId = pathURL.split('?'),
            URLVal = getId[1].split('&'),
            eventDate = URLVal[0].split('=')[1],
            careLevelId = parseInt(URLVal[1].split('=')[1]);
        let selectedCareLevel = null;
        accessToken =  localStorage.accessToken
        userId = parseInt(localStorage.userId)
        axios({
          method: 'GET',
          url: service.baseAPIURL + 'communities/getcarelevelsbyfilter?userId='+userId ,
          headers: {
              'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
            let newState = Object.assign({}, this.state);
            let careLevelsData = response.data.result.careLevels,
                carelevelOptions = []
            careLevelsData.forEach((item, i) => {
                if (!item.isActive) {
                        if (item.id === careLevelId) {
                            selectedCareLevel = {
                                value: item.id,
                                label: item.name
                            }
                        }
                    carelevelOptions.push({
                      value: item.id,
                      label: item.name
                    })
                  }
                });
            newState.careLevels = carelevelOptions;
            this.setState(newState);
            this.ChangeDate(eventDate, 'eventStartDate', 'eventStartDateError');
            this.handleCarelevels([selectedCareLevel]);
          })
          .catch(error => {
            if(error.request.status == 401){
                refreshToken()
            }
            console.log(error);
          });
    
      }

    Save = () => {
        const {selectedCareLevel, eventName, eventDesc, eventStartDate, eventStartTime, eventEndTime, formCompleted, eventLocation, profilePicName, profilePicFileType} = this.state
     
       let RRULEs = Helper.generateRrule(this.schedulerRef.current.state.schedule),
           schedulerRule = this.schedulerRef.current.state.schedule,
           endDate = null;
       
        if(eventName === ''){
            this.setState({
                eventNameError: true
            })
        }
        if(eventDesc === ''){
            this.setState({
                eventDescError: true
            })
        }
        if(eventStartDate === ''){
            this.setState({
                eventStartDateError: true
            })
        }
        if(eventStartTime === ''){
            this.setState({
                eventStartTimeError: true
            })
        }
        if(eventEndTime === ''){
            this.setState({
                eventEndTimeError: true
            })
        }
        if(eventLocation === ''){
            this.setState({
                eventLocationError: true
            })
        }
        if(selectedCareLevel.length === 0){
            this.setState({
                careLevelError: true
            })
        }
        if(formCompleted){
            let selectedCareLevels = []
            if (schedulerRule.recurrenceRange.selectedOption === '2') {
                endDate = schedulerRule.recurrenceRange.endDate
            }
           
            selectedCareLevel.map(data => {
                selectedCareLevels.push({
                    "CareLevelId": data.value
                })
            })
            let formData = { 
                "Name":eventName, 
                "Description":eventDesc, 
                "StartDate": eventStartDate, 
                "IsActive":true, 
                "Location": eventLocation, 
                "StartTime":eventStartTime, 
                "EndDate": endDate,
                "EndTime":eventEndTime, 
                "EventCareLevels": selectedCareLevels, 
                "Rrule":  RRULEs,
                "FileUtility":{
                    "FileData": profilePicName,
                    "FileExtension": profilePicFileType
                  },
            }
            axios({
                method: 'POST',
                url: service.baseAPIURL + 'events/addevent',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                },
                data: formData
              }).then(response => {
                if(response.data.result != null || response.data.result.isSuccess){
                  this.setState({
                    showAlert: true,
                    showAlertType : 'success'
                  })
                    window.location.href = '/#/admin/calendar?date=' + eventStartDate + '&careLevelId=' + selectedCareLevels[0].CareLevelId;
                  }
                else if(response.data.errors != null){
                  this.setState({
                    showAlert: true,
                    showAlertType : 'danger'
                  })
                }
                window.scrollTo(0, 0)
              })
              .catch(error => {
                if(error.request.status == 401){
                    refreshToken()
                }
                console.log(error); 
              });
        }

       
    }

    ChangeInput = (text, propertyName, propertyNameError) => {
        this.setState({
          [propertyName]: text,
          formCompleted: true
        })
        if(text !== ''){
          this.setState({
            [propertyNameError] : false
          })
        }
      }

      ChangeDate = (value, propertyName, propertyNameError) => {
          let selectedDate =  moment(value).format("YYYY-MM-DD")
          this.setState({
            [propertyName]: selectedDate,
            formCompleted: true,
            [propertyNameError]: false
          })
      }
      ChangeTime = (value, propertyName, propertyNameError) => {
        let selectedTime =  moment(value).format()
        this.setState({
          [propertyName]: selectedTime,
          formCompleted: true,
          [propertyNameError]: false
        })
    }
    handleCarelevels = selectedCarelevel => {
        console.log(selectedCarelevel)
        this.setState({ selectedCareLevel: selectedCarelevel, completeForm:true, careLevelError: false });
      };
      toggleStatus = (e) => {
        this.setState({
            eventStatus: e.target.checked
        })
      }

      changeProfilePic = (e) => {
        e.preventDefault();
          let file = e.target.files[0];
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            let fileName = file.name.split('.'),
                fileData = reader.result.split('base64,');
            this.setState({
              profilePicFileType: '.'+fileName[1],
              profilePic: reader.result,
              profilePicName : fileData[1],
              profilePicError: false,
              completeForm: true,
              selectedProfilePic: true
            });
       }
      }
      changeProfilePic = (e) => {
        e.preventDefault();
          let file = e.target.files[0];
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            let fileName = file.name.split('.'),
                fileData = reader.result.split('base64,');
            this.setState({
              profilePicFileType: '.'+fileName[1],
              profilePic: reader.result,
              profilePicName : fileData[1],
              profilePicError: false,
              completeForm: true,
              selectedProfilePic: true
            });
       }
      }
    render() {
       const {eventLocationError, careLevels, eventStatus,  eventNameError, eventStartTimeError, eventDescError, eventStartDateError, eventEndTimeError, careLevelError,showAlertType, showAlert, profilePicError} = this.state

         let startDateAssigned = 'form-control', startTimeAssigned = 'form-control', endTimeAssigned = 'form-control'
        if(eventStartDateError){
            startDateAssigned = 'is-invalid form-control'
        }
       
        if(eventStartTimeError){
            startTimeAssigned = 'is-invalid form-control'
        } 
        if(eventEndTimeError){
            endTimeAssigned = 'is-invalid form-control'
        }
        let inputStartDateProps = {
            className: startDateAssigned
        }
        let inputStartTimeProps = {
            className: startTimeAssigned
        }
        let inputEndTimeProps = {
            className: endTimeAssigned
        }
        let yesterday = moment().subtract( 1, 'day' ),
            valid = function( current ){
            return current.isAfter( yesterday );
        };
        const customStyles = {
            menu: (provided) => ({
              ...provided,
             zIndex: 9999
            })
        }
        let alertMessage, alertStatus
      if(this.state.showAlertType === 'success'){
        alertMessage =  'event added'
        alertStatus ="Success"
      }
      else{
        alertMessage = 'event couldn\'t be added'
        alertStatus ="Error"
      }
      const profilePicStyle = {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat:'no-Repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url('+ this.state.profilePic +')',
        borderRadius: '5px',
        backgroundColor: '#ebebeb'
      }
      const profilePicStyleError = {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat:'no-Repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url('+ this.state.profilePic +')',
        borderRadius: '5px',
        backgroundColor: '#ebebeb',
        borderWidth: '1px',
        borderColor: '#fb6340',
        borderStyle:'solid'
    }
        return (
            <React.Fragment>
                <Alert color={showAlertType} isOpen={showAlert} toggle={(e) => this.setState({showAlert: false})}>
                    <strong>{alertStatus}! </strong>  {alertMessage}
                </Alert>
                            <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-4">
                        <Row className="text-muted">
                            <Col md="6" lg="6">
                                <h3>Event Manager</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-2">
                     
                        <Row>
                            <Col lg="12" md="12">
                                <Form>
                                    <Row>
                                        <Col lg="3">
                                        <div className="position-relative profilePic">
                        <Input onChange={(e) => this.changeProfilePic(e)}  type="file" inputprops={{ accept: 'image/*' }} style={{position: 'absolute', width:'100%', height: '100%', opacity:0}}/>
                        <div style={profilePicError ? profilePicStyleError : profilePicStyle} ></div>
                        {profilePicError ? <div className="error-msg">Upload a profile pic</div>: ''}
                      </div>
                                        </Col>
                                        <Col lg="9" md="9" sm="6">
                                            <Row>
                                        <Col lg="4" md="4">
                                            <FormGroup className={eventNameError ? 'has-danger mb-3' : 'mb-3'} >
                                                <Label>Event Name</Label>
                                                <Input invalid={eventNameError}  onChange={(e) => this.ChangeInput(e.target.value, 'eventName', 'eventNameError')} />
                                                <FormFeedback>Enter a event name</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4" md="4">
                                            <FormGroup className={eventDescError ? 'has-danger mb-3' : 'mb-3'}>
                                                <Label>Description</Label>
                                                <Input type="textarea" name="text"  invalid={eventDescError} onChange={(e) => this.ChangeInput(e.target.value, 'eventDesc', 'eventDescError')}  />
                                                <FormFeedback>Enter a description for the event</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4" md="4">
                                            <FormGroup className={eventLocationError ? 'has-danger mb-3' : 'mb-3'} >
                                                <Label>Event Location</Label>
                                                <Input invalid={eventLocationError}  onChange={(e) => this.ChangeInput(e.target.value, 'eventLocation', 'eventLocationError')} />
                                                <FormFeedback>Enter a location of event</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4" md="4" className="mb-3">
                                        <div className={eventStartDateError ? 'd-flex align-items flex-column has-danger' : 'd-flex align-items flex-column'}>
                                                <Label>Start Date</Label>
                                                <Datetime value={this.state.eventStartDate} isValidDate={valid} timeFormat={false} inputProps={inputStartDateProps} onChange={(e) => this.ChangeDate(e, 'eventStartDate', 'eventStartDateError')} closeOnSelect={true} />
                                            {eventStartDateError ? <div className="error-msg">Enter a start date</div>: ''}
                                        </div>
                                           
                                        </Col>
                                        
                                        <Col lg="4" md="4" className="mb-3">
                                        <div className={eventStartTimeError ? 'd-flex align-items flex-column has-danger' : 'd-flex align-items flex-column'}>
                                            <Label>Start Time</Label>
                                            <Datetime dateFormat={false} inputProps={inputStartTimeProps} onChange={(e) => this.ChangeTime(e, 'eventStartTime', 'eventStartTimeError')} closeOnSelect={true} />
                                            {eventStartTimeError ? <div className="error-msg">Enter a start time</div>: ''}
                                        </div>                                        
                                        </Col>
                                        <Col lg="4" md="4" className="mb-3">
                                        <div className={eventEndTimeError ? 'd-flex align-items flex-column has-danger' : 'd-flex align-items flex-column'}>
                                            <Label>End Time</Label>
                                            <Datetime dateFormat={false} inputProps={inputEndTimeProps} onChange={(e) => this.ChangeTime(e, 'eventEndTime', 'eventEndTimeError')} closeOnSelect={true}/>
                                            {eventEndTimeError ? <div className="error-msg">Enter a end time</div>: ''}
                                        </div>                                        
                                        </Col>
                                        </Row>
                                        </Col>
                                        <Col lg="3" md="3">
                                            <FormGroup className={careLevelError ? 'has-danger mb-3' : 'mb-3'}>
                                            <Label>CareLevels </Label>
                                            <Select
                                            closeMenuOnSelect={false}
                                                    styles={customStyles}
                                                    value={this.state.selectedCareLevel}
                                            options={careLevels}
                                            isMulti={true}
                                            onChange={this.handleCarelevels}
                                            className={careLevelError ? 'is-invalid': ''} 
                                            />
                                             {careLevelError ? <div className="error-msg">Select atleast one </div>: ''}
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" sm="12" lg="3" className="mb-3">    
                                            <div className="d-flex align-items-center justify-content-between h-100">
                                                <div className="mb-1 mr-3">Active</div>
                                                <label className="custom-toggle">
                                                <input defaultChecked={eventStatus} type="checkbox" onClick = {(e) => this.toggleStatus(e)} />
                                                <span className="custom-toggle-slider rounded-circle" />
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 border-top pt-3">
                          <Col lg="12" md="12">
                              <Row>
                                  <Col lg="2" md="2">
                                    <p>Schedule</p>
                                  </Col>
                                  <Col lg="10" md="10">
                                    <Scheduler ref={this.schedulerRef} rrule="" />
                                  </Col>
                              </Row>
                          </Col>
                      </Row>
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                    <Link className="btn btn-secondary btn-sm" to = './events'>Cancel</Link>
                        <Button color="primary" size="sm"  onClick={this.Save}>Save</Button>
                    </CardFooter>
                </Card>
            </React.Fragment>
        )
    }
}

export default AddEvent;