import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Alert,
  Row,
  Col,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Nav,
  NavItem,
  FormGroup,
  Label,
  InputGroup,
  Input,
  NavLink,
  CardFooter
} from "reactstrap";
import service from '../../services';
import axios from "axios";
import classnames from 'classnames';
import FormField from './form-field';
import _ from 'lodash'
import moment from 'moment';
import { FieldTypes } from './form-utility'
import InputGroupAddon from '../../../node_modules/reactstrap/lib/InputGroupAddon';

let accessToken;
let userId;

const ResidentForm = (props) => {

  const [activeTab, setActiveTab] = useState(0);
  const [formDetail, setFormDetail] = useState({});
  const [residentDetail, setResidentDetail] = useState();
  const [residentFormDetail, setResidentFormDetail] = useState();
  const [reminder, setReminder] = useState({ enable: false, type: {} })
  const [responseAlert, setResponseAlert] = useState({ showAlert: false, alertType: 'success', alertMessage: '' });
  useEffect(() => {
    userId = parseInt(localStorage.userId);
    accessToken = localStorage.accessToken;
    if (props.match.params) {
      let residentId = props.match.params.rid;
      let formId = props.match.params.fid;
      console.log(props.match.params);
      getFormDetailAndSetModel(formId, residentId);
    }

  }, [])

  const warningBorder = {
    border: '1px solid #fbc140',
    borderRadius: '10px',
    padding: '10px',
    backgroundColor: 'orange',
    color: 'black'
  }

  const errorBorder = {
    border: '1px solid red',
    borderRadius: '10px',
    padding: '10px',
    backgroundColor: 'red',
    color: 'black'
  }

  const tableBorderStyle = {
    //border: '1px solid black',
    borderCollapse: 'collapse'
  }

  const getFormDetailAndSetModel = (formId, residentId) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + `forms/GetFormDetail/?formId=${formId}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let formData = response.data.result.formDetail
      if (formData !== null) {
        axios({
          method: 'GET',
          url: service.baseAPIURL + `forms/GetResidentFieldDetail/?residentId=${residentId}&&formId=${formId}`,
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          let residentFieldData = response.data.result.residentFieldData;
          if (residentFieldData !== null) {
            formData.tabs.forEach(t => {
              t.fields.forEach(f => {
                let currFieldData = residentFieldData.filter(rf => rf.fieldId === f.id)[0];
                f.actualValue = "['']";
                if (currFieldData) {
                  f.actualValue = currFieldData.value;
                } else if (!currFieldData && isMultiValueField(f.type)) {
                  f.actualValue = f.value;
                }
              })
            })
            setFormDetail(formData);
            setActiveTab(formData.tabs[0].id);
            console.log(formData);
          }
        }).catch(error => { console.log(error); });

        getResidentDetailAndSetModel(residentId, formId, formData.dueDuration, formData.reminderDuration, formData.createdDate);
      }
    }).catch(error => { console.log(error); });
  }

  const getResidentDetailAndSetModel = (residentId, formId, dueDuration, reminderDuration, formCreationDate) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + `residents/GetResident/?id=${residentId}&&userId=${userId}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let residentData = response.data.result.resident;
      if (residentData) {
        setResidentDetail(residentData);
        getResidentFormDetail(residentId, formId, dueDuration, reminderDuration, residentData.dateOfJoining, formCreationDate)
      }
    }).catch(error => { console.log(error); });
  }

  const getResidentFormDetail = (residentId, formId, dueDuration, reminderDuration, residentJoiningDate, formCreationDate) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + `forms/GetResidentFormDetail/?residentId=${residentId}&&formId=${formId}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let residentFormData = response.data.result.residentForm;
      if (residentFormData) {

        //check Due Date, if its new form then set due date for first time 
        if (residentFormData.dueDate === '0001-01-01T00:00:00') {
          //new form, set for first time
          formCreationDate = new Date(formCreationDate);
          residentJoiningDate = new Date(residentJoiningDate);

          let baseDate = residentJoiningDate;
          while (baseDate < formCreationDate) {
            baseDate.setDate(baseDate.getDate() + dueDuration);
          }

          baseDate.setDate(baseDate.getDate() + dueDuration);
          residentFormData.dueDate = baseDate.toJSON();
        }

        if (residentFormData.completionDate === '0001-01-01T00:00:00') {
          residentFormData.completionDate = '';
        }
        residentFormData.dueDate = residentFormData.dueDate.split('T')[0]
        residentFormData.completionDate = residentFormData.completionDate.split('T')[0]

        setResidentFormDetail(residentFormData);
        setReminderData(new Date(residentFormData.dueDate), reminderDuration);
      }
    }).catch(error => { console.log(error); });
  }

  const isMultiValueField = (fieldType) => {
    return fieldType === FieldTypes.radio
      || fieldType === FieldTypes.checkbox
      || fieldType === FieldTypes.dropdown
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const getTabLinks = () => {
    let tabLinks = [];
    if (formDetail && formDetail.tabs) {
      formDetail.tabs.forEach(t => {
        tabLinks.push(
          <NavItem key={t.id}>
            <NavLink key={t.id} className={classnames({ active: activeTab === t.id })}
              onClick={() => { toggle(t.id); }}>
              {t.name}
            </NavLink>
          </NavItem>
        )
      })
    }
    return tabLinks;
  }

  const getTabPanes = () => {
    let tabPanes = [];
    if (formDetail && formDetail.tabs) {
      formDetail.tabs.forEach(t => {
        tabPanes.push(
          <TabPane tabId={t.id}>
            {getFieldsByTabId(t.id)}
          </TabPane>
        )
      })
    }
    return tabPanes;
  }

  const getFieldsByTabId = (tabId) => {
    let tab = formDetail.tabs.filter(x => x.id === tabId)[0];
    if (tab.fields) {
      tab.fields.sort((a, b) => (a.row > b.row) ? 1 : ((b.row > a.row) ? -1 : 0))
      return getTabContent(tab.fields, tabId);
    }
  }

  const updateFieldActualValue = (newValue, fieldId, tabId) => {
    let formDet = _.cloneDeep(formDetail);
    let currTab = formDet.tabs.filter(t => t.id === tabId)[0];
    currTab.fields = currTab.fields.map(f => f.id === fieldId ? { ...f, actualValue: newValue } : f);
    setFormDetail(formDet);
  }

  const getTabContent = (fields, tabId) => {
    if (fields && fields.length > 0) {
      const firstRow = fields[0].row;
      const lastRow = fields[fields.length - 1].row;
      let contents = [];
      for (let rowIndex = firstRow; rowIndex <= lastRow; rowIndex++) {
        let currRowFields = fields.filter(x => x.row === rowIndex)
          .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

        if (currRowFields && currRowFields.length > 0) {
          let mdLen = currRowFields.length == 1 ? 6 : 12 / currRowFields.length;
          contents.push(
            <Row className='mt-3'>
              {
                currRowFields.map(f =>
                  <Col md={mdLen}>
                    <FormField key={f.id} tabId={tabId} field={f}
                      updateFieldActualValue={(newValue, fieldId, tabId) => updateFieldActualValue(newValue, fieldId, tabId)} />
                  </Col>)
              }
            </Row>)
        }
      }
      return contents;
    }
  }

  const setReminderData = (dueDate, reminderDuration) => {
    // reminder date = dueDate - reminderDuration
    let reminderDate = new Date(dueDate.toJSON());
    let currDate = new Date();
    reminderDate.setDate(dueDate.getDate() - reminderDuration);
    if (currDate >= reminderDate && currDate <= dueDate) {
      //show warning
      setReminder({ ...reminder, enable: true, type: warningBorder });
    } else if (currDate > dueDate) {
      //show error
      setReminder({ ...reminder, enable: true, type: errorBorder });
    } else {
      setReminder({ ...reminder, enable: false, type: {} });
    }
  }

  const upsertResidentFieldData = () => {
    let residentId = parseInt(props.match.params.rid);
    let formId = parseInt(props.match.params.fid);
    let residentFormData = [];
    formDetail.tabs.forEach(t => {
      t.fields.forEach(f => {
        residentFormData.push({ residentId: residentId, formId: formId, fieldId: f.id, value: f.actualValue });
      })
    });

    axios({
      method: 'POST',
      url: service.baseAPIURL + `forms/UpsertResidentFieldData`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      },
      data: residentFormData
    }).then(response => {
      if (response.data.result != null && !response.data.result.message) {
        setResponseAlert({ showAlert: true, alertType: 'success', alertMessage: "Form created successfully" });
      } else if (response.data.result.message) {
        setResponseAlert({ showAlert: true, alertType: 'failure', alertMessage: response.data.result.message });
      }
    }).catch(error => { console.log(error); });

    console.log(residentFormData);
  }

  const upsertResidentFormDetail = () => {

    //validation
    if (!residentFormDetail.completionDate || residentFormDetail.completionDate === '0001-01-01') {
      setResponseAlert({ showAlert: true, alertType: 'warning', alertMessage: "Completion date is mandatory !!!" });
      return;
    }

    let resFormDet = _.cloneDeep(residentFormDetail);

    let duedate = new Date(residentFormDetail.dueDate);
    let completionDate = new Date(residentFormDetail.completionDate);
    let reminderDate = new Date(duedate.toJSON());
    reminderDate.setDate(duedate.getDate() - formDetail.reminderDuration);

    //set new dueDate and save in database
    if (completionDate >= reminderDate) {
      duedate.setDate(duedate.getDate() + formDetail.dueDuration);
      let newDueDate = duedate.toJSON().split('T')[0];
      resFormDet.dueDate = newDueDate;
    }

    //setting createBy and updatedBy
    resFormDet.createdBy = parseInt(localStorage.userId)
    resFormDet.updatedBy = parseInt(localStorage.userId)

    axios({
      method: 'POST',
      url: service.baseAPIURL + `forms/UpsertResidentFormDetail`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      },
      data: resFormDet
    }).then(response => {
      if (response.data.result.message) {
        setResponseAlert({ showAlert: true, alertType: 'error', alertMessage: response.data.result.message });
      } else {
        //success
        setResponseAlert({ showAlert: true, alertType: 'success', alertMessage: "Completion date updated successfully" });
        setResidentFormDetail(resFormDet);
        setReminderData(new Date(resFormDet.dueDate), formDetail.reminderDuration);
      }
    }).catch(error => { console.log(error); });
  }

  const getRowGroupedFields = (fields) => {
    fields = fields.sort((a, b) => (a.row > b.row) ? 1 : ((b.row > a.row) ? -1 : 0));
    var grouped = _.mapValues(_.groupBy(fields, 'row'),
      clist => clist.map(d => _.omit(d, 'row')));
    return grouped;
  }

  // const exportToExcel = () => {
  //   var downloadurl;
  //   var dataFileType = 'application/vnd.ms-excel';
  //   let tableSelect = document.getElementById("tblexportData");
  //   var tableHTMLData = tableSelect.outerHTML.replace(/ /g, '%20');

  //   let filename = `resident-form.xls`;
  //   downloadurl = document.createElement("a");
  //   document.body.appendChild(downloadurl);
  //   if (navigator.msSaveOrOpenBlob) {
  //     var blob = new Blob(['\ufeff', tableHTMLData], {
  //       type: dataFileType
  //     });
  //     navigator.msSaveOrOpenBlob(blob, filename);
  //   } else {
  //     // Create a link to the file
  //     downloadurl.href = 'data:' + dataFileType + ', ' + tableHTMLData;
  //     downloadurl.download = filename;
  //     downloadurl.click();
  //   }
  // }

  const exportToExcel = () => {
    let uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let table = document.getElementById("tblexportData");
    let filename = `resident-form.xls`;
    var ctx = { worksheet: 'Resident' || 'Worksheet', table: table.innerHTML }

    let downloadurl = document.createElement("a");
    document.body.appendChild(downloadurl);
    downloadurl.href = uri + base64(format(template, ctx));
    downloadurl.download = filename;
    downloadurl.click();
  }

  return (
    Object.keys(formDetail).length > 0 &&
    < React.Fragment >
      <Alert color={responseAlert.alertType} isOpen={responseAlert.showAlert} toggle={() => setResponseAlert({ ...responseAlert, showAlert: false })}>
        <strong>{responseAlert.alertType.toUpperCase()}! </strong>  {responseAlert.alertMessage}
      </Alert>
      <Card className="bg-secondary shadow border-0">
        {residentDetail &&
          <CardHeader className="bg-transparent py-4">
            <Row className="text-muted">
              <Col md="6" lg="6">
                <Label>Form name</Label>
                <h1>{formDetail.name}</h1>
              </Col>
            </Row>
            <Row className="text-muted">
              <Col md="8" lg="8">
                <Label>Resident Detail</Label>
                <h2>
                  <span id="residentname"><i class="fas fa-user-tie"></i>{' '}{residentDetail.firstName + ' ' + residentDetail.lastName + ' | '}</span>
                  <span id="dateofbirth"><i class="fas fa-birthday-cake"></i>{' '} {new Date(residentDetail.dateOfBirth).toISOString().split('T')[0] + ' | '}</span>
                  <span id="moveindate"><i class="far fa-calendar-alt"></i>{' '} {new Date(residentDetail.dateOfJoining).toISOString().split('T')[0]}</span>
                </h2>
              </Col>
            </Row>
            <UncontrolledTooltip delay={0} placement="top" target="residentname"> Name </UncontrolledTooltip>
            <UncontrolledTooltip delay={0} placement="top" target="dateofbirth"> Date of birth </UncontrolledTooltip>
            <UncontrolledTooltip delay={0} placement="top" target="moveindate"> Move-in date </UncontrolledTooltip>
          </CardHeader>
        }
        <CardBody>
          <Button aria-label="Export" className="close shadow-none" onClick={exportToExcel}>
            <span>
              <i class="far fa-file-excel fa-lg"></i>
            </span>
          </Button>
          <Nav tabs>
            {getTabLinks()}
          </Nav>
          <TabContent activeTab={activeTab}>
            {getTabPanes()}
          </TabContent>
        </CardBody>
        <CardFooter>
          <Row>
            {residentFormDetail && reminder &&
              <Col sm="6">
                <Row style={reminder.type}>
                  <Col sm="6">
                    <FormGroup>
                      <Label>Due Date</Label>
                      <InputGroup>
                        <Input type="date" value={residentFormDetail.dueDate} disabled
                          placeholder="Due Date"
                          onChange={(ev) => setResidentFormDetail({ ...residentFormDetail, dueDate: ev.target.value })} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label>Completion Date</Label>
                      <InputGroup>
                        <Input type="date" value={residentFormDetail.completionDate}
                          placeholder="Completion Date" max={new Date().toISOString().split('T')[0]}
                          onChange={(ev) => setResidentFormDetail({ ...residentFormDetail, completionDate: ev.target.value })} />
                        <InputGroupAddon addonType="append">
                          <Button color="info" onClick={upsertResidentFormDetail}>Update</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            }
            <Col sm='3'></Col>
            <Col sm="3" style={{ alignSelf: 'center' }}>
              <Button style={{ float: 'right' }} color="primary" onClick={upsertResidentFieldData}>Save</Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
      <table id="tblexportData" style={tableBorderStyle} className="table d-none">
        <tr>
          <td colspan="5" style={tableBorderStyle}><u><b>Form Name = {formDetail.name}</b></u></td>
        </tr>
        <tr></tr>
        {formDetail.tabs.map(t =>
          <>
            <tr>
              <td colspan="4" style={tableBorderStyle}><u><b>{t.name}</b></u></td>
            </tr>
            {Object.keys(getRowGroupedFields(t.fields)).map(row =>
              <tr>
                {getRowGroupedFields(t.fields)[row].map(f =>
                  <td colspan="2" style={tableBorderStyle}>
                    {f.name + ' : '}
                  </td>
                )}
              </tr>
            )}
            <tr></tr>
          </>
        )}
      </table>
    </React.Fragment >
  )
}

export default ResidentForm