import React from 'react';
import {
    Input,
    Label,
    Row,
    Col,
    Button
} from "reactstrap";

export default class StoryDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.item.id,
            residentId: this.props.residentId,
            storyId: this.props.item.storyId,
            storyValue: this.props.item.storyValue !== undefined ? this.props.item.storyValue : '',
            name: this.props.item.name,
            description: this.props.item.description
        }
    }
    changeVal = (text) => {
        if(text !== ""){
            this.setState({
                storyValue: text
            })
        }
    }
    
    submitVal = () => {
        
        if(this.state.storyValue !== ""){
            this.props.addValues(this.state)
        }
    }


    render() {
        return (<>

                <Col md="6" sm="12" lg="6" className="mb-3">
                    <Label> {this.props.item.name}</Label>
                    <Input type="textarea" value={this.state.storyValue} onChange={(e) => this.changeVal(e.target.value)}  onBlur={this.submitVal}  />
                </Col>
            
        </>)
    }
}