import React, { Component } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    FormGroup,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    CardFooter,
    Table
} from "reactstrap";
import { Link } from 'react-router-dom';
import service from '../../services';
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';

let accessToken;
class UsersList extends Component {
    constructor() {
        super();
        this.state = {
            userList: [],
            filteredUserList: []
        };
    }

    componentDidMount() {
        accessToken = localStorage.accessToken
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'Users/GetUsers',
            headers: {
                'Authorization': 'Bearer ' + accessToken
            },
        }).then(response => {
            this.setState({
                userList: response.data.result.users,
                filteredUserList: response.data.result.users
            })
        })
            .catch(error => {
                console.log(error);
            });


        //axios.get(baseURL + 'Users/GetUsers', {}, {
        //    headers: {
        //        'Accept': 'application/json',
        //        'Content-Type': 'application/json',
        //        'Authorization': 'Bearer ' + accessToken
        //    }
        //})
        //    .then(response => {
        //        this.setState({
        //            users: response.data.result.users
        //        })
        //    })
        //    .catch(error => {
        //        console.log(error);
        //    }); 
    }

    filterUsers = (event) => {
        let searchText = event.target.value;
        if (!searchText) {
            this.setState({ filteredUserList: [...this.state.userList] });
        } else {
            searchText = searchText.toLowerCase();
            const filteredUsers = this.state.userList
                .filter(user => user.firstName.toLowerCase().indexOf(searchText) >= 0
                    || user.lastName.toLowerCase().indexOf(searchText) >= 0
                    || user.email.toLowerCase().indexOf(searchText) >= 0);
            this.setState({ filteredUserList: [...filteredUsers] });
        }
    }

    render() {
        function emailFormatter(cell, row) {
            return (<Link to={'./updateuser?id=' + row.id}> {row.email}</Link>);
        };

        const columns = [{
            dataField: 'id',
            text: 'UserId',
            hidden: true
        }, {
            dataField: 'email',
            text: 'Email',
            sort: true,
            formatter: emailFormatter
        }, {
            dataField: 'firstName',
            text: 'First Name',
            sort: true
        }, {
            dataField: 'lastName',
            text: 'Last Name',
            sort: true
        }, {
            dataField: 'phoneNumber',
            text: 'Phone Number',
            sort: true
        }]

        const { filteredUserList } = this.state;
        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-4">
                        <Row className="text-muted">
                            <Col md="6" lg="6">
                                <Row>
                                    <Col lg="8" md="8">
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    className="form-control-alternative"
                                                    placeholder="Search Users"
                                                    type="text"
                                                    onChange={this.filterUsers}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" lg="6" className="text-right">
                                <Link className="btn btn-outline-default btn-sm" to='./adduser'><i className="fa fa-plus" aria-hidden="true"></i> &nbsp;Add</Link>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-2">
                        <BootstrapTable classes="custom-table-css" striped hover condensed
                            keyField='id'
                            data={filteredUserList}
                            columns={columns} />
                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
            </React.Fragment>
        );
    }
}

export default UsersList;