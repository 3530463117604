import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal, CardFooter,
  Label
} from "reactstrap";
import service from '../../services';
import axios from "axios";
import classnames from 'classnames';
import FormField from './form-field';
import _ from 'lodash'
import { FieldTypes, FieldTypeOptions, SpecialFieldTypes, SpecialFieldTypeOptions, DueDurationOptions } from './form-utility'
import Select from 'react-select';
import useInterval from 'react-useinterval';
import confirm from "reactstrap-confirm";

let accessToken;

const defaultField = {
  'id': undefined,
  'name': 'Field name',
  'type': 'textbox',
  'value': "['Swim', 'Music', 'Cricket', 'Badminton']",
  'row': 1,
  'order': 1
}

const defaultTab = {
  id: undefined,
  name: 'Tab 1',
  fields: [defaultField]
};
const defaultFormDetail = {
  name: '',
  tabs: [defaultTab],
  isPartial: false,
  dueDuration: 0,
  reminderDuration: 0
};

const defaultFieldModalContent = {
  label: '',
  fieldId: undefined,
  tabId: undefined,
  fieldType: 'textbox',
  showOptions: false,
  options: [{ id: 1, value: '' }],
  okDisabled: false,
  fieldTypeOptions: [],
  warningMessage: ''
}

const AddUpdateForm = (props) => {

  const [activeTab, setActiveTab] = useState(0);
  const [formDetail, setFormDetail] = useState({});
  const [previewFieldModal, setPreviewFieldModal] = useState(false);
  const [fieldModalContent, setFieldModalContent] = useState(defaultFieldModalContent);
  const [previewFormNameModal, setPreviewFormNameModal] = useState(false);
  const [tabEditableStatus, setTabEditableStatus] = useState({ editable: false, tabId: 0, tabName: '' });
  const [responseAlert, setResponseAlert] = useState({ showAlert: false, alertType: 'success', alertMessage: '' });
  const [tabIdsToDelete, setTabIdsToDelete] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {

    if (props.match.params) {
      let communityId = parseInt(props.match.params.cid);
      let formId = parseInt(props.match.params.fid);
      console.log(props.match.params);

      //if formId is there then update form is open else add form is open
      if (formId && formId > 0) {
        //update form scenario
        setIsUpdate(true);
        getFormDetailById(formId).then(response => {
          if (response.data.result.formDetail) {
            setFormDetail(getFormWithRowSortedFields(response.data.result.formDetail));
            if (response.data.result.formDetail.tabs) {
              setActiveTab(response.data.result.formDetail.tabs[0].id);
            }
          }
        });
      } else if (communityId && communityId > 0) {
        // add new form scenario
        setIsUpdate(false);
        const defaultTabId = getNextId();
        defaultFormDetail.tabs[0].id = defaultTabId;
        defaultFormDetail.tabs[0].fields[0].id = getNextId();
        setFormDetail({ ...defaultFormDetail, communityId: communityId });
        setActiveTab(defaultTabId);
      }
    }

    accessToken = localStorage.accessToken;
  }, [])

  const getFormWithRowSortedFields = (form) => {
    let computedForm = { ...form };
    if (computedForm.tabs) {
      computedForm.tabs.forEach(tab => {
        tab.persisted = true;
        if (tab.fields) {
          tab.fields.sort((a, b) => (a.row > b.row) ? 1 : ((b.row > a.row) ? -1 : 0));
          tab.fields = tab.fields.map(f => { return { ...f, persisted: true } });
        }
      });
    }
    return computedForm;
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const AddFieldOnRow = (tabId, row, order) => {
    let formDet = _.cloneDeep(formDetail);
    let currTab = formDet.tabs.filter(t => t.id === tabId)[0];
    currTab.fields.push({ ...defaultField, id: getNextId(), row: row, order: (order + 1) });
    setFormDetail(formDet);
  }

  const AddRow = (tabId) => {
    let formDet = _.cloneDeep(formDetail);
    let currTab = formDet.tabs.filter(t => t.id === tabId)[0];
    if (currTab.fields && currTab.fields.length > 0) {
      //sort by row
      currTab.fields.sort((a, b) => (a.row > b.row) ? 1 : ((b.row > a.row) ? -1 : 0));
      const lastField = currTab.fields[currTab.fields.length - 1];
      currTab.fields.push({ ...defaultField, id: getNextId(), row: (lastField.row + 1) })
    }
    else {
      currTab.fields.push(defaultField);
    }

    setFormDetail(formDet);
  }

  const addTab = () => {
    let formDet = _.cloneDeep(formDetail);
    defaultTab.fields[0].id = getNextId();
    if (formDet.tabs && formDet.tabs.length > 0) {
      const nextId = getNextId();
      formDet.tabs.push({ ...defaultTab, id: nextId, name: 'Tab name' });
    }
    else {
      formDet.tabs.push({ ...defaultTab });
    }
    setFormDetail(formDet);
  }

  const getNextId = () => {
    // if (array && array.length > 0) {
    //   array.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    //   return array[array.length - 1].id + 1;
    // } else {
    //   return 1;
    // }
    return Math.floor((Math.random() * 2147483000) + 100000);
  }

  const onTabNameDblClicked = (tab) => {
    setTabEditableStatus({ ...tabEditableStatus, editable: true, tabName: tab.name, tabId: tab.id });
  }

  const updateTabName = () => {
    let formDet = _.cloneDeep(formDetail);
    let currTab = formDet.tabs.filter(t => t.id === tabEditableStatus.tabId)[0];
    currTab.name = tabEditableStatus.tabName;
    setFormDetail(formDet);
    setTabEditableStatus({ ...tabEditableStatus, editable: false })
  }

  const getTabLinks = () => {
    let tabLinks = [];
    if (formDetail && formDetail.tabs) {
      formDetail.tabs.forEach(t => {
        tabLinks.push(
          <NavItem key={t.id}>
            {t.id === tabEditableStatus.tabId && tabEditableStatus.editable ?
              <Input type='text' value={tabEditableStatus.tabName}
                onChange={(ev) => setTabEditableStatus({ ...tabEditableStatus, tabName: ev.target.value })}
                onBlur={updateTabName} />
              :
              <NavLink key={t.id} className={classnames({ active: activeTab === t.id })}
                onClick={() => { toggle(t.id); }}
                onDoubleClick={() => onTabNameDblClicked(t)}>
                {t.name}
                <Button aria-label="Close" className="close ml-2 shadow-none" type="button" onClick={(ev) => removeTab(ev, t)}>
                  <span className="btn-inner--icon">
                    <i className="fas fa-times" aria-hidden="true"></i>
                  </span>
                </Button>
              </NavLink>
            }
          </NavItem>
        )
      })
    }
    return tabLinks;
  }

  const removeTab = async (ev, tab) => {
    ev.stopPropagation(); //to stop propagating event from executing toggle on current tab

    if (await confirm()) {
      if (formDetail.tabs && formDetail.tabs.length > 1) {
        let formDet = _.cloneDeep(formDetail);
        const currTabIndex = formDet.tabs.findIndex(t => t.id === tab.id);
        formDet.tabs = formDet.tabs.filter(t => t.id != tab.id);
        if (tab.persisted) {
          setTabIdsToDelete([...tabIdsToDelete, tab.id]);
        }
        setFormDetail(formDet);

        if (activeTab === tab.id) {
          setActiveTab(formDet.tabs[currTabIndex === 0 ? 0 : currTabIndex - 1].id);
        }
      } else {
        setResponseAlert({ ...responseAlert, showAlert: true, alertType: "warning", alertMessage: "Atleast one tab is required" })
      }
    }
  }

  const removeField = async (field, tabId) => {
    if (await confirm()) {
      let formDet = _.cloneDeep(formDetail);
      const currTab = formDet.tabs.filter(t => t.id === tabId)[0];
      if (currTab.fields && currTab.fields.length > 1) {
        currTab.fields = currTab.fields.filter(f => f.id != field.id);
        //reorder fields
        let count = 1;
        currTab.fields.filter(f => f.row === field.row).forEach(f => {
          f.order = count++;
        });

        setFormDetail(formDet);
      } else {
        setResponseAlert({ ...responseAlert, showAlert: true, alertType: "warning", alertMessage: "Atleast one field is required in a tab" })
      }
    }
  }

  const getTabPanes = () => {
    let tabPanes = [];

    if (formDetail && formDetail.tabs) {
      formDetail.tabs.forEach(t => {
        tabPanes.push(
          <TabPane tabId={t.id}>
            {getFieldsByTabId(t.id)}
          </TabPane>
        )
      })
    }
    return tabPanes;
    console.log(formDetail);
  }

  const getFieldsByTabId = (tabId) => {
    let tab = formDetail.tabs.filter(x => x.id === tabId)[0];
    if (tab.fields) {
      tab.fields.sort((a, b) => (a.row > b.row) ? 1 : ((b.row > a.row) ? -1 : 0))
      return getTabContent(tab.fields, tabId);
    }
  }

  const moveFieldRight = (field, tabId) => {
    let formDet = _.cloneDeep(formDetail);
    let currTab = formDet.tabs.filter(t => t.id === tabId)[0];
    let currRowFields = currTab.fields.filter(f => f.row === field.row);

    let fieldToSwap = currRowFields.filter(f => f.order === (field.order + 1))[0];
    let currField = currRowFields.filter(f => f.id === field.id)[0];
    const temp = fieldToSwap.order;
    fieldToSwap.order = currField.order;
    currField.order = temp;
    setFormDetail(formDet);
  }
  const moveFieldLeft = (field, tabId) => {
    let formDet = _.cloneDeep(formDetail);
    let currTab = formDet.tabs.filter(t => t.id === tabId)[0];
    let currRowFields = currTab.fields.filter(f => f.row === field.row);

    let fieldToSwap = currRowFields.filter(f => f.order === (field.order - 1))[0];
    let currField = currRowFields.filter(f => f.id === field.id)[0];
    const temp = fieldToSwap.order;
    fieldToSwap.order = currField.order;
    currField.order = temp;
    setFormDetail(formDet);
  }
  const isRightMoveDisabled = (field, tabId) => {
    let currTab = formDetail.tabs.filter(t => t.id === tabId)[0];
    let currRowFields = currTab.fields.filter(f => f.row === field.row)
      .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));;
    let maxOrder = currRowFields[currRowFields.length - 1].order;
    return field.order >= maxOrder;
  }
  const isLeftMoveDisabled = (field, tabId) => {
    let currTab = formDetail.tabs.filter(t => t.id === tabId)[0];
    let currRowFields = currTab.fields.filter(f => f.row === field.row)
      .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    let minOrder = currRowFields[0].order;
    return field.order <= minOrder;
  }

  const getTabContent = (fields, tabId) => {
    if (fields && fields.length > 0) {
      const firstRow = fields[0].row;
      const lastRow = fields[fields.length - 1].row;
      let contents = [];
      for (let rowIndex = firstRow; rowIndex <= lastRow; rowIndex++) {
        let currRowFields = fields.filter(x => x.row === rowIndex)
          .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

        if (currRowFields && currRowFields.length > 0) {
          let mdLen = currRowFields.length == 1 ? 6 : 12 / currRowFields.length;
          const lastOrder = currRowFields[currRowFields.length - 1].order;
          contents.push(
            <Row className='mt-3'>
              {
                currRowFields.map(f =>
                  <Col md={mdLen}>
                    <Button aria-label="Close" className="close ml-1 shadow-none" type="button" onClick={() => removeField(f, tabId)}>
                      <span className="btn-inner--icon">
                        <i className="fas fa-times" aria-hidden="true"></i>
                      </span>
                    </Button>
                    <Button aria-label="Open" className="close ml-1 shadow-none" onClick={() => openFieldEditModal(f, tabId)}>
                      <span className="btn-inner--icon">
                        <i className="fas fa-cog" aria-hidden="true"></i>
                      </span>
                    </Button>
                    <Button aria-label="Open" disabled={isRightMoveDisabled(f, tabId)} className="close ml-1 shadow-none" onClick={() => moveFieldRight(f, tabId)}>
                      <span className="btn-inner--icon">
                        <i class="fas fa-chevron-right" aria-hidden="true"></i>
                      </span>
                    </Button>
                    <Button aria-label="Open" disabled={isLeftMoveDisabled(f, tabId)} className="close ml-1 shadow-none" onClick={() => moveFieldLeft(f, tabId)}>
                      <span className="btn-inner--icon">
                        <i class="fas fa-chevron-left" aria-hidden="true"></i>
                      </span>
                    </Button>
                    <FormField key={f.id} tabId={tabId} field={f} />
                  </Col>)
              }
              {!(currRowFields.length === 1 && currRowFields[0].type === SpecialFieldTypes.statement)
                && currRowFields.length < 4 &&
                <Button style={{ position: 'absolute', right: '-30px' }} aria-label="Close" className="shadow-none" type="button" onClick={() => AddFieldOnRow(tabId, rowIndex, lastOrder)}>
                  <span className="btn-inner--icon">
                    <i className="fas fa-plus-circle fa-2x" aria-hidden="true"></i>
                  </span>
                </Button>
              }
            </Row>)
        }
      }
      //Add AddRow button in the end of last row
      contents.push(
        <Row className='mt-3'>
          <Button color="secondary" onClick={() => AddRow(tabId)}>Add Row</Button>
        </Row>);
      return contents;
    }
  }

  const openFieldEditModal = (field, tabId) => {
    let content = {
      ...defaultFieldModalContent,
      label: field.name,
      fieldId: field.id,
      tabId: tabId,
      fieldType: field.type,
      options: []
    };

    if (field.order === 1) {
      content.fieldTypeOptions = [...FieldTypeOptions, ...SpecialFieldTypeOptions];
    } else {
      content.fieldTypeOptions = [...FieldTypeOptions]
    }

    if (field.type === SpecialFieldTypes.statement) {
      content.warningMessage = "No more fields will be allowed on same row !!!";
    }

    if (isMultiValueField(field.type)) {
      let values = eval(field.value);
      if (values && values.length > 0) {
        values.forEach(val => {
          content.options.push({ id: getNextId(content.options), value: val })
        })
      }
      content.showOptions = true;
    }

    setFieldModalContent(content);
    toggleFieldModal();
  }

  const isMultiValueField = (fieldType) => {
    return fieldType === FieldTypes.radio
      || fieldType === FieldTypes.checkbox
      || fieldType === FieldTypes.dropdown
  }

  const toggleFieldModal = () => {
    setPreviewFieldModal(!previewFieldModal)
  };

  const toggleFormNameModal = () => {
    setPreviewFormNameModal(!previewFormNameModal)
  };

  const setFormName = (formName) => {
    let formDet = _.cloneDeep(formDetail);
    formDet.name = formName;
    setFormDetail(formDet);
  }

  const onFieldTypeSelection = (selectedType) => {
    let content = { ...fieldModalContent, fieldType: selectedType.value, warningMessage: '' };
    if (isMultiValueField(selectedType.label)) {
      content.showOptions = true;
    }
    else {
      content.showOptions = false;
    }

    if (selectedType.label === SpecialFieldTypes.statement) {
      content.warningMessage = "No more fields will be allowed on same row !!!";
    }

    setFieldModalContent(content);
  }

  const onFieldModalOptionValChange = (newVal, id) => {
    let content = { ...fieldModalContent };
    content.options.filter(x => x.id === id)[0].value = newVal;
    setFieldModalContent(content);
  }

  const onAddOptionClick = () => {
    let content = { ...fieldModalContent, warningMessage: '' };
    content.options.push({ id: getNextId(content.options), value: '' });
    setFieldModalContent(content);
  }

  const removeOption = (val) => {
    let content = _.cloneDeep(fieldModalContent);

    //check if atleast one option is there
    if (fieldModalContent.options.length === 1) {
      content.warningMessage = "Atleast one option is required !!!"
    } else {
      content.options = content.options.filter(o => o.id != val.id);
    }

    setFieldModalContent(content);
  }

  const updateFieldFromModal = () => {
    let formDet = _.cloneDeep(formDetail);
    let currTab = formDet.tabs.filter(t => t.id === fieldModalContent.tabId)[0];
    let currField = currTab.fields.filter(f => f.id === fieldModalContent.fieldId)[0];
    currField.name = fieldModalContent.label;
    currField.type = fieldModalContent.fieldType;

    if (isMultiValueField(currField.type)) {
      if (!fieldModalContent.options || fieldModalContent.options.length === 0) {
        setFieldModalContent({ ...fieldModalContent, warningMessage: "Atleast one option must be added !!!" });
        return;
      } else {
        currField.value = JSON.stringify(fieldModalContent.options.map(o => o.value));
      }
    } else {
      currField.value = null
    }

    //if field at order = 1 is of type statement, then remove other fields of same row
    if (currField.type === SpecialFieldTypes.statement && currField.order === 1) {
      let sameRowFields = currTab.fields.filter(f => f.row === currField.row && f.id != currField.id);
      if (sameRowFields && sameRowFields.length > 0) {
        const sameRowFieldIds = sameRowFields.map(f => f.id);
        currTab.fields = currTab.fields.filter(f => !sameRowFieldIds.includes(f.id));
      }
    }

    setFormDetail(formDet);
    toggleFieldModal();
  }

  const deleteTabs = () => {
    if (tabIdsToDelete && tabIdsToDelete.length > 0) {
      axios({
        method: 'DELETE',
        url: service.baseAPIURL + `forms/DeleteTab?tabIds=${tabIdsToDelete.join()}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      }).then(response => {
        if (response.data.result != null || response.data.result.message === '') {
          setResponseAlert({ showAlert: true, alertType: 'success', alertMessage: "Tab deleted successfully" });
          setTabIdsToDelete([]);
        }
        else if (response.data.result.message) {
          setResponseAlert({ showAlert: true, alertType: 'failure', alertMessage: "Some problem in deleting tab" });
        }
        window.scrollTo(0, 0)
      })
        .catch(error => {
          if (error.request.status == 401) {
            //refreshToken()
          }
          console.log(error);
        });
    }
  }

  const getFormDetailById = (formId) => {
    return axios({
      method: 'GET',
      url: service.baseAPIURL + `forms/GetFormDetail/?formId=${formId}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
  }

  const autoSaveForm = () => {
    let formDet = _.cloneDeep(formDetail);
    //set random name for form when autosave is triggered and form is new.
    if (!formDet.isPartial && !formDet.name) {
      formDet.name = 'Draft-' + getNextId();
      formDet.isPartial = true;
    }

    //setting createBy and updatedBy
    formDet.createdBy = parseInt(localStorage.userId)
    formDet.updatedBy = parseInt(localStorage.userId)

    deleteTabs();
    axios({
      method: 'POST',
      url: service.baseAPIURL + 'forms/UpsertFormDetail',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
      data: formDet
    }).then(response => {
      if (response.data.result != null || response.data.result.formId > 0) {
        setResponseAlert({ showAlert: true, alertType: 'warning', alertMessage: "Form autosaved successfully !!!" });
        getFormDetailById(response.data.result.formId).then(res => {
          setFormDetail(res.data.result.formDetail);
        });
      }
      else if (response.data.errors != null) {
        setResponseAlert({ showAlert: true, alertType: 'failure', alertMessage: "Failed to autosave form !!!" });
      }
    })
      .catch(error => {
        if (error.request.status == 401) {
          //refreshToken()
        }
        console.log(error);
      });
  }

  const saveForm = () => {

    //reminder duration less than form duration validation
    // if (formDetail.reminderDuration > Days[formDetail.dueDuration]) {
    //   setResponseAlert({ showAlert: true, alertType: 'warning', alertMessage: "Reminder duration should be less than Due duration !!!" });
    //   toggleFormNameModal();
    //   return;
    // }

    if (formDetail.reminderDuration > formDetail.dueDuration) {
      setResponseAlert({ showAlert: true, alertType: 'warning', alertMessage: "Reminder duration should be less than Due duration !!!" });
      toggleFormNameModal();
      return;
    }

    setFieldModalContent({ ...fieldModalContent, okDisabled: true });
    let formDet = _.cloneDeep(formDetail);
    formDet.isPartial = false;

    //nullifying all ids for new form before sending request to server
    if (!isUpdate) {
      if (formDet.tabs) {
        formDet.tabs.forEach(t => {
          t.id = 0;
          if (t.fields) {
            t.fields.forEach(f => { f.id = 0; })
          }
        })
      }
    }

    //setting createBy and updatedBy
    formDet.createdBy = parseInt(localStorage.userId)
    formDet.updatedBy = parseInt(localStorage.userId)

    deleteTabs();
    axios({
      method: 'POST',
      url: service.baseAPIURL + 'forms/UpsertFormDetail',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
      data: formDet
    }).then(response => {
      setFieldModalContent({ ...fieldModalContent, okDisabled: false });

      if (response.data.result != null || response.data.result.formId > 0) {
        setResponseAlert({ showAlert: true, alertType: 'success', alertMessage: "Form created successfully" });
        getFormDetailById(response.data.result.formId).then(res => {
          setFormDetail(res.data.result.formDetail);
        });

        toggleFormNameModal();
      }
      else if (response.data.errors != null) {
        setResponseAlert({ showAlert: true, alertType: 'failure', alertMessage: response.data.errors });
        setFieldModalContent({ ...fieldModalContent, okDisabled: false });
      }
      window.scrollTo(0, 0)
    })
      .catch(error => {
        if (error.request.status == 401) {
          //refreshToken()
        }
        console.log(error);
        setFieldModalContent({ ...fieldModalContent, okDisabled: false });
      });
  }

  const onDueDurationChange = (ev) => {
    setFormDetail({ ...formDetail, dueDuration: DueDurationOptions.filter(d => d.label === ev.target.value)[0].value });
  }

  //useInterval(autoSaveForm, 120000);

  return (
    formDetail &&
    <React.Fragment>
      <Alert color={responseAlert.alertType} isOpen={responseAlert.showAlert} toggle={() => setResponseAlert({ ...responseAlert, showAlert: false })}>
        <strong>{responseAlert.alertType.toUpperCase()}! </strong>  {responseAlert.alertMessage}
      </Alert>
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent py-4">
          <Row className="text-muted">
            <Col md="6" lg="6">
              <h3>{formDetail.name ? formDetail.name : 'Add Form'}</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Nav tabs>
            {getTabLinks()}
            <Button className="close ml-2 shadow-none p-0" onClick={addTab}>
              <span className="btn-inner--icon">
                <i className="fas fa-plus-circle fa-1x" aria-hidden="true"></i>
              </span>
            </Button>
          </Nav>
          <TabContent activeTab={activeTab}>
            {getTabPanes()}
          </TabContent>
        </CardBody>
        <CardFooter>
          <Row>
            <Col sm="3">
              <FormGroup>
                <Label>Due Duration</Label>
                {/* <Input type="select" value={formDetail.dueDuration ? DueDurationOptions.filter(o => o.value === formDetail.dueDuration)[0].label : ''}
                  name="Due Duration" onChange={onDueDurationChange}>
                  {DueDurationOptions.map(o => <option id={o.value}>{o.label}</option>)}
                </Input> */}
                <InputGroup>
                  <Input type="number" value={formDetail.dueDuration}
                    placeholder="Due Duration in days"
                    onChange={(ev) => setFormDetail({ ...formDetail, dueDuration: parseInt(ev.target.value) })} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>days</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label>Reminder Duration</Label>
                <InputGroup>
                  <Input type="number" value={formDetail.reminderDuration}
                    placeholder="Reminder Duration in days"
                    onChange={(ev) => setFormDetail({ ...formDetail, reminderDuration: parseInt(ev.target.value) })} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>days</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm='3'></Col>
            <Col sm="3" style={{ alignSelf: 'center' }}>
              <Button style={{ float: 'right' }} color="primary" onClick={toggleFormNameModal}>Save Form</Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
      <Modal className="modal-dialog-centered" isOpen={previewFieldModal}>
        <div className="modal-header">
          <h4>Field Configuration</h4>
          <Button
            aria-label="Close"
            className="close shadow-none"
            data-dismiss="modal"
            type="button"
            onClick={toggleFieldModal}>
            <span className="btn-inner--icon">
              <i className="fas fa-times" aria-hidden="true"></i>
            </span>
          </Button>
        </div>
        <div className="modal-body">
          <FormGroup className="mt-3">
            <Label>Field name</Label>
            <Input type="text" value={fieldModalContent.label}
              onChange={(ev) => setFieldModalContent({ ...fieldModalContent, label: ev.target.value })} />
          </FormGroup>
          <FormGroup className="mt-3">
            <Label>Field type</Label>
            <Select options={fieldModalContent.fieldTypeOptions}
              onChange={onFieldTypeSelection}
              value={fieldModalContent.fieldTypeOptions.filter(ft => ft.label === fieldModalContent.fieldType)} />
          </FormGroup>
          {fieldModalContent.showOptions &&
            <FormGroup>
              {fieldModalContent.options.map(val =>
                <InputGroup style={{ border: '1px solid #cad1d7' }} className="mt-1">
                  <Input type="text" style={{ border: 'none' }} value={val.value} placeholder="Enter option value"
                    onChange={(ev) => onFieldModalOptionValChange(ev.target.value, val.id)} />
                  <InputGroupAddon addonType="append">
                    <Button className="close shadow-none" onClick={() => removeOption(val)}>
                      <span className="btn-inner--icon">
                        <i className="fas fa-times" aria-hidden="true"></i>
                      </span>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              )}
              <Button color="secondary" className="mt-2" onClick={onAddOptionClick}>Add option</Button>
            </FormGroup>
          }
        </div>
        <Label className='text-warning text-center'>{fieldModalContent.warningMessage}</Label>
        <div className="modal-footer">
          <Button color="primary" onClick={updateFieldFromModal}>Ok</Button>
        </div>
      </Modal>
      <Modal className="modal-dialog-centered" isOpen={previewFormNameModal}>
        <div className="modal-header">
          <h4>Form name</h4>
          <button
            aria-label="Close"
            className="close shadow-none"
            data-dismiss="modal"
            type="button"
            onClick={toggleFormNameModal}>
            <span className="btn-inner--icon">
              <i className="fas fa-times" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup className="mt-3">
            <Input type="text" value={formDetail.name} placeholder="Enter form name"
              onChange={(ev) => setFormName(ev.target.value)} />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button color="primary" disabled={fieldModalContent.okDisabled} onClick={saveForm}>Ok</Button>
        </div>
      </Modal>
    </React.Fragment >
  )
}

export default AddUpdateForm              