import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Spinner,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Label,
} from "reactstrap";
import ReactDOM from 'react-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; 
import Select from 'react-select';
import service from '../../services';
import axios from "axios";
import moment from 'moment';
import refreshToken from '../../refresh';
import './calendar.css';

let accessToken, userId

async function ReloadEvents(fetchInfo, careLevelId,successCallback) {
    accessToken = localStorage.getItem('accessToken');
    let startDate = moment(fetchInfo.start).format('YYYY-MM-DD');
    let endDate = moment(fetchInfo.end).format('YYYY-MM-DD');
    axios({
        method: 'GET',
        url: service.baseAPIURL + 'events/GetEvents?careLevelId=' + careLevelId + '&startDate=' + startDate + '&endDate=' + endDate,
        headers: {
            'Authorization': 'Bearer ' + accessToken
        }
    }).then(response => {
        let eventsData = response.data.result.events;
            successCallback(
                eventsData.map(function (item) {
                    return {
                        "id": item.id,
                        "title": item.name,
                        "allday": false,
                        "start": moment(item.startDate).format('YYYY-MM-DD') + 'T' + moment(item.startTime).format('HH:mm'),
                        "end": moment(item.startDate).format('YYYY-MM-DD') + 'T' + moment(item.endTime).format('HH:mm'),
                    }
                })
            );
    })
}

class Calendar extends React.Component{
    constructor() {
        super()
        this.state = {
            background:"background",
            events: [],
            careLevels: [],
            selectedCareLevel: null,
            startDate: '',
            endDate: '',
            selectedDate: '',
            loadingMessage: 'Please wait till the file downloads...',
            showLoading:false,
            careLevelId: 0,
            deleteModal: {
                isOpen: false,
                eventId: 0,
                eventDate:''
            }
        }
        this.calendarRef = React.createRef();
    }

    componentDidMount() {
        let reactdom = $('.fc-daygrid');
        let tbl = $("table:eq(0)", reactdom);
        $("<h1>").html('Header Tool').insertBefore(tbl);
        console.log(tbl);
        //ReactDOM.render(reactdom[0], $('.fc-daygrid')[0]);


        let currentDate = moment(this.calendarRef.current._calendarApi.getDate());
        let careLevelId = 0, selectedCarelevel = null;
        let pathURL = window.location.href;
        if (pathURL.indexOf('?') != -1) {
            let getId = pathURL.split('?'),
                URLVal = getId[1].split('&');
            currentDate = moment(URLVal[0].split('=')[1]);
            careLevelId = parseInt(URLVal[1].split('=')[1]);
        }
        userId = localStorage.getItem('userId')
        accessToken = localStorage.getItem('accessToken')
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'communities/getcarelevelsbyfilter?userId=' + userId,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(response => {
            let careLevelsData = response.data.result.careLevels,
                carelevelOptions = []
            careLevelsData.forEach((item, i) => {
                if (!item.isActive) {
                    if (item.id === careLevelId) {
                        selectedCarelevel = {
                            value: item.id,
                            label: item.name
                        }
                    }
                    carelevelOptions.push({
                        value: item.id,
                        label: item.name
                    })
                }
            });
            let startOfMonth = moment(currentDate).startOf('month').format('YYYY-MM-DD');
            let endOfMonth = moment(currentDate).endOf('month').format('YYYY-MM-DD');
            this.setState({
                careLevels: carelevelOptions,
                startDate: startOfMonth,
                endDate: endOfMonth
            })
            if (selectedCarelevel != null) {
                this.handleCarelevels(selectedCarelevel);
            }
        }).catch(error => {
                if (error.request.status == 401) {
                    refreshToken()
                }
                console.log(error);
            });
    }

    handleCarelevels = (item) => {
        let newState = Object.assign({}, this.state);
        newState.selectedCareLevel = item;
        this.setState(newState);
    }

    eventClicked = (eventId, eventDate) => {
        console.log(eventId);
        console.log(eventDate);
        window.location.href = '/#/admin/updateevents?id=' + eventId + '&eventdate=' + moment(eventDate).format("YYYY-MM-DD");
    }

    eventDelete = (eventId, eventDate) => {
        let newState = Object.assign({}, this.state);
        newState.deleteModal.isOpen = !newState.deleteModal.isOpen;
        newState.deleteModal.eventId = eventId;
        newState.deleteModal.eventDate = eventDate;
        this.setState(newState);
    }

    removeEvent = (type) => {
        let deleteForm = {
            "eventId": parseInt(this.state.deleteModal.eventId),
            "deleteType": type,
            "OccuranceDate": moment(this.state.deleteModal.eventDate).format('YYYY-MM-DD')
        };
        accessToken = localStorage.getItem('accessToken')
        axios({
            method: 'POST',
            url: service.baseAPIURL + 'events/DeleteEvent',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: deleteForm
        }).then(response => {
            this.deleteToggle();
        })
            .catch(error => {
                console.log(error);
            });
    }

    downloadPdf = () => {
        accessToken = localStorage.getItem('accessToken')
        let newState = Object.assign({}, this.state);
        newState.showLoading = true;
        this.setState(newState);
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'events/DownloadPdf?url=https://dev.k2btech.com/shared/CalendarPreview.html',
            headers: {
                'Authorization': 'Bearer ' + accessToken
            },
            responseType: 'blob'
        }).then(response => {
            console.log(response);
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'calendar.pdf'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            newState.showLoading = false;
            this.setState(newState);
        });
    }
    

    eventInfo = (info) => {
        const content =
            <div>
                <span style={{cursor: "pointer" }} onClick={() => this.eventClicked(info.event.id, info.event.start)}>
                    <span>{info.timeText}</span>&nbsp;
                    <span>{info.event.title}</span>&nbsp;
                </span>
                <i style={{ cursor: "pointer" }} className="fa fa-1x fa-trash" onClick={() => this.eventDelete(info.event.id, info.event.start)}></i>
            </div>;
        ReactDOM.render(content, info.el);
        return info.el;
    }

    dayClicked = (date) => {
        let eventDate = moment(date).format('MM/DD/YYYY');
        window.location.href = '/#/admin/addevents?eventDate=' + eventDate + '&careLevelId=' + this.state.selectedCareLevel.value;
    }

    deleteToggle = () => {
        let newState = Object.assign({}, this.state);
        newState.deleteModal.isOpen = !newState.deleteModal.isOpen;
        this.setState(newState);
    }



    render() {
        const { careLevels, selectedCareLevel } = this.state;
        const timeFormat = {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false
        }

        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-4">
                        <Row className="text-muted">
                            <Col md="6" lg="6">
                                <h3>Calendar</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-2">
                        <Row className="mb-3 align-items-end">
                            <Col lg="4" md="4">
                                <div className='d-flex align-items flex-column'>
                                    <Label>Care Level</Label>
                                    <Select
                                        options={careLevels}
                                        value={this.state.selectedCareLevel}
                                        onChange={this.handleCarelevels}
                                    />
                                </div>
                            </Col>
                            <Col lg="1" md="1">
                                <a target="_blank" href="https://dev.k2btech.com/shared/CalendarPreview.html">Preview</a>
                            </Col>
                            <Col lg="5" md="5">
                                <Button onClick={this.downloadPdf}>Download</Button>
                                {
                                    this.state.showLoading ? <span>{this.state.loadingMessage}</span>:''
                                }
                            </Col>
                        </Row>
                        <div>
                            <FullCalendar
                            eventTextColor="gray"
                            eventBackgroundColor="rgba(0,0,0,0)"
                            eventBorderColor="rgba(0,0,0,0)"
                            initialView="dayGridMonth"
                            eventDisplay="block"
                            eventDidMount={this.eventInfo}
                            eventTimeFormat={timeFormat}
                            timeFormat="h:mm"
                            navLinks={true}
                            navLinkDayClick={this.dayClicked}
                            headerToolbar={{
                                left: "prev,next",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay"
                            }}
                            ref={this.calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            events={(info, successCallback, failureCallback ) => {
                                ReloadEvents(info, selectedCareLevel == null ? 0 : selectedCareLevel.value, successCallback);
                            }}
                            />
                        </div>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.deleteModal.isOpen} toggle={this.deleteToggle}>
                    <ModalHeader toggle={this.deleteToggle}>Delete Event</ModalHeader>
                    <ModalBody>
                        Remove event for today or complete series
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.removeEvent('Current')}>Remove For Today</Button>{' '}
                        <Button color="primary" onClick={()=>this.removeEvent('all')}>Remove</Button>{' '}
                        <Button color="secondary" onClick={this.deleteToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>)
    }
}

export default Calendar;