import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Collapse,
    Badge,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import service from '../../services';
import axios from "axios";
import _ from 'lodash'
import { FieldTypes } from './form-utility';

let accessToken;
const delimiter = '<#::#>checked';

function useDebounce(callback, delay) {
    let debouncedCallback = useCallback(_.debounce(callback, delay), [delay]);
    return debouncedCallback;
}

const FormFilter = (props) => {

    const [searchText, setSearchText] = useState('');
    const [resultView, setResultView] = useState({});
    const [openStates, setOpenStates] = useState({});

    const tableBorderStyle = {
        border: '1px solid black',
        borderCollapse: 'collapse'
    }

    useEffect(() => {
        accessToken = localStorage.accessToken;
    }, [])

    const debouncedSearch = useDebounce((nextValue) => getFormFilterResult(nextValue), 1000);

    const searchTextChangeHandler = (ev) => {
        let newValue = ev.target.value;
        setSearchText(newValue);
        debouncedSearch(newValue);
    }

    const search = () => {
        getFormFilterResult(searchText);
    }

    const fillResultView = (data, searchVal) => {
        data.forEach(x => {
            let val = eval(x.fieldValue);
            if (isMultiValueField(x.fieldType)) {
                val = val.filter(fv => fv.indexOf(searchVal) >= 0 && fv.endsWith(delimiter));
            }
            x.fieldValue = val[0].replace(delimiter, '');
        });

        // var grouped = _.mapValues(_.groupBy(data, 'fieldId'),
        //     clist => clist.map(d => _.omit(d, 'fieldId')));

        var groupedResult = _.mapValues(_.groupBy(data, 'fieldId'),
            clist => _.mapValues(_.groupBy(clist, 'fieldValue'), flist => flist.map(d => d)));

        setResultView(groupedResult);

        let openStates_clone = {};
        Object.keys(groupedResult).forEach(fieldId => {
            Object.keys(groupedResult[fieldId]).forEach(fieldValue => {
                let key = `${fieldId}:--:${fieldValue}`;
                openStates_clone[key] = false;
            })
        })
        setOpenStates(openStates_clone);
    }

    const getFormFilterResult = (searchVal) => {
        if (searchVal) {
            axios({
                method: 'GET',
                url: service.baseAPIURL + `forms/GetFormFilterResult?searchText=${searchVal}`,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(response => {
                let data = response.data.result.filterResult;
                fillResultView(data, searchVal);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const isMultiValueField = (fieldType) => {
        return fieldType === FieldTypes.radio
            || fieldType === FieldTypes.checkbox
            || fieldType === FieldTypes.dropdown
    }

    const toggle = (key) => {
        let openStates_clone = { ...openStates };
        openStates_clone[key] = !openStates_clone[key];
        setOpenStates(openStates_clone);
    }
    const isOpen = (key) => {
        return openStates[key];
    }

    const navigateToResident = (residentId, formId) => {
        props.history.push(`././resident/${residentId}/form/${formId}`);
    }

    const exportToExcel = () => {
        var downloadurl;
        var dataFileType = 'application/vnd.ms-excel';
        let tableSelect = document.getElementById("tblexportData");
        var tableHTMLData = tableSelect.outerHTML.replace(/ /g, '%20');

        let filename = `form-filter-result_${searchText}.xls`;
        downloadurl = document.createElement("a");
        document.body.appendChild(downloadurl);
        if (navigator.msSaveOrOpenBlob) {
            var blob = new Blob(['\ufeff', tableHTMLData], {
                type: dataFileType
            });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadurl.href = 'data:' + dataFileType + ', ' + tableHTMLData;
            downloadurl.download = filename;
            downloadurl.click();
        }
    }

    return (
        <>
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent py-4 border-0">
                    <Row className="mb-4">
                        <Col lg="6" md="6" sm="12">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-search" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        className="form-control-alternative"
                                        placeholder="Search Forms"
                                        type="text"
                                        value={searchText}
                                        onChange={searchTextChangeHandler}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={search}>Search</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardHeader>
                {Object.keys(resultView).length > 0 &&
                    <CardBody className="px-lg-5 py-lg-5">
                        <Button aria-label="Close" className="close ml-2 shadow-none" onClick={exportToExcel}>
                            <span>
                                <i class="far fa-file-excel fa-lg"></i>
                            </span>
                        </Button>
                        {Object.keys(resultView).map(fieldId =>
                            <div className='mb-2'>
                                <h1><Badge color="dark">{resultView[fieldId][Object.keys(resultView[fieldId])[0]][0].fieldName}</Badge></h1>
                                {Object.keys(resultView[fieldId]).map(fieldValue =>
                                    <>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <Button onClick={() => toggle(`${fieldId}:--:${fieldValue}`)}
                                                    className='shadow-none'>
                                                    {isOpen(`${fieldId}:--:${fieldValue}`) ?
                                                        <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                                                </Button>
                                            </InputGroupAddon>
                                            <h3 style={{ padding: '5px' }}>{fieldValue} </h3>
                                        </InputGroup>
                                        <Collapse isOpen={isOpen(`${fieldId}:--:${fieldValue}`)}>

                                            <ListGroup>
                                                {resultView[fieldId][fieldValue].map(f =>
                                                    <ListGroupItem onClick={() => navigateToResident(f.residentId, f.formId)} tag="button" action>{f.residentName}</ListGroupItem>
                                                )}
                                            </ListGroup>

                                        </Collapse>
                                    </>
                                )}

                            </div>
                        )}

                    </CardBody>
                }
            </Card>
            {Object.keys(resultView).length > 0 &&
                <table id="tblexportData" style={tableBorderStyle} className="table d-none">
                    <tr>
                        <th colspan="5"><u>Search key = {searchText}</u></th>
                    </tr>
                    <tr>
                    </tr>
                    {Object.keys(resultView).map(fieldId =>
                        <>
                            <tr></tr>
                            <tr>
                                <th style={tableBorderStyle} colspan="5"><u>Field Name  = {resultView[fieldId][Object.keys(resultView[fieldId])[0]][0].fieldName}</u></th>
                            </tr>
                            {Object.keys(resultView[fieldId]).map(fieldValue =>
                                <>
                                    <tr>
                                        <th style={tableBorderStyle}>Field Value = {fieldValue}</th>
                                    </tr>
                                    {resultView[fieldId][fieldValue].map(f =>
                                        <tr>
                                            <td style={tableBorderStyle}>{f.residentName}</td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </table>
            }
        </>
    )
}

export default FormFilter;