import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col, 
    FormFeedback,
    CardFooter,
    Alert, 
    Label,
  } from "reactstrap";

import {Link} from 'react-router-dom';
import Select from 'react-select';
import CareLevelForm from '../../components/communities/careLevel'
import defaultPic from '../../assets/img/theme/defaultProfile.jpg';
import baseURL from '../../services';
import axios from "axios";
import validator from 'validator';
import service from '../../services';

const accessToken = localStorage.accessToken
class UpdateCommunities extends React.Component{
  
  constructor(){
    super()
    this.state = {
        communityName : '',
        abbrivation : '',
        streetName : '',
        city : '',
        states : [],
        countries : [],
        phoneNum : '',
        faxNum : '',
        zipcode: '',
        communityPic: defaultPic,
        communityNameError : false,
        abbrivationError: false,
        streetNameError: false,
        cityError: false,
        stateError: false,
        countryError: false,
        zipcodeError: false,
        phoneNumError: false,
        faxNumError: false,
        communityStatusError: false,
        communityTypeError: false,
        completeForm: true,
        communityStatus : true,
        carelevels: [],
        carelevelNum: 0,
        selectedCountry: null,
        selectedState: null,
        selectedStateId: null,
        selectedCountryId: null,
        showAlertType: null,
        selectedCountryValIndex : 0,
        selectedStateValIndex : 0,
        commmunityId: null,
        carelevelFormVal : [],
        communityPicFileType:'',
        communityPicName : '',
        isDisabled: false,
        Alertvisible: false
      }
      this.removeCareLevel = this.removeCareLevel.bind(this)
  }
  componentDidMount = () => {
    let pathURL = window.location.href
    let getId = pathURL.split('?');
    let id = getId[1];
    this.setState({communityId: id})
      this.getCommunitiesDetails(id)
  }

    // states and countries

    getCountriesAndStates = () => {
      const {selectedCountryId, selectedStateId} = this.state;
      axios({
        method: 'GET',
        url: service.baseAPIURL + 'communities/getstatesandcountries',
        headers: {
            'Authorization': 'Bearer ' + accessToken
        }
        }).then(response => {
          let states = response.data.result.states,
              countries = response.data.result.countries;
          let countriesOptions = [], stateOptions = [], selectedCountryVal = null, selectedStateVal = null;
          countries.forEach((item, i) => {
            if(!item.isActive){ 
              if(item.id  === selectedCountryId)   {
                selectedCountryVal = {value: item.id,
                label: item.name
              }
              }     
              countriesOptions.push({
                value: item.id,
                label: item.name
              })
            }
        })
          states.forEach((item, i) => {
            if(!item.isActive){
              if(item.id  === selectedStateId)   {
                selectedStateVal = {value: item.id,
                label: item.name
              }
              }  
              stateOptions.push({
                value: item.id,
                label: item.name
              })
            }
          })
          console.log(countriesOptions)
          this.setState({
            countries: countriesOptions,
            states: stateOptions,
            selectedCountry: selectedCountryVal,
            selectedState: selectedStateVal
          })
      })
        .catch(error => {
          console.log(error);
        });  
    }

  // calling the get communities details api
  getCommunitiesDetails = (id) => {

    axios({
      method: 'GET',
      url: service.baseAPIURL + 'communities/getcommunitydetails?id='+ id,
      headers: {
          'Authorization': 'Bearer ' + accessToken
      }
      }).then(response => {
        let data = response.data.result.community
        let profilePic = data.avatar === null ? '' : service.baseImgURL+ data.avatar
        let carelevelNum = data.careLevels === undefined ? 0 : data.careLevels.length
        this.setState({
          communityName : data.name === null ? '': data.name ,
          abbrivation : data.abbreviation === null ? '': data.abbreviation,
          streetName : data.street === null ? '' : data.street,
          city : data.city === null ? '' : data.city,
          phoneNum : data.phoneNumber === null ? '' : data.phoneNumber,
          faxNum : data.faxNumber === null ? '' : data.faxNumber,
          communityStatus: data.isActive,
          communityPic: profilePic,
          selectedCountryId: data.countryId,
          selectedStateId: data.stateId,
          zipcode: data.zipcode === null ? '' : data.zipcode,
          carelevelNum: carelevelNum,
          carelevels: data.careLevels === undefined ? [] : data.careLevels
        });
        this.getCountriesAndStates()
      })
      .catch(error => {
        console.log(error);
      });
  }

  submitDetails = () => {
    const {communityName, abbrivation, streetName, city, selectedState, selectedCountry, zipcode, phoneNum,faxNum, communityPicName, communityStatus, completeForm, selectedCountryId, selectedStateId, communityPicFileType, communityId } = this.state
    if(validator.isEmpty(communityName)){
       this.setState({communityNameError : true, completeForm: false})
    }
    if(validator.isEmpty(communityName)){
      this.setState({abbrivationError: true, completeForm: false})
    }
    if(validator.isEmpty(communityName)){
      this.setState({streetNameError: true, completeForm: false})
    }
    if(validator.isEmpty(communityName)){
      this.setState({cityError: true, completeForm: false})
    }
    if(selectedState === null){
      this.setState({stateError: true, completeForm: false})
    }
    if(selectedCountry === null){
      this.setState({countryError: true, completeForm: false})
    }
    if(validator.isEmpty(phoneNum) || !validator.isMobilePhone(phoneNum, ['en-IN'])){
      this.setState({phoneNumError: true, completeForm: false})
    }
    if(validator.isEmpty(zipcode) || !validator.isPostalCode(zipcode,"IN")){
      this.setState({zipcodeError:true, completeForm:false})
    }
    if(completeForm){
      let id = parseInt(communityId);
      let formData = {
        "Id": id,
        "Name": communityName,
        "Abbreviation": abbrivation,
        "isActive": communityStatus,
        "Avatar" : 'no-name.jpg',
        "Street": streetName,
        "City": city,
        "StateId": selectedStateId,
        "CountryId": selectedCountryId,
        "PhoneNumber": phoneNum,
        "FaxNumber": faxNum,
        "Zipcode": zipcode,
        "FileUtility":{
          "FileData": communityPicName,
          "FileExtension": communityPicFileType
        }
      }

      axios({
        method: 'POST',
        url: service.baseAPIURL + 'communities/updatecommunity',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        data: formData
        }).then(response => {
          if(response.data.result != null && response.data.result.isSuccess){
            if(this.state.carelevelFormVal > 0){
              this.updateCareLevel()
            } 
            else{
              this.setState({
                Alertvisible: true,
                showAlertType : 'success'
              })
            }     
           }
         else if(response.data.errors != null){
           this.setState({
             Alertvisible: true,
             showAlertType : 'danger'
           })
         }
         window.scrollTo(0, 0)
        })
        .catch(error => {
          console.log(error);
        });
    }
 };

 updateCareLevel = () => {
  let careLevelData = this.state.carelevelFormVal;
  axios({
    method: 'POST',
    url: service.baseAPIURL + 'communities/addorupdatecarelevel',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
    },
    data: careLevelData
    }).then(response => {
      this.setState({
        Alertvisible: true,
        showAlertType : 'success'
      })
        console.log(response.data)
    })
    .catch(error => {
      this.setState({
        Alertvisible: true,
        showAlertType : 'danger'
      })
        console.log(error);
    });
 }

 handleChangeCountry = selectedCountry => {
  this.setState({ selectedCountry: selectedCountry, selectedCountryId: selectedCountry.value, completeForm:true, countryError: false});
};

handleChangeState = selectedState => {
  this.setState({ selectedState: selectedState, selectedStateId: selectedState.value, completeForm:true, stateError: false });
};

 addCareLevels = () => {
   this.setState({
    carelevelNum : this.state.carelevelNum + 1,
    isDisabled : true
  })
  
 }

ChangeInput = (text, propertyName, propertyNameError) => {
  this.setState({
    [propertyName]: text,
    completeForm: true
  })
  if(text !== ''){
    this.setState({
      [propertyNameError] : false
    })
  }
}
 removeCareLevel = (index) => {
  
  this.setState({
    carelevelNum : this.state.carelevelNum - 1,
    isDisabled : false
  })
 }
 getCareLevelsVal = (data, i) => {
  this.setState({
    carelevelFormVal:  data
  }) 
 }

 changeProfilePic = (e) => {
  e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      let fileName = file.name.split('.'),
          fileData = reader.result.split('base64,');
        console.log(fileData[1])
      this.setState({
        communityPicFileType: '.'+fileName[1],
        communityPic: reader.result,
        communityPicName : fileData[1]
      });
 }
}
renderCountrySelect = () => {
  if(this.state.selectedCountryId != null && this.state.selectedCountry != null)   {
    return(
      <Select 
      value={this.state.selectedCountry}
      onChange={this.handleChangeCountry}
      options={this.state.countries}
      className={this.state.stateError ? 'is-invalid': ''} /> 
    )
  }   
}
    render(){
      const levels = [];
      for (var i = 0; i < this.state.carelevelNum; i++) {
        let careVal = this.state.carelevels[i]
        
        levels.push(<CareLevelForm key={i} id={i} careVal={careVal} commmunityId={this.state.communityId}  getCareLevelsVal={this.getCareLevelsVal} removeCareLevel={this.removeCareLevel}  />);
      };
      const profilePicStyle = {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat:'no-Repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url('+ this.state.communityPic +')',
        borderRadius: '5px',
        backgroundColor: '#ebebeb'
    }
   
      const {communityNameError,  abbrivationError, streetNameError, cityError, stateError, countryError, zipcodeError, phoneNumError,faxNumError, communityStatus, states, countries, communityName, abbrivation, streetName, city, phoneNum, faxNum, Alertvisible, showAlertType, selectedCountryId, selectedStateId, zipcode, isDisabled, selectedState, selectedCountry } = this.state
      console.log('selectedStateId :' + selectedStateId)
      console.log('selectedCountryId :' + selectedCountryId)
      let alertMessage, alertStatus
      if(this.state.showAlertType === 'success'){
        alertMessage = 'community details updated'
          alertStatus = 'Success'
      }
      else{
        alertMessage = 'community details couldn\'t be updated'
        alertStatus = 'Error'
      }
     
      return(
            <>
            <Alert color={showAlertType} isOpen={Alertvisible} toggle={(e) => this.setState({Alertvisible: false})}>
            <strong>{alertStatus}! </strong>  {alertMessage}
          </Alert>
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent py-4">  
                  <Row className="text-muted">
                    <Col md="6" lg="6">
                    <h3>Update Community Details</h3>
                    </Col>
                    <Col md="6" lg="6" className="text-right">
                    <Link to='./communities'> <i className="fa fa-chevron-left" aria-hidden="true"></i> Back</Link>
                    </Col>                   
                  </Row>   
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                
                  <Form role="form">
                    <Row>
                    <Col md="3" sm="1" lg="3" className="mb-3 position-relative">
                          <Input onChange={(e) => this.changeProfilePic(e)} id="communityAvatar" type="file" inputprops={{ accept: 'image/*' }} style={{position: 'absolute', width:'100%', height: '100%', opacity:0}}/>
                          <div style={profilePicStyle} ></div>
                        
                      </Col>
                      <Col md="9" sm="1" lg="9">
                        <Row>
                        <Col md="6" sm="1" lg="6"> 
                        <FormGroup className={communityNameError ? 'has-danger mb-3' : 'mb-3'}>
                         <Label>Community Name</Label>
                           <Input value={communityName} onChange={(e) => this.ChangeInput(e.target.value, 'communityName', 'communityNameError')} type="text" autoComplete="new-name" invalid={communityNameError}/>
                           <FormFeedback>Enter the name of the community</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="1" lg="6">
                      <FormGroup className={abbrivationError ? 'has-danger mb-3' : 'mb-3'}>
                      <Label>Abbrivation</Label>
                           <Input value={abbrivation}  onChange={(e) => this.ChangeInput(e.target.value, 'abbrivation', 'abbrivationError')} type="text" invalid={abbrivationError}/>
                           <FormFeedback>Enter abbrivation for the community name</FormFeedback>
                        </FormGroup>
                      </Col>
                      </Row>
                      <Row>
                      <Col md="6" sm="12" lg="6">
                      <FormGroup className={phoneNumError ? 'has-danger mb-3' : 'mb-3'}>
                          <Label> Phone Number </Label>
                          <Input value={phoneNum} onChange={(e) => this.ChangeInput(e.target.value, 'phoneNum', 'phoneNumError')}  placeholder="(xxx) - xxx - xxx" id="phoneNum" type="number" invalid={phoneNumError}/>
                          <FormFeedback>Enter valid phone number</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="12" lg="6">
                      <FormGroup className={faxNumError ? 'has-danger mb-3' : 'mb-3'}>
                      <Label>Fax Number </Label>
                          <Input value={faxNum} onChange={(e) => this.ChangeInput(e.target.value, 'faxNum', 'faxNumError')}  placeholder="(xxx) - xxx - xxx"id="faxNum" type="number"/>
                        </FormGroup>
                      </Col>
                      </Row>
                      </Col>
                        
                      <Col md="4" sm="1" lg="4">
                        <FormGroup className={streetNameError ? 'has-danger mb-3' : 'mb-3'}>
                        <Label>Street Name</Label>
                          <Input value={streetName} onChange={(e) => this.ChangeInput(e.target.value, 'streetName', 'streetNameError')}  type="text" invalid={streetNameError}/>
                          <FormFeedback>Enter your street name</FormFeedback>
                        </FormGroup>
                        </Col>
                        
                      <Col md="4" sm="1" lg="4">
                        <FormGroup className={cityError ? 'has-danger mb-3' : 'mb-3'}>
                        <Label>City</Label>
                          <Input value={city} placeholder="City" onChange={(e) => this.ChangeInput(e.target.value, 'city', 'cityError')} type="text" invalid={cityError} />
                          <FormFeedback>Enter your city name</FormFeedback>
                        </FormGroup>
                        </Col>
                        <Col md="4" lg="4" sm="12">
                      <FormGroup className={zipcodeError ? 'has-danger mb-3' : 'mb-3'}>
                        <Label>Zipcode</Label>
                          <Input value={zipcode} onChange={(e) => this.ChangeInput(e.target.value, 'zipcode', 'zipcodeError')} type="text" invalid={zipcodeError} />
                          <FormFeedback>Enter your zipcode</FormFeedback>
                        </FormGroup>
                      </Col>
                        
                      <Col md="4" sm="1" lg="4">
                        <FormGroup className={stateError ? 'has-danger mb-3' : 'mb-3'}>
                        <Label>State </Label>
                        <Select
                          value={selectedState}
                          onChange={this.handleChangeState}
                          options={states}
                          className={stateError ? 'is-invalid': ''}
                          
                        />
                          {stateError ? <div className="error-msg">Select a state</div>: ''}
                        </FormGroup>
                        </Col>
                        
                      <Col md="4" sm="1" lg="4">
                        <FormGroup className={countryError ? 'has-danger mb-3' : 'mb-3'}>
                          <Label>Country </Label>
                          <Select 
                          value={selectedCountry}
                          onChange={this.handleChangeCountry}
                          options={countries}
                          className={this.state.stateError ? 'is-invalid': ''} /> 
                        {countryError ? <div className="error-msg">Select a country</div>: ''}
                        
                        </FormGroup>
                      </Col>
                     
                     
                      <Col md="4" sm="12" lg="4">    
                        <div className="d-flex align-items-center justify-content-between h-100">
                            <div className="mb-1 mr-3">Active</div>
                            <label className="custom-toggle">
                              <input defaultChecked={communityStatus} type="checkbox" />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                        </div>
                      </Col>
                      
                      <Col md="12" sm="12" lg="12" className="mt-3 mb-3 border-top pt-4">
                        <Row className="mb-3">
                          <Col md="6" sm="6" lg="6">
                            <h4>Care Levels</h4>
                          </Col>
                          <Col md="6" sm="6" lg="6" className="text-right">
                              <Button className="btn-icon btn-2" color="default" type="button" size="sm" outline onClick={this.addCareLevels} disabled={isDisabled}>
                                <span className="btn-inner--icon">
                                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                </span>
                                <span className="btn-inner--text">Add</span>
                              </Button>
                         
                          </Col>
                        </Row>
                        <div className="careLevelWrap">
                        {levels}
                        </div>
                      </Col>
                    </Row>
                    
                  </Form>
                </CardBody>
                <CardFooter>
                <Row>
                     
                      <Col md="2" lg="2" className="offset-md-10 text-right" >
                      <Button color="primary" size="sm" type="button" onClick={this.submitDetails}>Save</Button>
                      </Col>               
                    </Row>
                </CardFooter>
              </Card>
            </>
        )
    }
    
}


export default UpdateCommunities