import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader, CardBody, Row, Col, FormGroup, InputGroup, InputGroupAddon, Input, Button, InputGroupText, UncontrolledTooltip, CardFooter, ButtonGroup, Label, Modal, ListGroup, ListGroupItem } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import service from '../../services';
import axios from "axios";
import Select from 'react-select';
import _ from 'lodash';
import { RFC_2822 } from '../../../node_modules/moment';
// import refreshToken from '../../refresh'

let accessToken;
let userId;
class ResidentList extends React.Component {

  constructor() {
    super();
    this.state = {
      columns: [{
        dataField: 'id',
        text: 'UserId',
        hidden: true
      }, {
        dataField: 'firstName',
        text: 'First Name',
        sort: true,
        formatter: this.linkFormatter

      }, {
        dataField: 'lastName',
        text: 'Last Name',
        sort: true
      }, {
        dataField: 'gender',
        text: 'Gender',
        sort: true
      }, {
        dataField: 'location',
        text: 'Location',
        sort: true
      }],
      residentList: [],
      filteredResidentList: [],
      gridState: true,
      careLevels: [],
      selectedCareLevelId: '',
      previewModal: false,
      formsDropdownOpen: {},
      residentForms: {}
    }
  }
  componentDidMount = () => {
    accessToken = localStorage.accessToken
    userId = localStorage.userId
    axios({
      method: 'GET',
      url: service.baseAPIURL + 'communities/getcarelevelsbyfilter?userId=' + userId,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let careLevelsData = response.data.result.careLevels,
        carelevelOptions = []
      careLevelsData.forEach((item, i) => {
        if (!item.isActive) {
          carelevelOptions.push({
            value: item.id,
            label: item.name
          })
        }
      });

      this.setState({
        careLevels: carelevelOptions
      })
    })
      .catch(error => {
        if (error.request.status === 401) {
          // return refreshToken
        }
        console.log(error.request.status);
      });

  }
  // resident list from api
  linkFormatter(cell, row) {
    return (<Link to={'./updateresident?id=' + row.id}> {row.firstName}</Link>);
  };
  getResidentsList = (id) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + 'residents/getresidents?careLevelId=' + id + '&status=true',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      console.log(response)
      let data = response.data.result.residents
      this.setState({
        residentList: data,
        filteredResidentList: data
      });

      //setdropdown state
      let ddObj = {};
      data.forEach(res => {
        ddObj[res.id] = false;
        //this.getFormsByResidentId(res.id);
      });
      this.setState({ formsDropdownOpen: ddObj });
    }).catch(error => {
      if (error.request.status === 401) {
        // return refreshToken
      }
    });
  }

  // getFormsByResidentId = (residentId) => {
  //   axios({
  //     method: 'GET',
  //     url: service.baseAPIURL + 'forms/GetFormsByResidentId?residentId=' + residentId,
  //     headers: {
  //       'Authorization': 'Bearer ' + accessToken
  //     }
  //   }).then(response => {
  //     let resForms = response.data.result.residentForms;
  //     if (resForms && resForms.length > 0) {
  //       let residentForms_clone = _.cloneDeep(this.state.residentForms);
  //       residentForms_clone[residentId] = resForms;
  //       this.setState({ residentForms: residentForms_clone });
  //     }
  //   })
  // }

  GetResidentFormsByCareLevelId = (carelevelId) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + 'forms/GetResidentFormsByCareLevelId?carelevelId=' + carelevelId,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let resForms = response.data.result.residentFormMap;
      if (resForms) {
        this.setState({ residentForms: JSON.parse(resForms) });
      }
    })
  }

  // change the layout view
  changeView = (x) => {
    if (x === 'grid') {
      this.setState({
        gridState: true
      })
    }
    else {
      this.setState({
        gridState: false
      })
    }
  }

  handleCarelevels = selectedCareLevel => {
    let id = selectedCareLevel.value;
    this.getResidentsList(id);
    this.GetResidentFormsByCareLevelId(id);
  };

  filterResidents = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredResidentList: [...this.state.residentList] })
    } else {
      searchText = searchText.toLowerCase();
      const filteredResidents = this.state.residentList
        .filter(res => res.firstName.toLowerCase().indexOf(searchText) >= 0
          || res.lastName.toLowerCase().indexOf(searchText) >= 0
          || res.location.toLowerCase().indexOf(searchText) >= 0);
      this.setState({ filteredResidentList: [...filteredResidents] });
    }
  }

  viewResident = (id) => {
    axios({
      method: 'GET',
      url: service.baseAPIURL + 'residents/getresidents?id=' + id,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(response => {
      let data = response.data.result
      console.log(data)
      this.toggleModal("previewModal")
      this.setState({
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dateOfBirth,
        doj: data.dateOfJoining,
        profilePic: data.avatar,
        location: data.location,
        gender: data.gender
      })
    })
      .catch(error => {
        console.log(error);
      });
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  formsDropdownToggle = (key) => {
    let ddobj = { ...this.state.formsDropdownOpen };
    ddobj[key] = !ddobj[key];
    this.setState({ formsDropdownOpen: ddobj });
  }

  // rendering the view layout
  renderView = () => {
    const { filteredResidentList, gridState, columns, formsDropdownOpen, residentForms } = this.state
    if (gridState && filteredResidentList.length !== 0) {
      const resident = filteredResidentList.map((data, i) => {
        return (
          <Col lg="4" md="4" sm="12" className="mb-3" key={i}>
            <Card>
              <CardBody className="p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5>{data.firstName + ' ' + data.lastName}</h5>
                    <p>{data.dateOfJoining.split('T')[0]}</p>
                    <p>{data.location}</p>
                  </div>
                  <div>
                    <img width="60" className="img-fluid rounded shadow" src={service.baseImgURL + data.avatar} />
                  </div>
                </div>
              </CardBody>
              <CardFooter className="p-0">
                <div className="d-flex justify-content-between">
                  <Link class="btn btn-lg" to={'./updateresident?' + data.id} data-placement="top" id={"tooltipedit"}>
                    <span className="btn-inner--icon">
                      <i className="fa fa-edit" aria-hidden="true"></i>
                    </span>
                  </Link>
                  <UncontrolledTooltip delay={0} placement="top" target={"tooltipedit"}> Edit </UncontrolledTooltip>

                  <Dropdown isOpen={formsDropdownOpen[data.id]} style={{ padding: '5px' }} toggle={() => this.formsDropdownToggle(data.id)}>
                    <DropdownToggle caret style={{ width: '200px' }}> Forms </DropdownToggle>
                    {residentForms && residentForms[data.id] ?
                      <DropdownMenu center>
                        {residentForms[data.id].map((rf, i) => <>
                          <DropdownItem id={rf.formId} onClick={() => this.props.history.push(`./resident/${data.id}/form/${rf.formId}`)}>
                            {rf.formName}
                          </DropdownItem>
                          {i < residentForms[data.id].length - 1 && <DropdownItem divider />}
                        </>)}
                      </DropdownMenu>
                      :
                      <DropdownMenu>
                        <DropdownItem text>No forms found</DropdownItem>
                      </DropdownMenu>
                    }
                  </Dropdown>

                  <Button color="link" size="lg" type="button" onClick={() => this.viewResident(data.id)}><span className="btn-inner--icon" data-placement="top" id={"tooltipview"}><i className="fa fa-eye"></i></span></Button>
                  <UncontrolledTooltip delay={0} placement="top" target={"tooltipview"}> View </UncontrolledTooltip>
                </div>
              </CardFooter>
            </Card>
          </Col>
        )
      })

      return resident
    }

    else if (!gridState && columns.length !== 0) {
      return (
        <Col lg="12" md="12" sm="12">
          <BootstrapTable classes="custom-table-css" striped hover condensed
            keyField='id'
            data={filteredResidentList}
            columns={columns} />
        </Col>
      )
    }
  }
  render() {
    const { gridState, careLevels } = this.state
    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent px-4 pt-4 pb-0 border-0">
            <Row>
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search Residents"
                          type="text"
                          onChange={this.filterResidents}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6" lg="6">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Link to="./addresident" className="btn btn-outline-default btn-sm"><i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Add New </Link>
                  </div>
                  <div>
                    <ButtonGroup>
                      <Button className={gridState ? 'active' : ''} onClick={() => this.changeView('grid')}><i className="fa fa-th-large" aria-hidden="true"></i></Button>
                      <Button className={gridState ? '' : 'active'} onClick={() => this.changeView()}><i className="fa fa-th-list" aria-hidden="true"></i></Button>
                    </ButtonGroup>
                  </div>


                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row className="mb-5">
              <Col lg="4" md="4" sm="12">
                <Label>CareLevels </Label>
                <Select
                  options={careLevels}
                  onChange={this.handleCarelevels}
                />
              </Col>
            </Row>
            <Row>
              {this.renderView()}
            </Row>

          </CardBody>
        </Card>
        <Modal className="modal-dialog-centered" isOpen={this.state.previewModal}>
          <div className="modal-header">
            <h4>Resident Details</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("previewModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row className="justify-content-md-center">
              <Col lg="11" md="11">
                <Row>
                  <Col lg="3" md="2" sm="5" >
                    <img src={this.state.profilePic} class="rounded img-thumbnail" alt="..." />
                  </Col>
                  <Col>
                    <p>{this.state.firstName + ' ' + this.state.lastName} </p>
                    <div className="d-flex align-items-start justify-content-between mb-3">
                      <div><i class="fa fa-calendar" aria-hidden="true"></i> : {this.state.dob}</div>
                      <div><i class="fa fa-calendar" aria-hidden="true"></i> :  {this.state.doj}</div>

                    </div>

                    <div className="d-flex align-items-start mb-3"><i class="fa fa-map-marker"></i> : {this.state.location}</div>
                    <div className="mb-3">
                      {this.state.gender === true ? 'Male' : 'Female'}
                    </div>

                  </Col>

                </Row>

              </Col>
            </Row>

          </div>
          <div className="modal-footer"></div>

        </Modal>

      </React.Fragment>
    )
  }
}
export default ResidentList