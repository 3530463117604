
export const FieldTypes = {
    textbox: 'textbox',
    textarea: 'textarea',
    radio: 'radio',
    checkbox: 'checkbox',
    dropdown: 'dropdown',
    date: 'date',
    datetime: 'datetime'
}

export const SpecialFieldTypes = {
    statement: 'statement',
    header: 'header'
}

export const DueDurations = {
    oneweek: "1 Week",
    twoweek: "2 Week",
    threeweek: "3 Week",
    fourweek: "4 Week",
    fiveweek: "5 Week",
    onemonth: "1 Month",
    twomonth: "2 Month",
    threemonth: "3 month"
}

export const Days = {
    oneweek: 7,
    twoweek: 14,
    threeweek: 21,
    fourweek: 28,
    fiveweek: 35,
    onemonth: 30,
    twomonth: 60,
    threemonth: 90
}

export const DueDurationOptions = Object.keys(DueDurations).map(x => { return { label: DueDurations[x], value: x } });

export const FieldTypeOptions = Object.keys(FieldTypes).map(ft => { return { label: FieldTypes[ft], value: FieldTypes[ft] } });
export const SpecialFieldTypeOptions = Object.keys(SpecialFieldTypes).map(ft => { return { label: SpecialFieldTypes[ft], value: SpecialFieldTypes[ft] } });