import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    CardFooter,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Alert
} from "reactstrap";
import DatePicker from 'react-datepicker';
import Scheduler from '../../components/events/scheduler';
import Helper from '../../helpers';
import Datetime from 'react-datetime';
import Select from 'react-select';
import service from '../../services';
import axios from "axios";
import moment from 'moment';
import refreshToken from '../../refresh';

let accessToken, userId
class UpdateEvent extends React.Component {
    constructor() {
        super(  );
        this.state = {
            careLevels: [],
            selectedCareLevel: [],
            eventId:0,
            eventNameError: false,
            eventDescError: false,
            eventStartDateError: false,
            eventStartTimeError: false,
            eventEndTimeError: false,
            avatar:'',
            careLevelError: false,
            formCompleted: false,
            eventLocationError: false,
            showAlert: false,
            showAlertType: null,
            eventStatus: true,
            isNever: false,
            RRULE:''
        }
        this.schedulerRef = React.createRef();
        this.updateAll = this.updateAll.bind(this);
        this.updateOccurence = this.updateOccurence.bind(this);
    }

    componentDidMount () {
        accessToken= localStorage.getItem('accessToken')
        userId = parseInt(localStorage.userId)
        let pathURL = window.location.href
        let getId = pathURL.split('?'),
            URLVal = getId[1].split('&'),
            id = URLVal[0].split('='),
            eventDateVal = URLVal[1].split('=');
        console.log(eventDateVal[1])
        this.setState({eventId: id[1], excludedDate: moment(eventDateVal[1]).format()})
        this.getEventDetails(id[1])
    }

    updateAll = () => {
        const {selectedCareLevel, eventName, eventDesc, eventStartDate, eventStartTime, eventEndTime, formCompleted, eventLocation, eventEndDate} = this.state
     
        let RRULEs = Helper.generateRrule(this.schedulerRef.current.state.schedule),
            schedulerRule = this.schedulerRef.current.state.schedule,
            endDate = eventEndDate;
        
         if(eventName === ''){
             this.setState({
                 eventNameError: true
             })
         }
         if(eventDesc === ''){
             this.setState({
                 eventDescError: true
             })
         }
         if(eventStartDate === ''){
             this.setState({
                 eventStartDateError: true
             })
         }
         if(eventStartTime === ''){
             this.setState({
                 eventStartTimeError: true
             })
         }
         if(eventEndTime === ''){
             this.setState({
                 eventEndTimeError: true
             })
         }
         if(eventLocation === ''){
             this.setState({
                 eventLocationError: true
             })
         }
         if(selectedCareLevel.length === 0){
             this.setState({
                 careLevelError: true
             })
         }
         if(formCompleted){
             let selectedCareLevels = []
             if (schedulerRule.recurrenceRange.selectedOption === '2') {
                 endDate = schedulerRule.recurrenceRange.endDate
             }
             let formData = {
                 "Id": this.state.eventId,
                 "Name":eventName, 
                 "Description":eventDesc, 
                 "Location": eventLocation, 
                 "StartTime": eventStartTime,
                 "Avatar": this.state.avatar,
                 "EndDate": endDate,
                 "FileUtility": {},
                 "EndTime":eventEndTime, 
                 "Rrule":  RRULEs,
             }
             axios({
                 method: 'POST',
                 url: service.baseAPIURL + 'events/updateevent',
                 headers: {
                     'Content-Type': 'application/json',
                     'Authorization': 'Bearer ' + accessToken
                 },
                 data: formData
               }).then(response => {
                 if(response.data.result != null || response.data.result.isSuccess){
                   this.setState({
                     showAlert: true,
                     showAlertType : 'success'
                   })
                   }
                 else if(response.data.errors != null){
                   this.setState({
                     showAlert: true,
                     showAlertType : 'danger'
                   })
                 }
                 window.scrollTo(0, 0)
               })
               .catch(error => {
                   console.log(error)
                 if(error.request.status == 401){
                     refreshToken()
                 }
                 console.log(error); 
               });
         }
 
    }
    updateOccurence = () => {
        const {selectedCareLevel, eventName, eventDesc, eventStartDate, eventStartTime, eventEndTime, formCompleted, eventLocation, excludedDate} = this.state
     
        
        
         if(eventName === ''){
             this.setState({
                 eventNameError: true
             })
         }
         if(eventDesc === ''){
             this.setState({
                 eventDescError: true
             })
         }
         if(eventStartDate === ''){
             this.setState({
                 eventStartDateError: true
             })
         }
         if(eventStartTime === ''){
             this.setState({
                 eventStartTimeError: true
             })
         }
         if(eventEndTime === ''){
             this.setState({
                 eventEndTimeError: true
             })
         }
         if(eventLocation === ''){
             this.setState({
                 eventLocationError: true
             })
         }
         if(selectedCareLevel.length === 0){
             this.setState({
                 careLevelError: true
             })
         }
         if(formCompleted){
             let selectedCareLevels = []
             selectedCareLevel.map(data => {
                 selectedCareLevels.push({
                     "CareLevelId": data.value
                 })
             })
             let formData = { 
                 "Id": this.state.eventId,
                 "Name": eventName, 
                 "CareLevelId": selectedCareLevels[0].CareLevelId,
                 "Description":eventDesc, 
                 "Location": eventLocation, 
                 "StartDate": eventStartDate,
                 "Avatar": this.state.avatar,
                 "FileUtility": {},
                 "StartTime":eventStartTime, 
                 "ExcludedDate": excludedDate,
                 "EndTime":eventEndTime, 
             }
             axios({
                 method: 'POST',
                 url: service.baseAPIURL + 'events/UpdateEventForOccurance',
                 headers: {
                     'Content-Type': 'application/json',
                     'Authorization': 'Bearer ' + accessToken
                 },
                 data: formData
               }).then(response => {
                 if(response.data.result != null || response.data.result.isSuccess){
                   this.setState({
                     showAlert: true,
                     showAlertType : 'success'
                   })
                   }
                 else if(response.data.errors != null){
                   this.setState({
                     showAlert: true,
                     showAlertType : 'danger'
                   })
                 }
                 window.scrollTo(0, 0)
               })
               .catch(error => {
                   console.log(error.request)
                 if(error.request.status == 401){
                     refreshToken()
                 }
                 console.log(error); 
               });
         }
 
    }

    ChangeInput = (text, propertyName, propertyNameError) => {
        this.setState({
          [propertyName]: text,
          formCompleted: true
        })
        if(text !== ''){
          this.setState({
            [propertyNameError] : false
          })
        }
      }

      ChangeDate = (value, propertyName, propertyNameError) => {
          let selectedDate =  moment(value).format("YYYY-MM-DD")
          this.setState({
            [propertyName]: selectedDate,
            formCompleted: true,
            [propertyNameError]: false
          })
      }
      ChangeTime = (value, propertyName, propertyNameError) => {
        let selectedTime =  moment(value).format()
        this.setState({
          [propertyName]: selectedTime,
          formCompleted: true,
          [propertyNameError]: false
        })
    }
    handleCarelevels = selectedCarelevel => {
        console.log(selectedCarelevel)
        this.setState({ selectedCareLevel: selectedCarelevel, completeForm:true, careLevelError: false });
      };
      toggleStatus = (e) => {
        this.setState({
            eventStatus: e.target.checked
        })
      }

    getEventDetails = (id) => {
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'events/geteventdetails?eventId='+id,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(response => {
            console.log(response)
            let data = response.data.result.eventInfo;
            this.setState({
                eventName: data.name,
                eventId: data.id,
                avatar: data.avatar,
                eventStartDate: data.startDate,
                eventEndDate: data.endDate,
                eventStartTime: data.startTime,
                eventEndTime: data.endTime,
                eventDesc: data.description,
                eventLocation: data.location,
                RRULE: data.rrule,
                formCompleted: true,
                isNever: data.rrule.indexOf('Never')!=-1,
                profilePic: data.avatar === null ? '' : service.baseImgURL + data.avatar,
            })
            this.getCareLevels(data.eventCareLevels)
        })
        .catch(error => {
            console.log(error);
        });
    
    }

    getCareLevels = (eventCareLevels) => {
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'communities/getcarelevelsbyfilter?userId='+userId ,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
          }).then(response => {
              let careLevelsData = response.data.result.careLevels,
                  carelevelOptions = [],
                  selectedEventsCareLevel = [];
                 
                        eventCareLevels.forEach((item) => {
                            let id = item.careLevelId
                            careLevelsData.forEach((data) => {
                                if(id === data.id){
                                    selectedEventsCareLevel.push({
                                        value: data.id,
                                        label: data.name
                                    })
                                }
                            })

                        })
                  careLevelsData.forEach((item, i) => {
                    if(!item.isActive){       
                      carelevelOptions.push({
                        value: item.id,
                        label: item.name
                      })
                    }
                });
               
                this.setState({
                  careLevels: carelevelOptions,
                  selectedEventsCareLevel,
                  selectedCareLevel: selectedEventsCareLevel
                })
            })
            .catch(error => {
                console.log(error)
                
              if(error.request.status == 401){
                  refreshToken()
              }
              console.log(error);
            });
    }
    render() {
        const {eventLocationError, careLevels, eventStatus,  eventNameError, eventStartTimeError, eventDescError, eventStartDateError, eventEndTimeError, careLevelError,showAlertType, showAlert, eventName, eventLocation, eventDesc, selectedEventsCareLevel, RRULE,  eventStartDate, eventStartTime, eventEndTime, profilePicError} = this.state

         let startDateAssigned = 'form-control', startTimeAssigned = 'form-control', endTimeAssigned = 'form-control'
        if(eventStartDateError){
            startDateAssigned = 'is-invalid form-control'
        }
       
        if(eventStartTimeError){
            startTimeAssigned = 'is-invalid form-control'
        } 
        if(eventEndTimeError){
            endTimeAssigned = 'is-invalid form-control'
        }
        let inputStartDateProps = {
            className: startDateAssigned
        }
        let inputStartTimeProps = {
            className: startTimeAssigned
        }
        let inputEndTimeProps = {
            className: endTimeAssigned
        }
        let yesterday = moment().subtract( 1, 'day' ),
            valid = function( current ){
            return current.isAfter( yesterday );
        };
        const customStyles = {
            menu: (provided) => ({
              ...provided,
             zIndex: 9999
            })
        }
        let alertMessage, alertStatus
      if(this.state.showAlertType === 'success'){
        alertMessage =  'event updated'
        alertStatus ="Success"
      }
      else{
        alertMessage = 'event couldn\'t be updated'
        alertStatus ="Error"
      }
      const profilePicStyle = {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat:'no-Repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url('+ this.state.profilePic +')',
        borderRadius: '5px',
        backgroundColor: '#ebebeb'
      }
      const profilePicStyleError = {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat:'no-Repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url('+ this.state.profilePic +')',
        borderRadius: '5px',
        backgroundColor: '#ebebeb',
        borderWidth: '1px',
        borderColor: '#fb6340',
        borderStyle:'solid'
    }
        return (
            <React.Fragment>
                  <Alert color={showAlertType} isOpen={showAlert} toggle={(e) => this.setState({showAlert: false})}>
                    <strong>{alertStatus}! </strong>  {alertMessage}
                </Alert>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-4">
                        <Row className="text-muted">
                            <Col md="6" lg="6">
                                <h3>Event Manager</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-2">
                        <Row>
                            
                            <Col lg="12" md="12">
                                <Form>
                                    <Row>
                                    <Col lg="3" md="3" sm="6">
                                    <div className="position-relative profilePic">
                                        <Input onChange={(e) => this.changeProfilePic(e)}  type="file" inputprops={{ accept: 'image/*' }} style={{position: 'absolute', width:'100%', height: '100%', opacity:0}}/>
                                        <div style={profilePicError ? profilePicStyleError : profilePicStyle} ></div>
                                        {profilePicError ? <div className="error-msg">Upload a profile pic</div>: ''}
                                    </div>
                                    </Col>
                                    <Col lg="9" md="9" sm="6">
                                        <Row>
                                        <Col lg="4" md="4">
                                            <FormGroup className={eventNameError ? 'has-danger mb-3' : 'mb-3'} >
                                                <Label>Event Name</Label>
                                                <Input value={eventName} invalid={eventNameError}  onChange={(e) => this.ChangeInput(e.target.value, 'eventName', 'eventNameError')} />
                                                <FormFeedback>Enter a event name</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4" md="4">
                                            <FormGroup className={eventDescError ? 'has-danger mb-3' : 'mb-3'}>
                                                <Label>Description</Label>
                                                <Input value={eventDesc} type="textarea" name="text"  invalid={eventDescError} onChange={(e) => this.ChangeInput(e.target.value, 'eventDesc', 'eventDescError')}  />
                                                <FormFeedback>Enter a description for the event</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4" md="4">
                                            <FormGroup className={eventLocationError ? 'has-danger mb-3' : 'mb-3'} >
                                                <Label>Event Location</Label>
                                                <Input value={eventLocation} invalid={eventLocationError}  onChange={(e) => this.ChangeInput(e.target.value, 'eventLocation', 'eventLocationError')} />
                                                <FormFeedback>Enter a location of event</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4" md="4" className="mb-3">
                                        <div className={eventStartDateError ? 'd-flex align-items flex-column has-danger' : 'd-flex align-items flex-column'}>
                                            <Label>Start Date</Label>
                                            <Datetime value={moment(eventStartDate)} isValidDate={ valid } timeFormat={false} inputProps={inputStartDateProps} onChange={(e) => this.ChangeDate(e, 'eventStartDate', 'eventStartDateError')} closeOnSelect={true}  />
                                            {eventStartDateError ? <div className="error-msg">Enter a start date</div>: ''}
                                        </div>
                                           
                                        </Col>
                                        </Row>

</Col>
                                        <Col lg="4" md="4" className="mb-3">
                                        <div className={eventStartTimeError ? 'd-flex align-items flex-column has-danger' : 'd-flex align-items flex-column'}>
                                            <Label>Start Time</Label>
                                            <Datetime value={moment(eventStartTime)} dateFormat={false} inputProps={inputStartTimeProps} onChange={(e) => this.ChangeTime(e, 'eventStartTime', 'eventStartTimeError')} closeOnSelect={true} />
                                            {eventStartTimeError ? <div className="error-msg">Enter a start time</div>: ''}
                                        </div>                                        
                                        </Col>
                                        <Col lg="4" md="4" className="mb-3">
                                        <div className={eventEndTimeError ? 'd-flex align-items flex-column has-danger' : 'd-flex align-items flex-column'}>
                                            <Label>End Time</Label>
                                            <Datetime value={moment(eventEndTime)} dateFormat={false} inputProps={inputEndTimeProps} onChange={(e) => this.ChangeTime(e, 'eventEndTime', 'eventEndTimeError')} closeOnSelect={true}/>
                                            {eventEndTimeError ? <div className="error-msg">Enter a end time</div>: ''}
                                        </div>                                        
                                        </Col>
                                        <Col lg="4" md="4">
                                            <FormGroup className={careLevelError ? 'has-danger mb-3' : 'mb-3'}>
                                            <Label>CareLevels </Label>
                                            <Select
                                            value={selectedEventsCareLevel}
                                            closeMenuOnSelect={false}
                                            styles={customStyles}
                                            options={careLevels}
                                            isMulti={true}
                                            isDisabled={true}
                                            className={careLevelError ? 'is-invalid': ''} 
                                            />
                                             {careLevelError ? <div className="error-msg">Select atleast one </div>: ''}
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" sm="12" lg="4" className="mb-3">    
                                            <div className="d-flex align-items-center justify-content-between h-100">
                                                <div className="mb-1 mr-3">Active</div>
                                                <label className="custom-toggle">
                                                <input defaultChecked={eventStatus} type="checkbox" onClick = {(e) => this.toggleStatus(e)} />
                                                <span className="custom-toggle-slider rounded-circle" />
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 border-top pt-3">
                                        <Col lg="12" md="12">
                                            {this.state.RRULE === "" ? <div></div> :
                                                <Scheduler ref={this.schedulerRef} rrule={this.state.RRULE} />}
                                         </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                        {this.state.isNever ? <span></span> : <Button color="primary" size="sm" onClick={this.updateOccurence} >Update Occurence</Button>}
                        <Button color="primary" size="sm" onClick={this.updateAll}>Update All</Button>
                    </CardFooter>
                </Card>
            </React.Fragment>
        )
    }
}

export default UpdateEvent;