const storyValidators = {
    name: {
        rules: [
            {
                test: (value) => {
                    return value == null || value.length != 0;
                },
                message: 'Name cannot be empty',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    description: {
        rules: [
            {
                test: (value) => {
                    return value == null || value.length != 0;
                },
                message: 'Description cannot be empty',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    }
};

export default storyValidators;