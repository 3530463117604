import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/css/main.css"
import "react-datetime/css/react-datetime.css";
import axios from 'axios';

axios.interceptors.request.use(request => {
    return request;
})

axios.interceptors.response.use(response => {
    return response;
}, error => {
        if (error.response.status === 400) {
            window.location = '/#/admin/badrequest';
        }
        else if (error.response.status === 403) {
            window.location = '/#/admin/unauthorized';
        }
        else if (error.response.status === 404) {
            window.location = '/#/admin/pagenotfound';
        }
        else if (error.response.status === 500) {
            window.location = '/#/admin/internalservererror';
        }
        else if (error.response.status === 401) {
            //window.location = '/#/auth/login';
        }
        return Promise.reject(error);
})

ReactDOM.render(
  <React.StrictMode>
        <App />
       
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
