import React, { Component } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Progress,
    Input,
    Row,
    Col, Toast, ToastBody, ToastHeader,
     CardFooter
} from "reactstrap";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import service from '../../services';
import axios from "axios";
import defaultPic from '../../assets/img/theme/defaultProfile.jpg';
import { strengthIndicator, strengthColor } from '../../components/password-strength/strength-checker';
import userValidators from './user-validation';

let accessToken,userId;
class UpdateUser extends Component {
    constructor() {
        super();

        this.state = {
            timeZones: [],
            roles: [],
            communities: [],
            careLevels: [],
            showResponse: false,
            responseMessage: 'ok',
            userInfo: {
                id:0,
                email: '',
                firstName: '',
                lastName: '',
                avatar: '',
                password: '',
                retypePassword:'',
                userPic: defaultPic,
                selectedTimeZone: null,
                selectedRole: null,
                selectedCommunity: null,
                selectedCareLevels: [],
                aboutMe: '',
                userImage: {
                    fileData: null,
                    fileType: '',
                    fileName: ''
                },
                phoneNumber: '',
                showOnSubmit: false
            }
        }

        this.userValidators = userValidators;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.resetValidators = this.resetValidators.bind(this);
        this.updateValidators = this.updateValidators.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.displayValidationErrors = this.displayValidationErrors.bind(this);
        this.displayErrorBorder = this.displayErrorBorder.bind(this);
    }

    componentDidMount() {
        accessToken = localStorage.accessToken;
        userId = localStorage.userId;
        let pathURL = window.location.href
        if (pathURL.indexOf('?') > 0) {
            let getId = pathURL.split('?');
            let id = getId[1];
            var newState = Object.assign({}, this.state);
            newState.userInfo.id = id;
            this.setState(newState);
            this.getUserDetails(id.split('=')[1])
        };
    }

    getCareLevels = (userid,communityId) => {
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'Users/GetCareLevels?userId=' + userid + '&communityId=' + communityId,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                console.log(response);
                let careLevelOptions = [];
                response.data.result.careLevels.forEach((item, index) => {
                    careLevelOptions.push({ label: item.name, value: item.id, isChecked: item.isSelected });
                });
                var newState = Object.assign({}, this.state);
                newState.userInfo.selectedCareLevels = careLevelOptions;
                newState.careLevels = careLevelOptions;
                this.updateValidators("SelectedCareLevels", this.state.userInfo.selectedCareLevels);
                this.setState(newState);
            })
            .catch(error => {
                console.log(error);
            });
    }

    getUserDetails = (id) => {
        axios({
            method: 'GET',
            url: service.baseAPIURL + 'Users/GetUserDetails?userId=' + id,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            },
            data: {}
        })
            .then(response => {
                console.log(response);
                if (response.data.result.user) {
                    var user = response.data.result.user;
                    let timeZoneOptions = [], roleOptions = [], communitiesOptions = [],
                        selectedTimeZoneOption, selectedCommunityOption, selectedRoleOption;
                    user.timeZones.forEach((item, index) => {
                        if (item.id === user.timeZoneId) {
                            selectedTimeZoneOption = { label: item.name, value: item.id };
                        }
                        timeZoneOptions.push({ label: item.name, value: item.id });
                    });
                    user.communities.forEach((item, index) => {
                        if (item.isSelected === true) {
                            selectedCommunityOption = { label: item.name, value: item.id };
                        }
                        communitiesOptions.push({ label: item.name, value: item.id });
                    });
                    user.roles.forEach((item, index) => {
                        if (item.isSelected === true) {
                            selectedRoleOption = { label: item.name, value: item.id };
                        }
                        roleOptions.push({ label: item.name, value: item.id });
                    });
                    console.log(selectedRoleOption);
                    this.setState({
                        userInfo: {
                            id: user.id,
                            email: user.email,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            avatar: user.avatar,
                            password: '1234567',
                            retypePassword: '1234567',
                            userPic: service.baseImgURL + user.avatar,
                            selectedTimeZone: selectedTimeZoneOption,
                            aboutMe: user.aboutMe,
                            selectedRole: selectedRoleOption,
                            selectedCommunity: selectedCommunityOption,
                            userImage: {
                                fileData: null,
                                fileType: '',
                                fileName: ''
                            },
                            phoneNumber: user.phoneNumber,
                            showOnSubmit: false
                        },
                        communities: communitiesOptions,
                        roles: roleOptions,
                        timeZones: timeZoneOptions
                    })
                    debugger;
                    this.updateValidators("email", this.state.userInfo.email);
                    this.updateValidators("firstName", this.state.userInfo.firstName);
                    this.updateValidators("lastName", this.state.userInfo.lastName);
                    this.updateValidators("phoneNumber", this.state.userInfo.phoneNumber);
                    this.updateValidators("password", this.state.userInfo.password);
                    this.updateValidators("retypePassword", this.state.userInfo.retypePassword);
                    this.updateValidators("selectedTimeZone", selectedTimeZoneOption);
                    this.updateValidators("selectedRole", selectedRoleOption);
                    this.updateValidators("selectedCommunity", selectedCommunityOption);
                    this.getCareLevels(user.id, selectedCommunityOption.value);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    isFormValid() {
        let status = true;
        Object.keys(this.userValidators).forEach((field) => {
            if (!this.userValidators[field].valid) {
                status = false;
            }
        });
        return status;
    }

    updateValidators(fieldName, value) {
        const { userInfo } = this.state;
        if (this.userValidators[fieldName] == undefined) return;
        this.userValidators[fieldName].errors = [];
        this.userValidators[fieldName].state = value;
        this.userValidators[fieldName].valid = true;
        this.userValidators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.userValidators[fieldName].errors.push(rule.message);
                    this.userValidators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function' && rule.fieldToCompare) {
                if (!rule.test(value, userInfo[rule.fieldToCompare])) {
                    this.userValidators[fieldName].errors.push(rule.message);
                    this.userValidators[fieldName].valid = false;
                }
            }
            else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.userValidators[fieldName].errors.push(rule.message);
                    this.userValidators[fieldName].valid = false;
                }
            }
        });
    }

    handleInputChange(event, inputPropName) {
        const newState = Object.assign({}, this.state);
        if (event.target) {
            newState.userInfo[inputPropName] = event.target.value;
            this.updateValidators(inputPropName, event.target.value);
        }
        else {
            newState.userInfo[inputPropName] = event;
            this.updateValidators(inputPropName, event);
        }
        this.setState(newState);
        if (inputPropName == "selectedCommunity") {
            this.getCareLevels(userId,event.value);
        }
    }

    resetValidators() {
        Object.keys(this.userValidators).forEach((fieldName) => {
            this.userValidators[fieldName].errors = [];
            this.userValidators[fieldName].state = '';
            this.userValidators[fieldName].valid = false;
        });
    }

    saveUser = () => {
        debugger;
        const { userInfo } = this.state;
        var isUserFormValid = this.isFormValid();
        if (!isUserFormValid) {
            this.setState(prevState => ({ userInfo: { ...prevState.userInfo, showOnSubmit: true } }));
            this.updateValidators("email", userInfo.email);
            this.updateValidators("firstName", userInfo.firstName);
            this.updateValidators("lastName", userInfo.lastName);
            this.updateValidators("phoneNumber", userInfo.phoneNumber);
            this.updateValidators("selectedTimeZone", userInfo.selectedTimeZone);
            this.updateValidators("selectedRole", userInfo.selectedRole);
            this.updateValidators("selectedCommunity", userInfo.selectedCommunity);
            this.updateValidators("SelectedCareLevels", userInfo.selectedCareLevels);
        }
        else {
            accessToken = localStorage.accessToken;
            userId = localStorage.userId;
            var careLevelOptions = [];
            userInfo.selectedCareLevels.forEach((item, index) => {
                if (item.isChecked) {
                    careLevelOptions.push({ "Id": item.value });
                }
            })
            let formData = {
                "Id": userInfo.id,
                "Email": userInfo.email,
                'FirstName': userInfo.firstName,
                'LastName': userInfo.lastName,
                'PhoneNumber': userInfo.phoneNumber,
                'Avatar': userInfo.avatar,
                'Password': '123456',
                'RetypePassword':'123456',
                'TimeZoneId': userInfo.selectedTimeZone.value,
                'AboutMe': userInfo.aboutMe,
                "SelectedRoles": [{ "Id": userInfo.selectedRole.value }],
                "SelectedCommunities": [{ "Id": userInfo.selectedCommunity.value }],
                "SelectedCareLevels": careLevelOptions,
                "FileUtility": {
                    "FileData": userInfo.userImage.fileData,
                    "FileExtension": userInfo.userImage.fileType
                }
            }
                axios({
                    method: 'POST',
                    url: service.baseAPIURL + 'Users/UpdateUser',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    },
                    data: formData
                })
                .then(response => {
                    console.log(response);
                    
                    this.setState({
                        showResponse: true,
                        responseMessage: response.data.result.message
                    })
                    this.resetUser();
                    debugger;
                    let info = this.state;
                    window.scrollTo(0, 0)
                })
                .catch(error => {
                    console.log(error);
                });
        }

    };

    changeCareLevel = (e, careLevel, index) => {
        var isChecked = e.target.checked;
        this.state.userInfo.selectedCareLevels[index].isChecked = isChecked;

    }

    displayErrorBorder(fieldName) {
        return !this.userValidators[fieldName].valid && this.userValidators[fieldName].errors.length > 0 ? "has-danger" : '';
    }

    displayValidationErrors(fieldName) {
        const validator = this.userValidators[fieldName];
        const result = '';
        if (validator && !validator.valid) {
            const errors = validator.errors.map((info, index) => {
                return <span className="error" key={index}>* {info}</span>;
            });

            return (
                <div className="col s12 row">
                    {errors}
                </div>
            );
        }
        return result;
    }



    changeProfilePic = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            let fileName = file.name.split('.'),
                fileData = reader.result.split('base64,');
            this.setState(prevState => ({
                userInfo: {
                    ...prevState.userInfo,
                    userPic: reader.result,
                    userImage: {
                        fileType: '.' + fileName[fileName.length - 1],
                        fileData: fileData[1],
                        fileName: file.name
                    }
                }
            }));
        }
    }

    resetUser = () => {
        this.resetValidators();
    }


    render() {
        const profilePicStyle = {
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-Repeat',
            backgroundPosition: 'center',
            backgroundImage: 'url(' + this.state.userInfo.userPic + ')',
            borderRadius: '5px',
            backgroundColor: '#ebebeb'
        }
        const { timeZones, roles, careLevels, communities, showResponse, responseMessage, userInfo } = this.state;
        return (
            <React.Fragment>
                <Toast style={{ margin: '5px', color: 'white' }} isOpen={showResponse}>
                    <ToastHeader icon={responseMessage == "" ? "success" : "warning"}>
                        Update user
                    </ToastHeader>
                    <ToastBody>
                        {responseMessage == "" ? "User updated successfully" : responseMessage}
                    </ToastBody>
                </Toast>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-4">
                        <Row className="text-muted">
                            <Col md="6" lg="6">
                                <h3>Update User</h3>
                            </Col>
                            <Col md="6" lg="6" className="text-right">
                                <Link to='./users'>Back</Link>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-2">
                        <Form role="form">

                            <Row className="mb-3">
                            <Col md="3" sm="1" lg="3" className="mb-3 position-relative">
                                    <Input size="sm" src={userInfo.avatar} onChange={(e) => this.changeProfilePic(e)} id="communityAvatar" type="file" inputprops={{ accept: 'image/*' }} style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0 }} />
                                    <div style={profilePicStyle} ></div>
                                </Col>
                                <Col md="9" className={this.displayErrorBorder("firstName")}>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                        <FormGroup className="mb-3">
                                            <Input type="text" value={userInfo.firstName} size="sm" placeholder="Enter first name" onChange={(e) => this.handleInputChange(e, "firstName")} />
                                                {this.displayValidationErrors('firstName')}
                                                </FormGroup>
                                        </Col>
                                        <Col md="6" lg="6" sm="12" className={this.displayErrorBorder("lastName")}>
                                        <FormGroup className="mb-3">
                                                <Input type="text" value={userInfo.lastName} size="sm" placeholder="Enter last name" onChange={(e) => this.handleInputChange(e, "lastName")} />
                                                {this.displayValidationErrors('lastName')}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" lg="6" sm="12"  className={this.displayErrorBorder("email")}>
                                            <FormGroup className="mb-3">
                                                <Input type="email" autoComplete="new-email" value={userInfo.email} size="sm" name="email" placeholder="Enter email" onChange={(e) => this.handleInputChange(e, "email")} />
                                                {this.displayValidationErrors('email')}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" className={this.displayErrorBorder("phoneNumber")}>
                                        <FormGroup className="mb-3">
                                                <Input type="tel" size="sm" value={userInfo.phoneNumber} placeholder="Enter Phone number" onChange={(e) => this.handleInputChange(e, "phoneNumber")} />
                                                {this.displayValidationErrors('phoneNumber')}
                                                </FormGroup>
                                            </Col>
                                        <Col md="12">
                                            <Input type="textarea" value={userInfo.aboutMe} size="sm" rows="3" placeholder="Tell us about yourself" onChange={(e) => this.handleInputChange(e, "aboutMe")} />
                                        </Col>
                                    </Row>
                                               
                                </Col>
  
                            </Row>
                            <Row className="mb-3">
                                <Col md="4" className={this.displayErrorBorder("selectedTimeZone")}>
                                    <Select
                                        value={userInfo.selectedTimeZone}
                                        options={timeZones}
                                        placeholder="Select Timezone"
                                        onChange={(e) => this.handleInputChange(e, "selectedTimeZone")}
                                    />
                                    {this.displayValidationErrors('selectedTimeZone')}
                                </Col>
                                <Col md="4" className={this.displayErrorBorder("selectedRole")}>
                                    <Select
                                        value={userInfo.selectedRole}
                                        options={roles}
                                        placeholder="Select Role"
                                        onChange={(e) => this.handleInputChange(e, "selectedRole")}
                                    />
                                    {this.displayValidationErrors('selectedRole')}
                                </Col>
                                <Col md="4" className={this.displayErrorBorder("selectedCommunity")}>
                                    <Select
                                        value={userInfo.selectedCommunity}
                                        options={communities}
                                        placeholder="Select Community"
                                        onChange={(e) => this.handleInputChange(e, "selectedCommunity")}
                                    />
                                    {this.displayValidationErrors('selectedCommunity')}
                                </Col>
                            </Row>
                            <Row className={this.displayErrorBorder("SelectedCareLevels")}>
                                {
                                    careLevels.map((careLevel, index) => {
                                        return (<Col md="3" sm="12" lg="4">
                                            <div className="d-flex align-items-center h-100">
                                                <label className="custom-toggle">
                                                    <input defaultChecked={careLevel.isChecked}
                                                        onChange={(e) => this.changeCareLevel(e, careLevel, index)} type="checkbox" />
                                                    <span className="custom-toggle-slider rounded-circle" />
                                                </label>
                                                <div className="mb-1 mr-3">{careLevel.label}</div>
                                            </div>
                                        </Col>)
                                    })
                                }
                                {this.displayValidationErrors('SelectedCareLevels')}
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Row>
                           
                            <Col md="2" className="offset-md-10 text-right">
                                <Row>
                                    <Col md="12" sm="12" lg="12" C >
                                        <Button color="primary" size="sm" type="button" onClick={this.saveUser}>Save</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </React.Fragment>
        )
    }
}

export default UpdateUser;